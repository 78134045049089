import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import GeneralTab from "./GeneralTab";
import ColorTab from "./ColorTab";
import SizeTab from "./SizeTab";
import OtherTab from "./OtherTab";
import PriceTab from "./PriceTab";

const ProductTabs = (props) => {
	const {
		control,
		watch,
		getValues,
		setValue,
		colorFields,
		colorAppend,
		colorRemove,
		measurementsFields,
		measurementsAppend,
		measurementsRemove,
		imageAppend,
		imageRemove,
		imageReplace,
		priceFields,
		priceAppend,
		priceRemove,
		priceReplace,
	} = props;

	const [tabValue, setTabValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					variant="fullWidth"
					value={tabValue}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label="General Info"
						{...a11yProps(0)}
					/>
					<Tab
						label="Colors"
						{...a11yProps(1)}
					/>
					<Tab
						label="Measurements"
						{...a11yProps(2)}
					/>
					<Tab
						label="Price"
						{...a11yProps(3)}
					/>
					<Tab
						label="Other Info"
						{...a11yProps(4)}
					/>
				</Tabs>
			</Box>

			<GeneralTab
				tabValue={tabValue}
				control={control}
				watch={watch}
				setValue={setValue}
				imageReplace={imageReplace}
				imageAppend={imageAppend}
				imageRemove={imageRemove}
			></GeneralTab>
			<ColorTab
				{...{
					tabValue,
					control,
					watch,
					getValues,
					setValue,
					colorFields,
					colorAppend,
					colorRemove,
				}}
			></ColorTab>
			<SizeTab
				{...{
					tabValue,
					control,
					watch,
					getValues,
					setValue,
					measurementsFields,
					measurementsAppend,
					measurementsRemove,
				}}
			></SizeTab>
			<PriceTab
				{...{
					tabValue,
					control,
					watch,
					getValues,
					setValue,
					priceFields,
					priceAppend,
					priceRemove,
					priceReplace,
				}}
			></PriceTab>
			<OtherTab
				{...{
					tabValue,
					control,
					watch,
					getValues,
					setValue,
					measurementsFields,
					measurementsAppend,
					measurementsRemove,
				}}
			></OtherTab>
		</Box>
	);
};

export default ProductTabs;
