import { Box, Rating, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RatingCmp } from "..";

import {
  ChatIcon,
  LocationOnOutlinedIcon,
  WatchLaterOutlinedIcon,
} from "../../Assets";
import styles from "./CompletedJobCard.module.css";

const CompletedJobCard = ({ projectFB, companyFB, path, completedJobData }) => {
  const {
    job_start_date,
    job_completion_date,
    job_awarded_date,
    label,
    address,
    city,
    state,
    zipcode,
    proposals,
    freelancer_review,
    rating_as_bidder,
    contractor_review,
    rating_as_owner,
  } = completedJobData || {};

  const { bid_amount, user: bidder } = proposals[0] || {
    bid_amount: 353000,
    user: {
      company_name: "Company Name",
      label: "Project Name",
      street_address: "Address",
      city: { label: "City" },
      state: { label: "State" },
      zip: "19117",
    },
  };

  const navigate = useNavigate();

  console.log("completedJobData", completedJobData);

  return (
    <section
      className={styles.card_wrapper}
      onClick={() => {
        navigate(path, {
          state: { completedJobData },
        });
      }}
    >
      <section className={styles.project_wrappper}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              className={styles.bidName}
            >
              {label}
            </Typography>
          </Box>
          <Box sx={{ margin: "5px 0 " }}>
            <Typography
              sx={{
                fontSize: "small",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnOutlinedIcon
                fontSize="small"
                sx={{ color: "var(--primary-color)", marginLeft: "-5px" }}
              />
              {address}, {city?.label}, {state?.label}, {zipcode}
            </Typography>
          </Box>
        </Box>

        <div className={styles.projectDate}>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Start Date:
            </Typography>
            <Typography fontSize="14px">{job_start_date}</Typography>
          </section>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Completition Date:
            </Typography>
            <Typography fontSize="14px">{job_completion_date}</Typography>
          </section>
        </div>
        <div className={styles.project__feedback}>
          {freelancer_review ? (
            <>
              <RatingCmp readOnly={true} ratingValue={rating_as_owner} />
              <Typography>{rating_as_owner} of 4 reviews</Typography>
            </>
          ) : (
            <>
              <WatchLaterOutlinedIcon fontSize="small" />
              <Typography fontSize="small">No Feedback Received</Typography>
            </>
          )}
        </div>
      </section>

      {/* ///company details */}
      <section className={styles.company_wrappper}>
        <Box
          sx={{ display: " flex", justifyContent: "space-between" }}
          className={styles.companyName}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h5" fontWeight={"bold"}>
                {bidder?.company_name || bidder?.label}
              </Typography>
            </Box>
            <Box sx={{ margin: "5px 0 " }}>
              <Typography
                sx={{
                  fontSize: "small",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon
                  fontSize="small"
                  sx={{ color: "var(--primary-color)", marginLeft: "-5px" }}
                />
                {bidder?.street_address}, {bidder.city?.label},{" "}
                {bidder?.state?.label}, {bidder?.zip}
              </Typography>
            </Box>
          </Box>
          <div>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color={{ color: "var(--green)" }}
            >
              ${bid_amount}
            </Typography>
            <Typography fontSize="small" sx={{ textAlign: "right" }}>
              Project Cost
            </Typography>
          </div>
        </Box>

        <div className={styles.projectDate}>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Awarded Date:
            </Typography>
            <Typography fontSize="small">{job_awarded_date}</Typography>
          </section>
          {/* <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Awarded Date:
            </Typography>
            <Typography fontSize="small">8/25/2021</Typography>
          </section> */}
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Contact Details:
            </Typography>
            <Typography
              fontSize="small"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
            >
              <img src={ChatIcon} />
              Message
            </Typography>
          </section>
        </div>
        <div className={styles.project__feedback}>
          {contractor_review ? (
            <>
              <RatingCmp readOnly={true} ratingValue={rating_as_bidder} />
              <Typography>{rating_as_bidder} of 5 reviews</Typography>
            </>
          ) : (
            <>
              <WatchLaterOutlinedIcon fontSize="small" />
              <Typography fontSize="small">No Feedback Given</Typography>
            </>
          )}
        </div>
      </section>
    </section>
  );
};

export default CompletedJobCard;
