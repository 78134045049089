import { useEffect, useState } from "react";

function useGerUserPermissions() {
	const [userPermissions, setUserPermissions] = useState([]);
	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		setUserPermissions(userData.all_roles);
	}, []);
	return userPermissions;
}

export default useGerUserPermissions;
