import ActionTypes from "../Constants";

const INITIALSTATE = {
  ///create job
  createJobLoading: false,
  createJobdata: "",
  createJobError: "",

  ///all user project
  getAllUSerProjectLoading: false,
  getAllUSerProjectdata: "",
  getAllUSerProjectError: "",

  //single Project
  singleProjectDetailsLoading: false,
  singleProjectDetailsdata: "",
  singleProjectDetailsError: "",

  //single Bids Project
  singleBidDetailsLoading: false,
  singleBidDetailsdata: "",
  singleBidDetailsError: "",

  //Awarded Bids Project
  awardedJobLoading: false,
  awardedJobdata: "",
  awardedJobError: "",

  //delete job Project
  deleteJobLoading: false,
  deleteJobMessage: "",
  deleteJobError: "",

  //delete job Project
  getDeleteJobLoading: false,
  getDeleteJobData: "",
  getDeleteJobError: "",

  //Completed job Project
  completedJobsLoading: false,
  completedJobsData: "",
  completedJobsError: "",

  //delete job Project
  restoreDeleteJobLoading: false,
  isRestore: false,
  restoreDeleteJobMsg: "",
  restoreDeleteJobError: "",

  //accept Proposal Project
  acceptProposalLoading: false,
  acceptProposalData: "",
  acceptProposalMsg: "",
  acceptProposalError: "",

  //permanent job Project
  permanentDeleteJobLoading: false,
  isPermanentDelete: false,
  permanentDeleteJobMsg: "",
  permanentDeleteJobError: "",

  //GET milestone awarded job
  awardedJobMileStoneLoading: false,
  awardedJobMileStoneData: "",
  awardedJobMileStoneMsg: "",
  awardedJobMileStoneError: "",

  //GET milestone awarded job
  accpetedMileStoneLoading: false,
  isStatusUpdate: false,

  //get single job Project
  getSingleJobLoading: false,
  getSingleJobData: "",
  getSingleJobError: "",

  //accept end contract
  acceptEndContractLoading: false,
  acceptEndContractData: false,
  acceptEndContractError: "",

  //get single job Project
  getSingleJobCompleteLoading: false,
  getSingleCompleteJobData: "",

  //get single job Project
  getSingleProjectCompleteLoading: false,
  getSingleCompleteProjectData: "",
};

const CreateJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_JOB_REQUEST:
      return {
        ...state,
        createJobLoading: true,
      };
    case ActionTypes.CREATE_JOB_SUCCESS:
      return {
        ...state,
        createJobLoading: false,
        createJobdata: action.payload.data,
        createJobError: "",
      };
    case ActionTypes.CREATE_JOB_FAIL:
      return {
        ...state,
        createJobLoading: false,
        createJobdata: [],
        createJobError: action.payload.message,
      };
    default:
      return state;
  }
};

const GetAllUserProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALLUSER_PROJECT_REQUEST:
      return {
        ...state,
        getAllUSerProjectLoading: true,
      };
    case ActionTypes.GET_ALLUSER_PROJECT_SUCCESS:
      return {
        ...state,
        getAllUSerProjectLoading: false,
        getAllUSerProjectdata: action.payload.data,
        getAllUSerProjectError: "",
      };
    case ActionTypes.GET_ALLUSER_PROJECT_FAIL:
      return {
        ...state,
        getAllUSerProjectLoading: false,
        getAllUSerProjectdata: [],
        getAllUSerProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const SingleProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.SINGLE_JOB_REQUEST:
      return {
        ...state,
        singleProjectDetailsLoading: true,
      };
    case ActionTypes.SINGLE_JOB_SUCCESS:
      return {
        ...state,
        singleProjectDetailsLoading: false,
        singleProjectDetailsdata: action.payload.data,
        singleProjectDetailsError: "",
      };
    case ActionTypes.SINGLE_JOB_FAIL:
      return {
        ...state,
        singleProjectDetailsLoading: false,
        singleProjectDetailsdata: [],
        singleProjectDetailsError: action.payload.message,
      };
    default:
      return state;
  }
};

const SingleBidsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.SINGLE_BID_REQUEST:
      return {
        ...state,
        singleBidDetailsLoading: true,
      };
    case ActionTypes.SINGLE_BID_SUCCESS:
      return {
        ...state,
        singleBidDetailsLoading: false,
        singleBidDetailsdata: action.payload.data,
        singleBidDetailsError: "",
      };
    case ActionTypes.SINGLE_BID_FAIL:
      return {
        ...state,
        singleBidDetailsLoading: false,
        singleBidDetailsdata: [],
        singleBidDetailsError: action.payload.message,
      };
    default:
      return state;
  }
};

const AwardedJobsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_AWARDED_PROJECT_REQUEST:
      return {
        ...state,
        awardedJobLoading: true,
      };
    case ActionTypes.GET_AWARDED_PROJECT_SUCCESS:
      return {
        ...state,
        awardedJobLoading: false,
        awardedJobdata: action.payload.data,
        awardedJobError: "",
      };
    case ActionTypes.GET_AWARDED_PROJECT_FAIL:
      return {
        ...state,
        awardedJobLoading: false,
        awardedJobdata: [],
        awardedJobError: action.payload.message,
      };
    default:
      return state;
  }
};

const CompletedJobsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPLETED_JOB_REQUEST:
      return {
        ...state,
        completedJobsLoading: true,
      };
    case ActionTypes.GET_COMPLETED_JOB_SUCCESS:
      return {
        ...state,
        completedJobsLoading: false,
        completedJobsData: action.payload.data,
        completedJobsError: "",
      };
    case ActionTypes.GET_COMPLETED_JOB_FAIL:
      return {
        ...state,
        completedJobsLoading: false,
        completedJobsData: [],
        completedJobsError: action.payload.message,
      };
    default:
      return state;
  }
};

const DeleteJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.DELETE_JOB_REQUEST:
      return {
        ...state,
        deleteJobLoading: true,
      };
    case ActionTypes.DELETE_JOB_SUCCESS:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobMessage: action.payload.message,
        deleteJobError: "",
      };
    case ActionTypes.DELETE_JOB_FAIL:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobMessage: "",
        deleteJobError: action.payload.message,
      };
    case ActionTypes.EMPTY__DELETE_JOB_STATE:
      return {
        ...state,
        deleteJobLoading: false,
        deleteJobMessage: "",
        deleteJobError: "",
      };
    default:
      return state;
  }
};

const GetDeleteJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_DELETE_JOB_REQUEST:
      return {
        ...state,
        getDeleteJobLoading: true,
      };
    case ActionTypes.GET_DELETE_JOB_SUCCESS:
      return {
        ...state,
        getDeleteJobLoading: false,
        getDeleteJobData: action.payload.data,
        getDeleteJobError: "",
      };
    case ActionTypes.GET_DELETE_JOB_FAIL:
      return {
        ...state,
        getDeleteJobLoading: false,
        getDeleteJobData: [],
        getDeleteJobError: action.payload.message,
      };
    default:
      return state;
  }
};

const RestoreDeleteJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.RESTORE_DELETE_JOB_REQUEST:
      return {
        ...state,
        restoreDeleteJobLoading: true,
      };
    case ActionTypes.RESTORE_DELETE_JOB_SUCCESS:
      return {
        ...state,
        restoreDeleteJobLoading: false,
        isRestore: action.payload.success,
        restoreDeleteJobError: "",
        restoreDeleteJobMsg: action.payload.message,
      };
    case ActionTypes.RESTORE_DELETE_JOB_FAIL:
      return {
        ...state,
        restoreDeleteJobLoading: false,
        isRestore: false,
        restoreDeleteJobError: action.payload.message,
        restoreDeleteJobMsg: "",
      };
    case ActionTypes.EMPTY_RESTORE_DELETE_STATE:
      return {
        ...state,
        restoreDeleteJobLoading: false,
        isRestore: false,
        restoreDeleteJobError: "",
        restoreDeleteJobMsg: "",
      };
    default:
      return state;
  }
};

const AcceptProposalReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.ACCEPT_PROPOSAL_REQUEST:
      return {
        ...state,
        acceptProposalLoading: true,
      };
    case ActionTypes.ACCEPT_PROPOSAL_SUCCESS:
      return {
        ...state,
        acceptProposalLoading: false,
        acceptProposalData: action.payload.data,
        acceptProposalMsg: action.payload.message,
        acceptProposalError: "",
      };
    case ActionTypes.ACCEPT_PROPOSAL_FAIL:
      return {
        ...state,
        acceptProposalLoading: false,
        acceptProposalData: "",
        acceptProposalMsg: "",
        acceptProposalError: action.payload.message,
      };
    case ActionTypes.EMPTY_ACCEPT_PROPOSAL_STATE:
      return {
        ...state,
        acceptProposalLoading: false,
        acceptProposalMsg: "",
        acceptProposalError: "",
        acceptProposalData: "",
      };
    default:
      return state;
  }
};

const PermanentDeleteJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.PERMANENT_DELETE_JOB_REQUEST:
      return {
        ...state,
        permanentDeleteJobLoading: true,
      };
    case ActionTypes.PERMANENT_DELETE_JOB_SUCCESS:
      return {
        ...state,
        permanentDeleteJobLoading: false,
        isPermanentDelete: action.payload.success,
        permanentDeleteJobError: "",
        permanentDeleteJobMsg: action.payload.message,
      };
    case ActionTypes.PERMANENT_DELETE_JOB_FAIL:
      return {
        ...state,
        permanentDeleteJobLoading: false,
        isPermanentDelete: false,
        permanentDeleteJobError: action.payload.message,
        permanentDeleteJobMsg: "",
      };
    case ActionTypes.EMPTY_PERMANENT_DELETE_STATE:
      return {
        ...state,
        permanentDeleteJobLoading: false,
        isPermanentDelete: false,
        permanentDeleteJobError: "",
        permanentDeleteJobMsg: "",
      };
    default:
      return state;
  }
};

const GetAwardedJobMilestoneReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_AWARDED_JOB_MILESTONE_REQUEST:
      return {
        ...state,
        awardedJobMileStoneLoading: true,
      };
    case ActionTypes.GET_AWARDED_JOB_MILESTONE_SUCCESS:
      return {
        ...state,
        awardedJobMileStoneLoading: false,
        awardedJobMileStoneData: action.payload.data,
        awardedJobMileStoneMsg: action.payload.message,
      };
    case ActionTypes.GET_AWARDED_JOB_MILESTONE_FAIL:
      return {
        ...state,
        awardedJobMileStoneLoading: false,
        awardedJobMileStoneData: false,
        awardedJobMileStoneMsg: "",
        awardedJobMileStoneError: action.payload.message,
      };
    case ActionTypes.EMPTY_GET_AWARDED_JOB_MILESTONE_STATE:
      return {
        ...state,
        awardedJobMileStoneLoading: false,
        awardedJobMileStoneData: false,
        awardedJobMileStoneError: "",
        awardedJobMileStoneMsg: "",
      };
    default:
      return state;
  }
};

const AccpetedMileStoneReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.ACCEPT_MILESTONE_REQUEST:
      return {
        ...state,
        accpetedMileStoneLoading: true,
      };
    case ActionTypes.ACCEPT_MILESTONE_SUCCESS:
      return {
        ...state,
        accpetedMileStoneLoading: false,
        isStatusUpdate: !state.isStatusUpdate,
        accpetedMileStoneError: "",
      };
    case ActionTypes.ACCEPT_MILESTONE_FAIL:
      return {
        ...state,
        accpetedMileStoneLoading: false,
        accpetedMileStoneError: action.payload.message,
      };
    default:
      return state;
  }
};

//get single job
const GetSingleJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SINGLE_JOB_REQUEST:
      return {
        ...state,
        getSingleJobLoading: true,
      };
    case ActionTypes.GET_SINGLE_JOB_SUCCESS:
      return {
        ...state,
        getSingleJobLoading: false,
        getSingleJobData: action.payload.data,
        getSingleJobError: "",
      };
    case ActionTypes.GET_SINGLE_JOB_FAIL:
      return {
        ...state,
        getSingleJobLoading: false,
        getSingleJobData: [],
        getSingleJobError: action.payload.message,
      };
    default:
      return state;
  }
};

const AcceptEndContractReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.ACCEPT_END_CONTRACT_REQUEST:
      return {
        ...state,
        acceptEndContractLoading: true,
      };
    case ActionTypes.ACCEPT_END_CONTRACT_SUCCESS:
      return {
        ...state,
        acceptEndContractLoading: false,
        acceptEndContractData: !state.acceptEndContractData,
        acceptEndContractError: "",
      };
    case ActionTypes.ACCEPT_END_CONTRACT_FAIL:
      return {
        ...state,
        acceptEndContractLoading: false,
        acceptEndContractError: action.payload.message,
      };
    default:
      return state;
  }
};

//get single complete job
const GetSingleCompleteJobReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SINGLE_COMPLETE_JOB_REQUEST:
      return {
        ...state,
        getSingleJobCompleteLoading: true,
      };
    case ActionTypes.GET_SINGLE_COMPLETE_JOB_SUCCESS:
      return {
        ...state,
        getSingleJobCompleteLoading: false,
        getSingleCompleteJobData: action.payload.data,
      };
    case ActionTypes.GET_SINGLE_COMPLETE_JOB_FAIL:
      return {
        ...state,
        getSingleJobCompleteLoading: false,
        getSingleCompleteJobData: [],
      };
    default:
      return state;
  }
};

//get single complete job /bidder side
const GetSingleCompleteProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SINGLE_COMPLETE_PROJECT_REQUEST:
      return {
        ...state,
        getSingleProjectCompleteLoading: true,
      };
    case ActionTypes.GET_SINGLE_COMPLETE_PROJECT_SUCCESS:
      return {
        ...state,
        getSingleProjectCompleteLoading: false,
        getSingleCompleteProjectData: action.payload.data,
      };
    case ActionTypes.GET_SINGLE_COMPLETE_PROJECT_FAIL:
      return {
        ...state,
        getSingleProjectCompleteLoading: false,
        getSingleCompleteProjectData: [],
      };
    default:
      return state;
  }
};

export {
  GetAllUserProjectReducer,
  SingleProjectReducer,
  SingleBidsReducer,
  AwardedJobsReducer,
  DeleteJobReducer,
  CompletedJobsReducer,
  GetDeleteJobReducer,
  RestoreDeleteJobReducer,
  AcceptProposalReducer,
  CreateJobReducer,
  PermanentDeleteJobReducer,
  GetAwardedJobMilestoneReducer,
  AccpetedMileStoneReducer,
  GetSingleJobReducer,
  AcceptEndContractReducer,
  GetSingleCompleteJobReducer,
  GetSingleCompleteProjectReducer,
};
