import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./DatePicker.module.css";
import moment from "moment";
import { Controller } from "react-hook-form";
import { dateFormat } from "../../Utils/utils";

import { alpha, styled } from "@mui/material/styles";

const SuccessSlider = styled(({ theme }) => ({
  width: 300,
}));

export default function DatePickerCmp({
  label,
  onChange,
  control,
  name,
  style,
  ...props
}) {
  const [value, setValue] = React.useState(null);
  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={[]}
      defaultValue={""}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            sx={{ width: "100%", ...style }}
            className={styles.datePicker}
            value={value}
            onChange={(newValue) => {
              onChange(newValue._d);
              setValue(newValue);
              // console.log(moment(newValue._d).format("l"), "moment");
            }}
            InputProps={{
              className: styles.datePickerInput,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // placeholder="Select multiple tags"
                label={label}
                value={value}
                // variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                helperText={error ? error.message : null}
                className={styles.dateInput}
              />
            )}
            {...props}
          />
        </LocalizationProvider>
      )}
    />
  );
}
