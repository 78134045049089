import ActionTypes from "../Constants";

const INITIALSTATE = {
  // MyBids all Projects
  myBidsProjectLoading: false,
  myBidsProjectdata: "",
  myBidsProjectMessage: "",

  // MyBids Project Details
  myBidsProjectDetailsLoading: false,
  myBidsProjectDetailsdata: "",
  myBidsProjectDetailsMessage: "",
  myBidsProjectDetailsError: "",

  // MyBids Create Proposal
  createProposalLoading: false,
  createProposalMessage: "",
  createProposalError: "",

  // MyBids Update Proposal
  updateProposalLoading: false,
  updateProposalMessage: "",
  updateProposalError: "",

  // MyBids Project Details
  myBidsSubmittedBidDetailsLoading: false,
  myBidsSubmittedBidDetailsdata: "",
  myBidsSubmittedBidDetailsMessage: "",
  myBidsSubmittedBidDetailsError: "",

  ////wishlist
  isWishListState: "",
  myBidsAddingtoWishListLoading: false,
  refreshMyBids: false,
  myBidsUpdatedData: "",

  // Wishlisted Projects
  myBidsWishlistedProjectsLoading: false,
  myBidsWishlistedProjectsdata: "",
  myBidsWishlistedProjectsMessage: "",
  myBidsWishlistedProjectsError: "",

  // Applied Projects
  myBidsAppliedProjectsLoading: false,
  myBidsAppliedProjectsdata: "",
  myBidsAppliedProjectsMessage: "",
  myBidsAppliedProjectsError: "",

  // Awarded Projects
  myBidsAwardedProjectsLoading: false,
  myBidsAwardedProjectsdata: "",
  myBidsAwardedProjectsMessage: "",
  myBidsawardedProjectsError: "",

  // MyBids Completed Projects
  myBidsCompletedProjectLoading: false,
  myBidsCompletedProjectdata: "",
  myBidsCompletedProjectMessage: "",

  //Add milestones
  addMileStoneLoading: false,
  addMileStoneData: "",
  addMileStoneError: "",

  //Delete milestones
  delelteMileStoneLoading: false,
  isMilestoneDelete: false,
  delelteMileStoneError: "",

  //edit milestones
  editeMileStoneLoading: false,
  isMilestoneEdit: false,
  editeMileStoneError: "",

  //end contract
  endContractLoading: false,
  endContractData: false,
  endContractError: "",

  //owner rating job
  ownerRatingLoading: false,
  ownerRatingData: false,
  ownerRatingError: "",

  //bidder rating job
  bidderRatingLoading: false,
  bidderRatingData: false,
  bidderRatingError: "",
};

const refreshMyBids = false;

const MyBidsProjectReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_PROJECT_REQUEST:
      return {
        ...state,
        myBidsProjectLoading: true,
      };
    case ActionTypes.GET_MYBIDS_PROJECT_SUCCESS:
      return {
        ...state,
        myBidsProjectLoading: false,
        myBidsProjectdata: action.payload.data,
        myBidsProjectMessage: "",
      };
    case ActionTypes.GET_MYBIDS_PROJECT_FAIL:
      return {
        ...state,
        myBidsProjectLoading: false,
        myBidsProjectdata: "",
        myBidsProjectMessage: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsProjectDetailsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        myBidsProjectDetailsLoading: true,
      };
    case ActionTypes.GET_MYBIDS_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        myBidsProjectDetailsLoading: false,
        myBidsProjectDetailsdata: action.payload.data,
        myBidsProjectDetailsMessage: "",
      };
    case ActionTypes.GET_MYBIDS_PROJECT_DETAILS_FAIL:
      return {
        ...state,
        myBidsProjectDetailsLoading: false,
        myBidsProjectDetailsdata: [],
        myBidsProjectDetailsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsCreateProposalReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.MYBIDS_CREATE_PROPOSAL_REQUEST:
      return {
        ...state,
        createProposalLoading: true,
      };
    case ActionTypes.MYBIDS_CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        createProposalLoading: false,
        createProposalMessage: action.payload.message,
      };
    case ActionTypes.MYBIDS_CREATE_PROPOSAL_FAIL:
      return {
        ...state,
        createProposalLoading: false,
        createProposalError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsSubmittedBidDetailsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_REQUEST:
      return {
        ...state,
        myBidsSubmittedBidDetailsLoading: true,
      };
    case ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_SUCCESS:
      return {
        ...state,
        myBidsSubmittedBidDetailsLoading: false,
        myBidsSubmittedBidDetailsdata: action.payload.data,
        myBidsSubmittedBidDetailsMessage: "",
      };
    case ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_FAIL:
      return {
        ...state,
        myBidsWishlistedSubmittedBidLoading: false,
        myBidsWishlistedSubmittedBidsdata: [],
        myBidsWishlistedSubmittedBidsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsUpdateProposalReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.MYBIDS_UPDATE_PROPOSAL_REQUEST:
      return {
        ...state,
        updateProposalLoading: true,
      };
    case ActionTypes.MYBIDS_UPDATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        updateProposalLoading: false,
        updateProposalMessage: action.payload.message,
      };
    case ActionTypes.MYBIDS_UPDATE_PROPOSAL_FAIL:
      return {
        ...state,
        updateProposalLoading: false,
        updateProposalError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsAddToWishlistReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.MYBIDS_ADD_TO_WISHLIST_REQUEST:
      return {
        ...state,
        myBidsAddingtoWishListLoading: true,
      };
    case ActionTypes.MYBIDS_ADD_TO_WISHLIST_SUCCESS:
      const newArray = { ...action.payload.data }; //making a new array
      let index;
      let onlyWishlistedProjects;
      if (action.payload.page !== "MyBidDetail") {
        if (action.payload.page === "Wishlisted") {
          onlyWishlistedProjects = action.payload.data.records?.filter(
            (data) => data.project.slug !== action.payload.slug
          );
          newArray.records = onlyWishlistedProjects;
        } else {
          index = action.payload.data.records?.findIndex(
            (project) => project.slug == action.payload.slug
          );
          newArray.records[index].is_in_wishlist = action.payload.isWishList;
        }
      }
      return {
        ...state,
        myBidsAddingtoWishListLoading: false,
        isWishListState: action.payload.isWishList,
        refreshMyBids: !refreshMyBids,
        myBidsUpdatedData: newArray,
      };
    case ActionTypes.MYBIDS_ADD_TO_WISHLIST_FAIL:
      return {
        ...state,
        myBidsWishlistedProjectsLoading: false,
        myBidsWishlistedProjectsdata: [],
        myBidsWishlistedProjectsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsWishlistedProjectsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_REQUEST:
      return {
        ...state,
        myBidsWishlistedProjectsLoading: true,
      };
    case ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_SUCCESS:
      return {
        ...state,
        myBidsWishlistedProjectsLoading: false,
        myBidsWishlistedProjectsdata: action.payload.data,
        myBidsWishlistedProjectsError: "",
      };
    case ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_FAIL:
      return {
        ...state,
        myBidsWishlistedProjectsLoading: false,
        myBidsWishlistedProjectsdata: [],
        myBidsWishlistedProjectsError: action.payload.message,
      };
    default:
      return state;
  }
};
const MyBidsAppliedProjectsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_APPLIED_PROJECT_REQUEST:
      return {
        ...state,
        myBidsAppliedProjectsLoading: true,
      };
    case ActionTypes.GET_MYBIDS_APPLIED_PROJECT_SUCCESS:
      return {
        ...state,
        myBidsAppliedProjectsLoading: false,
        myBidsAppliedProjectsdata: action.payload.data,
        myBidsAppliedProjectsError: "",
      };
    case ActionTypes.GET_MYBIDS_APPLIED_PROJECT_FAIL:
      return {
        ...state,
        myBidsAppliedProjectsLoading: false,
        myBidsAppliedProjectsdata: [],
        myBidsAppliedProjectsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsAwardedProjectsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_AWARDED_PROJECT_REQUEST:
      return {
        ...state,
        myBidsAwardedProjectsLoading: true,
      };
    case ActionTypes.GET_MYBIDS_AWARDED_PROJECT_SUCCESS:
      return {
        ...state,
        myBidsAwardedProjectsLoading: false,
        myBidsAwardedProjectsdata: action.payload.data,
        myBidsAwardedProjectsError: "",
      };
    case ActionTypes.GET_MYBIDS_AWARDED_PROJECT_FAIL:
      return {
        ...state,
        myBidsAwardedProjectsLoading: false,
        myBidsAwardedProjectsdata: [],
        myBidsAwardedProjectsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MyBidsCompletedProjectReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_REQUEST:
      return {
        ...state,
        myBidsCompletedProjectLoading: true,
      };
    case ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_SUCCESS:
      return {
        ...state,
        myBidsCompletedProjectLoading: false,
        myBidsCompletedProjectdata: action.payload.data,
        myBidsCompletedProjectMessage: "",
      };
    case ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_FAIL:
      return {
        ...state,
        myBidsCompletedProjectLoading: false,
        myBidsCompletedProjectdata: "",
        myBidsCompletedProjectMessage: action.payload.message,
      };
    default:
      return state;
  }
};

const AddMileStoneReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.ADD_MILESTONE_REQUEST:
      return {
        ...state,
        addMileStoneLoading: true,
      };
    case ActionTypes.ADD_MILESTONE_SUCCESS:
      return {
        ...state,
        addMileStoneLoading: false,
        addMileStoneData: action.payload.data,
        addMileStoneError: "",
      };
    case ActionTypes.ADD_MILESTONE_FAIL:
      return {
        ...state,
        addMileStoneLoading: false,
        addMileStoneError: action.payload.message,
      };
    case ActionTypes.EMPTY_ADD_MILESTONE_STATE:
      return {
        ...state,
        addMileStoneLoading: false,
        addMileStoneError: "",
      };
    default:
      return state;
  }
};

const DeleteMileStoneReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.DELETE_MILESTONE_REQUEST:
      return {
        ...state,
        delelteMileStoneLoading: true,
      };
    case ActionTypes.DELETE_MILESTONE_SUCCESS:
      return {
        ...state,
        delelteMileStoneLoading: false,
        isMilestoneDelete: !state.isMilestoneDelete,
        delelteMileStoneError: "",
      };
    case ActionTypes.DELETE_MILESTONE_FAIL:
      return {
        ...state,
        delelteMileStoneLoading: false,
        delelteMileStoneError: action.payload.message,
      };
    case ActionTypes.EMPTY_DELETE_MILESTONE_STATE:
      return {
        ...state,
        delelteMileStoneLoading: false,
        delelteMileStoneError: "",
      };
    default:
      return state;
  }
};

const EditMileStoneReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.EDIT_MILESTONE_REQUEST:
      return {
        ...state,
        editeMileStoneLoading: true,
      };
    case ActionTypes.EDIT_MILESTONE_SUCCESS:
      return {
        ...state,
        editeMileStoneLoading: false,
        isMilestoneEdit: !state.isMilestoneEdit,
        editeMileStoneError: "",
      };
    case ActionTypes.EDIT_MILESTONE_FAIL:
      return {
        ...state,
        editeMileStoneLoading: false,
        editeMileStoneError: action.payload.message,
      };
    case ActionTypes.EMPTY_EDIT_MILESTONE_STATE:
      return {
        ...state,
        editeMileStoneLoading: false,
        editeMileStoneError: "",
      };
    default:
      return state;
  }
};

const EndContractReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.END_CONTRACT_REQUEST:
      return {
        ...state,
        endContractLoading: true,
      };
    case ActionTypes.END_CONTRACT_SUCCESS:
      return {
        ...state,
        endContractLoading: false,
        endContractData: action.payload.data,
        endContractError: "",
      };
    case ActionTypes.END_CONTRACT_FAIL:
      return {
        ...state,
        endContractLoading: false,
        endContractError: action.payload.message,
      };
    case ActionTypes.EMPTY_END_CONTRACT_STATE:
      return {
        ...state,
        endContractLoading: false,
        endContractError: "",
      };
    default:
      return state;
  }
};
const OwnerRatingReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.OWNER_RATING_REQUEST:
      return {
        ...state,
        ownerRatingLoading: true,
      };
    case ActionTypes.OWNER_RATING_SUCCESS:
      return {
        ...state,
        ownerRatingLoading: false,
        ownerRatingData: action.payload.data,
        ownerRatingError: "",
      };
    case ActionTypes.OWNER_RATING_FAIL:
      return {
        ...state,
        ownerRatingLoading: false,
        ownerRatingData: "",
        ownerRatingError: action.payload.message,
      };
    default:
      return state;
  }
};

const BidderRatingReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.BIDDER_RATING_REQUEST:
      return {
        ...state,
        bidderRatingLoading: true,
      };
    case ActionTypes.BIDDER_RATING_SUCCESS:
      return {
        ...state,
        bidderRatingLoading: false,
        bidderRatingData: action.payload.data,
        bidderRatingError: "",
      };
    case ActionTypes.BIDDER_RATING_FAIL:
      return {
        ...state,
        bidderRatingLoading: false,
        bidderRatingData: "",
        bidderRatingError: action.payload.message,
      };
    default:
      return state;
  }
};

export {
  MyBidsProjectReducers,
  MyBidsProjectDetailsReducer,
  MyBidsAddToWishlistReducer,
  MyBidsWishlistedProjectsReducer,
  MyBidsAppliedProjectsReducer,
  MyBidsCreateProposalReducer,
  MyBidsSubmittedBidDetailsReducer,
  MyBidsAwardedProjectsReducer,
  MyBidsCompletedProjectReducers,
  AddMileStoneReducer,
  DeleteMileStoneReducer,
  EditMileStoneReducer,
  EndContractReducer,
  MyBidsUpdateProposalReducer,
  OwnerRatingReducers,
  BidderRatingReducers,
};
