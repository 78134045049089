import { Grid, Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import useGetUserRole from "../../../Hooks/getUserRole";

import {
	BodyLayout,
	ManufacturerProductCard,
	WhiteButtonCmp,
	LoaderCmp,
	IconCmp,
	BackDropCmp,
	RatingCmp,
} from "../../../Components";
//import EditProductForm from "./EditProductForm";
import Icon from "../../../Assets/Images/star hammer.png";
import styles from "./ViewProductDetails.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetailsAction } from "../../../Store/Actions/ManufacturerActions";
import useAlert from "../../../Hooks/useAlert/useAlert";
import { BiEdit, PlaceholderImg } from "../../../Assets/index";
import {
	GetColorsAction,
	GetMaterialsAction,
	GetCoatsAction,
	GetPrimersAction,
	GetDistributorsAction,
	GetSuppliersAction,
	GetFinishingsAction,
	GetPatternsAction,
	GetGlassTypesAction,
} from "../../../Store/Actions/GetActions";

const images1 = [
	{
		original:
			// "https://s3-alpha.figma.com/hub/file/948140848/1f4d8ea7-e9d9-48b7-b70c-819482fb10fb-cover.png",
			PlaceholderImg,
		originalClass: styles.originalImg,
		// thumbnail:
		//   "https://s3-alpha.figma.com/hub/file/948140848/1f4d8ea7-e9d9-48b7-b70c-819482fb10fb-cover.png",
	},
];

const ViewProductDetails = () => {
	const { productSlug } = useParams();
	const dispatch = useDispatch();
	const { setAlert } = useAlert();
	const navigate = useNavigate();

	const { colorsdata } = useSelector((state) => state.ColorsReducers);
	const { materialsdata } = useSelector((state) => state.MaterialsReducers);
	const { coatsdata } = useSelector((state) => state.CoatsReducers);
	const { primersdata } = useSelector((state) => state.PrimersReducers);
	const { finishingsdata } = useSelector((state) => state.FinishingsReducers);
	const { patternsdata } = useSelector((state) => state.PatternsReducers);
	const { glassTypesdata } = useSelector((state) => state.GlassTypesReducers);

	const DEFAULT = "Default";

	useEffect(() => {
		dispatch(GetColorsAction());
		dispatch(GetMaterialsAction());
		dispatch(GetCoatsAction());
		dispatch(GetPrimersAction());
		dispatch(GetDistributorsAction());
		dispatch(GetSuppliersAction());
		dispatch(GetFinishingsAction());
		dispatch(GetPatternsAction());
		dispatch(GetGlassTypesAction());
	}, []);

	const { productDetailsdata, productDetailsLoading } = useSelector(
		(state) => state.ProductDetailsReducer
	);
	const { updateProductLoading, updateProductdata } = useSelector(
		(state) => state.UpdateProductReducer
	);

	const [productDetails, setProductDetails] = useState(
		productDetailsdata?.product_detail || ""
	);
	const [showLess, setShowLess] = useState(false);
	const [editProduct, setEditProduct] = useState(false);

	useEffect(() => {
		dispatch(ProductDetailsAction(productSlug, setAlert));
	}, [productSlug]);

	useEffect(() => {
		if (productDetailsdata) {
			setProductDetails(productDetailsdata?.product_detail);
		}
	}, [productDetailsdata]);

	useEffect(() => {
		if (updateProductdata) {
			setProductDetails(updateProductdata);
			setEditProduct(false);
		}
	}, [updateProductdata]);

	const {
		title,
		slug,
		brand,
		sku,
		description,
		model_no,
		colors,
		type,
		material,
		finish,
		width,
		height,
		length,
		distributor,
		supplier,
		rooms,
		quantity,
		style,
		sdl,
		coat_one,
		coat_two,
		primer,
		pattern,
		joint,
		glass_type,
		installer,
		other,
		images,
		model_name,
		category,
		symbols,
		depth,
		item,
		item_no,
		do_not_include,
		custom,
		ImgURL,
		variants,
		measurements,
		reviews,
	} = productDetails || {};

	// const ImgURL = "https://buildlink.designfinix.com/uploads/products/";

	const temp =
		images?.length > 0
			? images
					//.filter((item) => item.is_thumbnail != 1)
					.map((item) => {
						return {
							original: ImgURL + item.image,
							thumbnail: ImgURL + item.image,
							originalClass: styles.originalImg,
						};
					})
			: [];

	colors?.forEach((color, index) => {
		temp.push({
			original: ImgURL + color.image,
			thumbnail: ImgURL + color.image,
			originalClass: styles.originalImg,
		});
	});

	const userRole = useGetUserRole();

	const edit = () => {
		navigate(`/${userRole}/editproduct/${slug}`, {
			state: {
				colorsdata: colorsdata,
				materialsdata: materialsdata,
				coatsdata: coatsdata,
				primersdata: primersdata,
				finishingsdata: finishingsdata,
				patternsdata: patternsdata,
				glassTypesdata: glassTypesdata,
			},
		});
	};

	return productDetailsLoading ? (
		<LoaderCmp />
	) : (
		productDetailsdata && productDetails && (
			<BodyLayout>
				<section className={styles.productDetail_wrapper}>
					{
						<>
							<div className={styles.product_wrapper}>
								<Grid container>
									<Grid
										item
										lg={4}
										sx={{ width: "100%" }}
									>
										<ImageGallery
											items={temp || images1}
											showFullscreenButton={false}
											lazyLoad={true}
											autoPlay={true}
											showPlayButton={false}
											showNav={false}
											showThumbnails={true}
											additionalClass={styles.imagesSlider}
										/>
									</Grid>

									<Grid
										item
										lg={8}
									>
										<section className={styles.details_wrapper}>
											<div className={styles.details_productName}>
												<section>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
														}}
													>
														<Typography
															variant="h5"
															sx={{ fontWeight: "bold" }}
														>
															{title ? title : "GenAir Elite"}
														</Typography>
														<div onClick={edit}>
															<IconCmp>
																<BiEdit />
															</IconCmp>
														</div>
													</div>

													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															gap: "20px",
														}}
													>
														<Box sx={{ gap: "10px" }}>
															<Typography
																sx={{
																	color: "var(--textColor)",
																	fontSize: "14px",
																	mt: "3px",
																}}
															>
																SKU
															</Typography>
															<Typography
																sx={{ fontSize: "12px", fontWeight: "bold" }}
															>
																{sku ? sku : "00708"}
															</Typography>
														</Box>
													</Box>
												</section>
												<Box sx={{ mt: "20px" }}>
													<Typography sx={{ fontSize: "14px" }}>
														{description
															? description
															: "lorem ipsum dolor sit amet "}
													</Typography>
												</Box>
											</div>
											<section className={styles.productCategory}>
												<Typography
													variant="h5"
													sx={{ fontWeight: "bold" }}
												>
													Information
												</Typography>
												<Grid
													container
													rowSpacing={3}
													sx={{ mt: 1 }}
												>
													<Grid
														item
														lg={12}
													>
														<div>
															<Typography
																sx={{
																	color: "var(--textColor)",
																	fontSize: "14px",
																}}
															>
																Category
															</Typography>
															<Typography
																sx={{ fontSize: "16px", fontWeight: "bold" }}
															>
																{category?.title
																	? category?.title
																	: "General Category"}
															</Typography>
														</div>
													</Grid>

													<Grid
														item
														lg={12}
													>
														<div>
															<Typography
																sx={{
																	color: "var(--textColor)",
																	fontSize: "14px",
																}}
															>
																Brand
															</Typography>
															<Typography
																sx={{ fontSize: "16px", fontWeight: "bold" }}
															>
																{brand?.title ? brand.title : "General Brand"}
															</Typography>
														</div>
													</Grid>
												</Grid>
											</section>
										</section>
									</Grid>
									<Grid
										item
										lg={12}
									>
										<section className={styles.details_wrapper}>
											<section className={styles.productCategory}>
												<Typography
													variant="h5"
													sx={{ fontWeight: "bold" }}
												>
													Colors
												</Typography>
												<Grid
													container
													rowSpacing={3}
												>
													{Array.from(colors).map((color, index) => (
														<Grid
															container
															sx={{ mt: 6 }}
															rowSpacing={3}
															key={`${color.color_id}-input`}
														>
															{/* <Grid
																item
																lg={12}
															>
																<img
																	src={ImgURL + color.image}
																	alt="Thumbnail"
																	style={{
																		maxWidth: "200px",
																		maxHeight: "200px",
																	}}
																/>
															</Grid> */}
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Name
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{color.color.title}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																{" "}
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Material
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			materialsdata?.find(
																				(x) => x.id == color.material_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Primer
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			primersdata?.find(
																				(x) => x.id == color.primer_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		First Coat
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			coatsdata?.find(
																				(x) => x.id == color.primer_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Second Coat
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			coatsdata?.find(
																				(x) => x.id == color.primer_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Finish
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{color?.finishings?.map((x, i) => {
																			return (
																				x.finishing.title +
																				(i != color?.finishings.length - 1
																					? "/ "
																					: "")
																			);
																		})}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Pattern
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			patternsdata?.find(
																				(x) => x.id == color.pattern_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Glass Type
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{
																			glassTypesdata?.find(
																				(x) => x.id == color.glass_type_id
																			)?.title
																		}
																	</Typography>
																</div>
															</Grid>
															<Grid
																item
																lg={3}
															>
																<div>
																	<Typography
																		sx={{
																			color: "var(--textColor)",
																			fontSize: "14px",
																		}}
																	>
																		Joint
																	</Typography>
																	<Typography
																		sx={{
																			fontSize: "16px",
																			fontWeight: "bold",
																		}}
																	>
																		{color.joint}
																	</Typography>
																</div>
															</Grid>
														</Grid>
													))}
												</Grid>
											</section>
										</section>
									</Grid>
									<Grid
										item
										lg={12}
									>
										<section className={styles.details_wrapper}>
											<section className={styles.productCategory}>
												<Typography
													variant="h5"
													sx={{ fontWeight: "bold" }}
												>
													Measurements
												</Typography>
												<Grid
													container
													rowSpacing={3}
												>
													{Array.from(measurements).map(
														(measurement, index) => (
															<Grid
																container
																sx={{ mt: 6 }}
																rowSpacing={3}
																key={`${measurement.id}-input`}
															>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Size
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.title}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Width
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.width}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Height
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.height}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Length
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.length}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Depth
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.depth}
																		</Typography>
																	</div>
																</Grid>
																<Grid
																	item
																	lg={3}
																>
																	<div>
																		<Typography
																			sx={{
																				color: "var(--textColor)",
																				fontSize: "14px",
																			}}
																		>
																			Weight
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "16px",
																				fontWeight: "bold",
																			}}
																		>
																			{measurement.weight}
																		</Typography>
																	</div>
																</Grid>
															</Grid>
														)
													)}
												</Grid>
											</section>
										</section>
									</Grid>
									<Grid
										item
										lg={12}
									>
										{" "}
										<section className={styles.details_wrapper}>
											<section className={styles.productCategory}>
												<Typography
													variant="h5"
													sx={{ fontWeight: "bold" }}
												>
													Prices
												</Typography>
												<Grid
													item
													lg={12}
												>
													<table className={styles.priceTable}>
														<thead>
															<tr>
																<th
																	style={{ width: "160px", fontSize: "0.9rem" }}
																>
																	Size
																</th>
																<th
																	style={{ width: "250px", fontSize: "0.9rem" }}
																>
																	Color
																</th>
																<th style={{ fontSize: "0.9rem" }}>Price</th>
																<th style={{ fontSize: "0.9rem" }}>
																	Disc. Price
																</th>
																<th style={{ fontSize: "0.9rem" }}>Quantity</th>
																<th style={{ fontSize: "0.9rem" }}>
																	Model No.
																</th>
																<th style={{ fontSize: "0.9rem" }}>SKU</th>
																<th style={{ fontSize: "0.9rem" }}>
																	Color Image
																</th>
																<th
																	style={{ width: "80px", fontSize: "0.9rem" }}
																>
																	Active
																</th>
																<th
																	style={{ width: "80px", fontSize: "0.9rem" }}
																>
																	Default
																</th>
															</tr>
														</thead>
														<tbody>
															{variants?.map((price, index) => (
																<tr
																	key={
																		price.measurement_size + price.color_title
																	}
																>
																	<td>
																		{price.size === DEFAULT ? (
																			<span
																				className={styles.defaultSize}
																			></span>
																		) : (
																			<Box>
																				<span
																					className={
																						styles[
																							price.measurement_size?.replace(
																								" ",
																								""
																							)
																						]
																					}
																				></span>
																				<span>{price.measurement_size}</span>
																			</Box>
																		)}
																	</td>
																	<td>
																		{price?.color === DEFAULT ? (
																			<span
																				className={styles.defaultColor}
																			></span>
																		) : (
																			<Box>
																				<span
																					style={{
																						width: "20px",
																						height: "20px",
																						backgroundColor: `${price?.color_code}`,
																						paddingLeft: "20px",
																						paddingRight: "20px",
																						borderRadius: "5px",
																						marginRight: "20px",
																					}}
																				></span>
																				<span>{`${price?.color_title}`}</span>
																			</Box>
																		)}
																	</td>
																	<td>
																		<Box>
																			<span>{`${price.price}`}</span>
																		</Box>
																	</td>
																	<td>
																		<Box>
																			<span>{`${price.discounted_price}`}</span>
																		</Box>
																	</td>
																	<td>
																		<Box>
																			<span>{`${price.quantity}`}</span>
																		</Box>
																	</td>
																	<td>
																		<Box>
																			<span>{`${price.model_no}`}</span>
																		</Box>
																	</td>
																	<td>
																		<Box>
																			<span>{`${price.sku}`}</span>
																		</Box>
																	</td>{" "}
																	<td>
																		<Box>
																			{colors.find(
																				(x) =>
																					x.color.title == price.color_title
																			) && (
																				<div>
																					{colors.find(
																						(x) =>
																							x.color.title == price.color_title
																					)?.image == null ? (
																						<p>No image</p>
																					) : (
																						<img
																							src={
																								ImgURL +
																								colors.find(
																									(x) =>
																										x.color.title ==
																										price.color_title
																								)?.image
																							}
																							alt="Thumbnail"
																							style={{
																								maxWidth: "60px",
																								maxHeight: "60px",
																							}}
																						/>
																					)}
																				</div>
																			)}
																		</Box>
																	</td>{" "}
																	<td>
																		<Box>
																			<span>{`${
																				price.is_available ? "YES" : "NO"
																			}`}</span>
																		</Box>
																	</td>
																	<td>
																		<Box>
																			<span>{`${
																				price.is_default ? "YES" : "NO"
																			}`}</span>
																		</Box>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</Grid>
											</section>
										</section>
									</Grid>
								</Grid>
							</div>

							{/* /////PRODUCT LISTING/// */}

							<section className={styles.similarProductListing}>
								<section>
									<Typography
										variant="h5"
										sx={{ fontWeight: "bold" }}
									>
										Similar Product
									</Typography>

									<Box sx={{ my: "40px" }}>
										<Grid
											container
											columnSpacing={3}
										>
											{productDetailsdata.related_product &&
												productDetailsdata.related_product.map(
													(product, index) => {
														return (
															<Grid
																item
																lg={3}
																key={index}
															>
																<ManufacturerProductCard
																	product={product}
																	// key={product.id}
																	path={`/manufacturer/viewproduct/viewproductdetail/${product.slug}`}
																/>{" "}
																<br />
															</Grid>
														);
													}
												)}
										</Grid>
									</Box>
								</section>
							</section>

							<section>
								<Typography
									sx={{
										fontSize: "26px",
										fontWeight: "bold",
										mb: "1rem",
									}}
								>
									Reviews
								</Typography>
								{reviews &&
									reviews.map((review) => {
										return (
											<Box
												sx={{
													borderTop: "1px solid #4d4d4d",
													py: "1rem",
												}}
											>
												<Box>
													<Box>
														<Typography
															sx={{
																fontSize: "20px",
																fontWeight: "bold",
																mb: "0.4rem",
															}}
														>
															{review.user.first_name +
																" " +
																review.user.last_name}{" "}
														</Typography>
														{}
													</Box>
												</Box>
												<Box>
													<Typography
														sx={{
															fontSize: "16px",

															mb: "0.2rem",
														}}
													>
														Overall
													</Typography>

													<RatingCmp
														ratingValue={review.avg_rating}
														readOnly={true}
													/>
												</Box>
												<Box>
													<Typography
														sx={{
															fontSize: "16px",

															mb: "0.2rem",
														}}
													>
														Product
													</Typography>
													<RatingCmp
														ratingValue={review.rating}
														readOnly={true}
													/>
												</Box>
												<Box>
													<Typography
														sx={{
															fontSize: "16px",

															mb: "0.2rem",
														}}
													>
														Service
													</Typography>

													<RatingCmp
														ratingValue={review.store_service}
														readOnly={true}
													/>
												</Box>
												<Box>
													<Typography
														sx={{
															fontSize: "16px",

															mb: "0.2rem",
														}}
													>
														Shipping
													</Typography>

													<RatingCmp
														ratingValue={review.store_ship}
														readOnly={true}
													/>
												</Box>
												<Box>
													<Typography
														sx={{
															fontSize: "16px",
															fontWeight: "bold",
															mb: "0.2rem",
														}}
													>
														Comment
													</Typography>
													{review.comments}
												</Box>
											</Box>
										);
									})}
							</section>
						</>
					}
				</section>
				<BackDropCmp setOpen={updateProductLoading} />
			</BodyLayout>
		)
	);
};

export default ViewProductDetails;
