import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCmp } from "..";
import { LocationOnOutlinedIcon, AccountAlert } from "../../Assets";

import ConnectionPic from "../../Assets/Images/connectionPic.png";
import useAlert from "../../Hooks/useAlert/useAlert";
import {
  CreateConnectionAction,
  EmptyCreateConnectionAction,
  // remove Connection / Freind request
  RemoveConnectionRequestAction,
  // remove Connection / Freind
  RemoveConnectionAction,
} from "../../Store/Actions/ProConnectionAction";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
import styles from "./ConnectionCard.module.css";

const ConnectionCard = ({
  pending,
  open,
  setOpen,
  userConnectin,
  setUserDetails,
}) => {
  console.log("userConnectin", userConnectin);
  const [pendingRequest, setPendingRequest] = useState(false);
  const {
    name,
    street_address,
    is_connected,
    image,
    ImgURL,
    email,
    status,
    sent_request_pending,
    received_request_pending,
    services,
  } = userConnectin || {};
  console.log("pending status", pending);
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { createConnectiondata } = useSelector(
    (state) => state.CreateConnectionReducer
  );

  //send request handler
  const createConnection = () => {
    dispatch(CreateConnectionAction(email, setAlert));
  };
  const removeConnectionRequest = () => {
    dispatch(RemoveConnectionRequestAction(email, setAlert));
  };

  const removeConnection = () => {
    dispatch(RemoveConnectionAction(email, setAlert));
  };

  // useEffect(() => {
  //   if (createConnectiondata) {
  //     setPendingRequest(createConnectiondata);
  //     dispatch(EmptyCreateConnectionAction());
  //   }
  // }, [createConnectiondata]);

  return (
    <section className={styles.connectionCard}>
      <div className={styles.connectionCardImg}>
        <img src={ImgURL + image} alt="" />
        {pending && (
          <div className={styles.threeDots}>
            <ThreeDotsMenu removeConnectionRequest={removeConnectionRequest} />
          </div>
        )}
      </div>
      <div className={styles.connectionCardName}>
        <Typography
          onClick={() => {
            setOpen(!open);
            setUserDetails(userConnectin);
          }}
          noWrap
          variant="h5"
          fontWeight={"bold"}
          sx={{ cursor: "pointer" }}
        >
          {name && name}
        </Typography>
        <Typography noWrap sx={{ fontSize: "14px", my: "5px" }}>
          Contractor
        </Typography>
        <Typography
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            justifyContent: "center",
            my: "5px",
          }}
        >
          <LocationOnOutlinedIcon sx={{ color: "var(--primary-color)" }} />
          New Orleans, LA
        </Typography>
      </div>
      <div className={styles.connectionCardTags}>
        {services && services?.length > 0 ? (
          <>
            <section className={styles.scrollTabsBar2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={AccountAlert} />
                <Typography sx={{ fontSize: "14px" }}>
                  {services[0].service.title}
                </Typography>
              </Box>
            </section>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                {services.length - 1} more...
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <section className={styles.scrollTabsBar2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={AccountAlert} />
                <Typography sx={{ fontSize: "14px" }}>No service</Typography>
              </Box>
            </section>
          </>
        )}
      </div>
      <div className={styles.connectionCardBtn}>
        {/* {!pending ? (
          is_connected ? (
            <ButtonCmp title="Connected" />
          ) : (
            <ButtonCmp title="Connect" onClick={createConnection} />
          )
        ) : (
          <ButtonCmp
            title="Pending"
            disabled
            style={{
              backgroundColor: "var(--disabledColor) !important",
              color: "white !important",
            }}
          />
        )} */}

        {/* {pending == "pending" ? (
          <ButtonCmp title="pending Request" disabled />
        ) : pendingRequest ? (
          <ButtonCmp title="pending Request" />
        ) : (
          <ButtonCmp title="Connect" onClick={createConnection} />
        )} */}
        {is_connected ? (
          <ButtonCmp
            title="DisConnect"
            style={{ backgroundColor: "var(--redColor)" }}
            onClick={removeConnection}
          />
        ) : pending ? (
          <ButtonCmp title="pending Request" disabled />
        ) : (
          <ButtonCmp title="Connect" onClick={createConnection} />
        )}
      </div>
    </section>
  );
};

export default ConnectionCard;
