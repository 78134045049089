import IconCmp from "../IconCmp/IconCmp";
import { CloseIcon, RiDeleteBin6Line } from "../../Assets";
import styles from "./ViewImageModal.module.css";
import WhiteButtonCmp from "../ButtonCmp/WhiteButtonCmp";
import Backdrop from "@mui/material/Backdrop";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Avatar from "react-avatar-edit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useAlert from "../../Hooks/useAlert/useAlert";
import ImageGallery from "react-image-gallery";
import { useRef } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 700,
	bgcolor: "background.paper",
	border: "none",
	boxShadow: 24,
	outline: "none !important",
	p: 4,
};

export default function VIewImagesModal({
	open,
	setOpen,
	projectImages,
	deleteGalleryPic,
}) {
	const imageSlider = useRef(null);
	const [slideImgIndex, setSlideImgIndex] = React.useState("");

	const handleClose = () => setOpen(false);

	const images = projectImages.map((img) => {
		return {
			original: img.imgSrc,
			originalClass: styles.originalImg,
			thumbnail: img.imgSrc,
		};
	});

	const renderCustomControls = () => {
		setSlideImgIndex(() => imageSlider?.current?.getCurrentIndex());
	};

	useEffect(() => {
		if (projectImages.length === 0) {
			setOpen(false);
		}
	}, [projectImages]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box sx={style}>
					{/* ///header/// */}
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant={"h6"}>Projet Gallery Images</Typography>

						<IconCmp>
							<CloseIcon onClick={handleClose} />
						</IconCmp>
					</Box>

					{/* ///BODY/// */}
					<Box className={styles.modalBody}>
						<ImageGallery
							items={images}
							showFullscreenButton={false}
							lazyLoad={true}
							autoPlay={true}
							showPlayButton={false}
							showNav={false}
							showThumbnails={true}
							additionalClass={styles.imagesSlider}
							ref={imageSlider}
							renderCustomControls={renderCustomControls}
						></ImageGallery>
						<section className={styles.editImgBox}>
							<IconCmp>
								<RiDeleteBin6Line
									onClick={() => deleteGalleryPic(slideImgIndex)}
									fontSize="30px"
									color="var(--redColor)"
								/>
							</IconCmp>
						</section>
					</Box>

					{/* ///footer// */}
					<Box className={styles.footerModal}>
						<WhiteButtonCmp
							title="cancel"
							onClick={handleClose}
						/>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
