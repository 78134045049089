import React, { useEffect } from "react";
import { ButtonCmp, InputCmp, DropDownCmp, WhiteButtonCmp } from "..";
import { useForm } from "react-hook-form";
import styles from "./ProfileForms.module.css";
import { Typography } from "@mui/material";
import { UpdateProfileAction } from "../../Store/Actions/ProfileAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCitiesAction,
  GetStateAction,
} from "../../Store/Actions/GetActions";
import useAlert from "../../Hooks/useAlert/useAlert";

const ProfileInformationForm = ({ handleCancel, profileDetailsdata }) => {
  const {
    first_name,
    last_name,
    email,
    phone,
    street_address,
    company_name,
    state_id,
    city_id,
    city,
    state,
    zip,
    website,
    year_est,
    company_email,
  } = profileDetailsdata;
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { statesdata } = useSelector((state) => state.StatesReducers);
  const { citiesdata } = useSelector((state) => state.CitiesReducers);

  const { control, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      contactFirstName: first_name || "",
      contactLastName: last_name || "",
      companyName: company_name || "",
      companyStreetAddress: street_address || "",
      companyCity: city || "",
      companyState: state || "",
      companyZip: zip || "",
      yearEst: year_est || "",
      companyPhone: phone || "",
      companyEmailAddress: company_email || "",
      website: website || "",
    },
  });

  ///form state
  const stateState = watch("companyState", false);

  const onSubmit = (obj) => {
    dispatch(UpdateProfileAction(obj, "profileInformation", setAlert));
  };

  useEffect(() => {
    dispatch(GetStateAction());
  }, []);

  useEffect(() => {
    if (stateState) {
      dispatch(GetCitiesAction(stateState.label));
    }
  }, [stateState]);

  return (
    <section className={styles.profile_information}>
      <div className={styles.profile_information_heading}>
        <Typography noWrap variant="h5" fontWeight={"bold"}>
          Profile Information
        </Typography>
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <Typography
            fontSize="16px"
            sx={{ color: "var(--black)", fontWeight: "bold" }}
          >
            Account Email *
          </Typography>
          <Typography
            fontSize="18px"
            sx={{ mt: "10px", color: "var(--primary-color)" }}
          >
            {email}
          </Typography>
        </section>
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <InputCmp
            name="contactFirstName" //imporant
            control={control} //imporant
            label="Contact First Name"
          />
        </section>
        <section>
          <InputCmp
            name="contactLastName" //imporant
            control={control} //imporant
            label="Contact Last Name"
          />
        </section>
        <section>
          <InputCmp
            name="companyName" //imporant
            control={control} //imporant
            label="Company Name"
          />
        </section>
        <section>
          <InputCmp
            name="companyStreetAddress" //imporant
            control={control} //imporant
            label="Company Street Address"
          />
        </section>
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <DropDownCmp
            name="companyState" //imporant
            control={control} //imporant
            label="Company State"
            options={statesdata}
            disableClearable
            freeSolo={false}
          />
        </section>
        <section>
          <DropDownCmp
            name="companyCity" //imporant
            control={control} //imporant
            label="Company City"
            options={citiesdata}
            disableClearable
            freeSolo={false}
          />
        </section>
        <section>
          <InputCmp
            name="companyZip" //imporant
            control={control} //imporant
            label="Company Zip"
          />
        </section>
        <section>
          <InputCmp
            name="yearEst" //imporant
            control={control} //imporant
            label="Year Est."
          />
        </section>
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <InputCmp
            name="companyPhone" //imporant
            control={control} //imporant
            label="Company Phone"
          />
        </section>
        <section>
          <InputCmp
            name="companyEmailAddress" //imporant
            control={control} //imporant
            label="Company Email Address"
          />
        </section>
        <section>
          <InputCmp
            name="website" //imporant
            control={control} //imporant
            label="Website"
          />
        </section>
        <section></section>
      </div>
      <div className={styles.buttonContainer}>
        {/* <section> */}

        <WhiteButtonCmp
          title="Cancel"
          onClick={() => handleCancel("profileInfo")}
        />
        {/* </section>
                <section> */}
        <ButtonCmp
          title="Save"
          style={{ marginLeft: "10px" }}
          onClick={handleSubmit(onSubmit)}
        />
        {/* </section> */}
      </div>
    </section>
  );
};

export default ProfileInformationForm;
