import {
  getMethodCustomHeader,
  getMethodWithoutToken,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const GetCitiesAction = (state) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_CITIES_REQUEST,
      });

      const response = await getMethodWithoutToken(
        `cities?state=${state ? state : "Sindh"}&country=United State`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_CITIES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_CITIES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_CITIES_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetStateAction = (country) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_STATES_REQUEST,
      });
      const response = await getMethodWithoutToken(
        `states?country=${country ? country : "United State"}`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_STATES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_STATES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_STATES_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetServicesAction = (country) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SERVICES_REQUEST,
      });
      const response = await getMethodCustomHeader("services");

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SERVICES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_SERVICES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_SERVICES_FAIL,
        payload: error.response.data,
      });
    }
  };
};
const GetAddedServicesAction = (data) => {
  return (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ADDED_SERVICES,
        payload: data,
      });
    } catch (error) {
      ///
      console.log(data, "errro");
      dispatch({
        type: ActionTypes.GET_ADDED_SERVICES,
        payload: error.data,
      });
    }
  };
};

const SelectedServiceAction = (service) => {
  return (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SELECTED_SERVICES,
        payload: service,
      });
    } catch (error) {
      ///
      console.log(service, "errro");
      dispatch({
        type: ActionTypes.GET_SELECTED_SERVICES,
        payload: error.service,
      });
    }
  };
};

const GetLicensedServicesAction = (country) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_LICENSED_SERVICES_REQUEST,
      });
      const response = await getMethodCustomHeader("services?is_licensed=1");

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_LICENSED_SERVICES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_LICENSED_SERVICES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_LICENSED_SERVICES_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetOtherServicesAction = (country) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_OTHER_SERVICES_REQUEST,
      });
      const response = await getMethodCustomHeader("services?is_licensed=0");

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_OTHER_SERVICES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_OTHER_SERVICES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_OTHER_SERVICES_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetBrandsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BRANDS_REQUEST,
      });

      const response = await getMethodCustomHeader(`brands`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_BRANDS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_BRANDS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_BRANDS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetCategory = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_CATEGORY_REQUEST,
      });

      const response = await getMethodCustomHeader(`categories`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_CATEGORY_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_CATEGORY_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_CATEGORY_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetSymbolAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SYMBOL_REQUEST,
      });

      const response = await getMethodCustomHeader(`symbols`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SYMBOL_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_SYMBOL_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_SYMBOL_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetFinishingsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_FINISHINGS_REQUEST,
      });

      const response = await getMethodCustomHeader(`finishings`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_FINISHINGS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_FINISHINGS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_FINISHINGS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetColorsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_COLORS_REQUEST,
      });

      const response = await getMethodCustomHeader(`colors`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_COLORS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_COLORS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_COLORS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetMaterialsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MATERIALS_REQUEST,
      });

      const response = await getMethodCustomHeader(`materials`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MATERIALS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MATERIALS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MATERIALS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetCoatsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_COATS_REQUEST,
      });

      const response = await getMethodCustomHeader(`coats`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_COATS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_COATS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_COATS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetPrimersAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PRIMERS_REQUEST,
      });

      const response = await getMethodCustomHeader(`primers`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PRIMERS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_PRIMERS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PRIMERS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetPatternsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PATTERNS_REQUEST,
      });

      const response = await getMethodCustomHeader(`patterns`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PATTERNS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_PATTERNS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PATTERNS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetGlassTypesAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_GLASS_TYPES_REQUEST,
      });

      const response = await getMethodCustomHeader(`glass-types`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_GLASS_TYPES_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_GLASS_TYPES_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_GLASS_TYPES_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetDistributorsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_DISTRIBUTORS_REQUEST,
      });

      const response = await getMethodCustomHeader(`distributors`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_DISTRIBUTORS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_DISTRIBUTORS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_DISTRIBUTORS_FAIL,
        payload: error.response.data,
      });
    }
  };
};
const GetSuppliersAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SUPPLIERS_REQUEST,
      });

      const response = await getMethodCustomHeader(`suppliers`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SUPPLIERS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_SUPPLIERS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_SUPPLIERS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

////BuildStudio
const GetServiceSymbols = (serviceSlug) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SERVICE_SYMBOL_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `services-symbols/${serviceSlug}`
      );
      console.log("response GetServiceSymbols", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SERVICE_SYMBOL_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_SERVICE_SYMBOL_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_SERVICE_SYMBOL_FAIL,
        payload: error.response.data,
      });
    }
  };
};

//dashboard
const GetDashboardData = (setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DASHBOARD_DATA_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `user/dashboard?bid=week&projects=week`
      );
      console.log("response GetDashboardData", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.DASHBOARD_DATA_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.DASHBOARD_DATA_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.DASHBOARD_DATA_FAIL,
        payload: error.response.data,
      });
    }
  };
};

export {
  GetCitiesAction,
  GetStateAction,
  GetServicesAction,
  GetAddedServicesAction,
  SelectedServiceAction,
  GetLicensedServicesAction,
  GetOtherServicesAction,
  GetBrandsAction,
  GetColorsAction,
  GetMaterialsAction,
  GetCoatsAction,
  GetPrimersAction,
  GetPatternsAction,
  GetGlassTypesAction,
  GetDistributorsAction,
  GetSuppliersAction,
  GetCategory,
  GetSymbolAction,
  GetServiceSymbols,
  GetDashboardData,
  GetFinishingsAction
};
