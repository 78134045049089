import {
  deleteMethodCustomHeader,
  getMethodCustomHeader,
  postMethodCustomHeader,
  postMethodMultiPartHeader,
} from "../../../Utils/response";
import ActionTypes from "../../Constants";

const GetBuildStudioProject = (perPage, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BUILDSTUDIO_PROJECTS_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `user/projects-bs?per_page=${perPage}&page=${page}`
      );
      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_BUILDSTUDIO_PROJECTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_BUILDSTUDIO_PROJECTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_BUILDSTUDIO_PROJECTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const DeleteBuildStudioProject = (slug, setAlert, navigation) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_REQUEST,
      });

      const response = await deleteMethodCustomHeader(
        `user/projects-bs/${slug}`
      );
      console.log(response, "response delete");
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_SUCCESS,
          payload: response.data,
        });
        if (navigation) {
          navigation("/buildstudio/jobs");
        }
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetBSDeleteProject = (perPage, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_DELETE_BS_PROJECTS_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `user/projects-deleted-bs?per_page=${perPage}&page=${page}`
      );
      console.log(response, "response GetBSDeleteProject");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_DELETE_BS_PROJECTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_DELETE_BS_PROJECTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_DELETE_BS_PROJECTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const RestoreBSDeleteProject = (slug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.RESTORE_DELETE_BS_PROJECTS_REQUEST,
      });

      const response = await postMethodCustomHeader(
        `user/project-bs-restore/${slug}`
      );
      console.log(response, "response GetBSDeleteProject");
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.RESTORE_DELETE_BS_PROJECTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.RESTORE_DELETE_BS_PROJECTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.RESTORE_DELETE_BS_PROJECTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const PermanentBSDeleteProject = (slug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.PARMANENT_DELETE_BS_PROJECTS_REQUEST,
      });

      const response = await postMethodCustomHeader(
        `user/project-bs-permanent-delete/${slug}`
      );
      console.log(response, "response GetBSDeleteProject");
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.PARMANENT_DELETE_BS_PROJECTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.PARMANENT_DELETE_BS_PROJECTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.PARMANENT_DELETE_BS_PROJECTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetSingleBSProject = (slug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SINGLE_BS_PROJECT_REQUEST,
      });

      const response = await getMethodCustomHeader(`user/projects-bs/${slug}`);
      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SINGLE_BS_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_SINGLE_BS_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_SINGLE_BS_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};
const setPdfDesignerData = (data, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SET_PDF_DESIGNER_DATA,
      });
      const { pdfId, projectSlug } = data;
      const objToSend = {
        title: data.title,
        // original_pdf: data.original_pdf,
        pages: data.pages,
        original_pdf_edited: data.original_pdf_edited,
        original_pdf_with_symbols: data.original_pdf_with_symbols,
        pages_data: data.pages_data,
      };

      const formData = new FormData();
      for (let key in objToSend) {
        if (key === "pages_data") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
      for (let i = 0; i < data.symbols.length; i++) {
        formData.append(`symbols[${i}][symbol]`, data.symbols[i].symbol);
        for (var j = 0; j < data.symbols[i].products.length; j++) {
          formData.append(
            `symbols[${i}][products][${j}]`,
            data.symbols[i].products[j].slug
          );
        }
      }
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`, "form data values savePDF");
      }
      // Display the key/value pairs
      const response = await postMethodMultiPartHeader(
        `user/projects-bs/${projectSlug}/project-bs-pdf/${pdfId}`,
        formData
      );

      console.log("response response project create", response);

      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.SET_PDF_DESIGNER_DATA_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.SET_PDF_DESIGNER_DATA_FAIL,
          payload: response.data,
        });
      }
      if (response.data.success) {
        setAlert(response.data?.message, "success");
      } else {
        setAlert(response.data?.message, "error");
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.SET_PDF_DESIGNER_DATA_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetAllProductsAction = (perPage, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ALL_PRODUCTS_REQUEST,
      });

      const response = await getMethodCustomHeader(`products`);
      console.log(response, "response GetAllProductsAction");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_ALL_PRODUCTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_ALL_PRODUCTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_ALL_PRODUCTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const CreateBSProjectAction = (data, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CREATE_BS_PROJECT_REQUEST,
      });
      const { jobTitle, street, city, state, zipCode, jobDesc, pdf, services } =
        data;

      const objToSend = {
        city_id: city?.id,
        state_id: state?.id,
        country_id: 167,
        title: jobTitle,
        street: street,
        zipcode: zipCode,
        description: jobDesc,
      };

      const formData = new FormData();
      for (var key in objToSend) {
        formData.append(key, objToSend[key]);
      }

      for (var i = 0; i < pdf.length; i++) {
        formData.append(`pdf[${i}][pdf]`, pdf[i].file);
        formData.append(`pdf[${i}][title]`, pdf[i].title);
      }

      for (var i = 0; i < services.length; i++) {
        formData.append(`services[${i}]`, services[i].slug);
      }

      const response = await postMethodMultiPartHeader(
        "user/projects-bs",
        formData
      );
      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.CREATE_BS_PROJECT_SUCCESS,
          payload: response.data,
        });
        navigate("/buildstudio/jobs");
      } else {
        setAlert(response?.data.message, "error");
        dispatch({
          type: ActionTypes.CREATE_BS_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response?.data.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.CREATE_BS_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const UploadSinglePDFAction = (obj, setAlert, projectID) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPLOAD_SINGLE_PDF_REQUEST,
      });

      const formData = new FormData();
      formData.append("original_pdf", obj.PDFfile);
      formData.append("title", obj.title);
      formData.append("pages", 1);

      const response = await postMethodMultiPartHeader(
        `user/projects-bs/${projectID}/project-bs-pdf`,
        formData
      );
      console.log(response, "response GetBSDeleteProject");
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.UPLOAD_SINGLE_PDF_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.UPLOAD_SINGLE_PDF_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error, "error");
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.UPLOAD_SINGLE_PDF_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetSingleBSProjectAction = (slug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_BS_PROJECT_REQUEST,
      });

      const response = await getMethodCustomHeader(`user/projects-bs/${slug}`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_BS_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_BS_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error, "error");
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_BS_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EditBSProjectAction = (projectSlug, data, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.EDIT_BS_PROJECT_REQUEST,
      });
      const {
        jobTitle,
        street,
        city,
        state,
        zipCode,
        jobDesc,
        pdf,
        services,
        delete_pdf,
      } = data;

      const objToSend = {
        city_id: city?.id,
        state_id: state?.id,
        country_id: 167,
        title: jobTitle,
        street: street,
        zipcode: zipCode,
        description: jobDesc,
      };

      const formData = new FormData();
      for (var key in objToSend) {
        formData.append(key, objToSend[key]);
      }
      const newPDF = pdf.filter((pdf) => !pdf.id);
      for (var i = 0; i < newPDF.length; i++) {
        formData.append(`pdf[${i}][pdf]`, newPDF[i].file);
        formData.append(`pdf[${i}][title]`, newPDF[i].title);
      }

      for (var i = 0; i < services.length; i++) {
        formData.append(`services[${i}]`, services[i].slug);
      }
      for (var i = 0; i < delete_pdf.length; i++) {
        formData.append(`delete_pdf[${i}]`, delete_pdf[i]);
      }

      const response = await postMethodMultiPartHeader(
        `user/projects-bs/${projectSlug}`,
        formData
      );

      console.log(response, "response EditBSProjectAction");
      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.EDIT_BS_PROJECT_SUCCESS,
          payload: response.data,
        });
        navigate("/buildstudio/jobs");
      } else {
        setAlert(response?.data.message, "error");
        dispatch({
          type: ActionTypes.EDIT_BS_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response?.data.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.EDIT_BS_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

export {
  GetBuildStudioProject,
  DeleteBuildStudioProject,
  GetBSDeleteProject,
  RestoreBSDeleteProject,
  PermanentBSDeleteProject,
  GetSingleBSProject,
  setPdfDesignerData,
  GetAllProductsAction,
  CreateBSProjectAction,
  UploadSinglePDFAction,
  GetSingleBSProjectAction,
  EditBSProjectAction,
};
