import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import { useDispatch } from "react-redux";

import styles from "../BidsListCard/BidsListCard.module.css";
import { IconCmp, RatingCmp, ScrollableTabs } from "..";
import { useNavigate } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import { AppliedIcon, FavoriteBorderOutlinedIcon } from "../../Assets";
import useAlert from "../../Hooks/useAlert/useAlert";
import moment from "moment";

import { MyBidsAddToWishList } from "../../Store/Actions/BuildBidsMyBids";

const WhishListListCard = ({
  color,
  applied,
  path,
  myBidsWishlistedData,
  data
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const [paraLine, setParaLine] = useState(true);
  const [hover, setHover] = useState(false);

  const paraHandler = (e) => {
    e.stopPropagation();
    setParaLine(!paraLine);
  };

  const hoverHandler = (event) => {
    if (event == "enter") {
      setHover(true);
      return;
    }
    setHover(false);
  };

  const handleWishlistClick = (e) => {
    e.stopPropagation();
    dispatch(MyBidsAddToWishList(myBidsWishlistedData.slug, data, "Wishlisted", setAlert));
  };

  const {
    label,
    created_at,
    address,
    zipcode,
    biding_end_date,
    biding_start_date,
    services_count,
    description,
    services,
    user,
    is_in_wishlist,
  } = myBidsWishlistedData;

  return (
    <section
      className={styles.jobCardWrapper}
      onMouseEnter={() => hoverHandler("enter")}
      onMouseLeave={() => hoverHandler("leave")}
      onClick={() => navigate(path)}
    >
      <Box className={styles.cardHeader} >
        <Box >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold" }}
            className={styles.bidName}
          >
            {label && label}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
              Posted -
              {moment(created_at).startOf("data").fromNow(created_at) + " "}
              ago
            </Typography>
            <Box className={styles.ratingBox}>
              <RatingCmp ratingValue={user?.rating} readOnly={true} style={{ fontSize: "20px" }} />
              <Typography fontSize="15px">5.00 of 4 reviews</Typography>
            </Box>
          </Box>
        </Box>
        {applied ? (
          <Box className={styles.whishList}>
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>Applied</Typography>
            </Box>
            <IconCmp style={{ background: "var(--green)" }}>
              {/* <AppliedIcon sx={{ color: "white" }} /> */}
              <img src={AppliedIcon} width="20" alt="" />
            </IconCmp>
          </Box>
        ) : is_in_wishlist ? (
          <Box className={styles.whishList} onClick={handleWishlistClick}>
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>
                Remove from Wish List
              </Typography>
            </Box>
            <IconCmp style={{ background: "var(--green)" }}>
              <FavoriteBorderOutlinedIcon sx={{ color: "white" }} />
            </IconCmp>
          </Box>
        ) : (
          <Box className={styles.whishList} onClick={handleWishlistClick} >
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>Save To Whish List</Typography>
            </Box>
            <IconCmp style={{ background: color }}>
              <FavoriteBorderOutlinedIcon />
            </IconCmp>
          </Box>
        )}
      </Box>
      <section className={styles.headerList} onClick={() => navigate(path)}>
        {/* {headerList.map((list, index) => {
          return ( */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            my: "5px",
          }}
          className={styles.jobDetails}
        >
          <LocationOnOutlinedIcon
            sx={{
              marginRight: " 5px",
              color: "var(--green)",
              ml: "-5px",
              fontSize: "18px",
            }}
          />
          <small>{address + "," + zipcode}</small>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            my: "5px",
            ml: "20px",
          }}
          className={styles.jobDetails}
        >
          <EventAvailableOutlinedIcon
            sx={{
              marginRight: " 5px",
              color: "var(--primary-color)",
              fontSize: "18px",
            }}
          />
          <small>Create Date: {biding_start_date}</small>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            my: "5px",
            ml: "20px",
          }}
          className={styles.jobDetails}
        >
          <EventBusyOutlinedIcon
            sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
          />
          <small>Bid Closing Date: {biding_end_date}</small>
        </Box>
        {/* );
        })} */}
        <Box
          sx={{
            backgroundColor: "var(--light-green)",
            padding: "5px 10px",
            borderRadius: "5px",
            color: "var(--green)",
          }}
          className={styles.cardSeriveWrapper}
        >
          <div>
            <Typography fontSize={"12px"}>
              {services_count} Services required
            </Typography>
          </div>
        </Box>
      </section>
      <section className={styles.cardBody} onClick={() => navigate(path)}>
        <TextTruncate
          line={paraLine && 1}
          element="span"
          truncateText="…"
          text={description}
          style={{ color: "var(--textColor)", fontSize: "medium" }}
        />
      </section>
      <small className={styles.paraMoreBtn} onClick={paraHandler}>
        {paraLine ? "More" : "Less"}
      </small>
      <section className={styles.cardFooter}>
        <div>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            Services:
          </Typography>
        </div>
        <ScrollableTabs bgColor={color} servicesTags={services} hover={hover} />
      </section>
    </section>
  );
};

const headerList = [
  {
    title: "New Orleans, LA, 701115",
    icon: (
      <LocationOnOutlinedIcon
        sx={{
          marginRight: " 5px",
          color: "var(--green)",
          ml: "-5px",
          fontSize: "18px",
        }}
      />
    ),
  },
  {
    title: "Create Date: 6/3/2021",
    icon: (
      <EventAvailableOutlinedIcon
        sx={{
          marginRight: " 5px",
          color: "var(--primary-color)",
          fontSize: "18px",
        }}
      />
    ),
  },
  {
    title: "Bid Closing Date: 12/25/2021",
    icon: (
      <EventBusyOutlinedIcon
        sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
      />
    ),
  },
];

export default WhishListListCard;
