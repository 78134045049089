import ActionTypes from "../Constants";

const INITIALSTATE = {
  //search connection
  searchConnectionLoading: false,
  searchConnectiondata: "",
  searchConnectionError: "",

  //create connection request
  createConnectionLoading: false,
  createConnectiondata: false,
  createConnectionError: "",

  //get pending requests
  getPendingRequestLoading: false,
  getPendingRequestdata: undefined,
  getPendingRequestError: "",

  //get received requests
  getReceivedRequestLoading: false,
  getReceivedRequestdata: undefined,
  getReceivedRequestError: "",

  // recieved connection request actions
  recievedConnectionRequestLoading: false,
  isSuccess: false,
  recievedConnectionRequestError: "",
  recievedConnectionRequestMsg: "",

  // remove connection request actions
  removeConnectionRequestLoading: false,
  isRemoveSuccess: false,
  removeConnectionRequestError: "",
  removeConnectionRequestMsg: "",

  //get pro connetion
  getProConnectionLoading: false,
  getProConnectiondata: [],
  getProConnectionError: "",

  // remove connection / freind
  removeConnectionLoading: false,
  isRemoveConnectionSuccess: false,
  removeConnectionError: "",
  removeConnectionMsg: "",
};

const SearchConnectionReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_CONNECTION_REQUEST:
      return {
        ...state,
        searchConnectionLoading: true,
      };
    case ActionTypes.SEARCH_CONNECTION_SUCCESS:
      return {
        ...state,
        searchConnectionLoading: false,
        searchConnectiondata: action.payload.data,
        searchConnectionError: "",
      };
    case ActionTypes.SEARCH_CONNECTION_FAIL:
      return {
        ...state,
        searchConnectionLoading: false,
        searchConnectiondata: [],
        searchConnectionError: action.payload.message,
      };
    default:
      return state;
  }
};

const CreateConnectionReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_CONNECTION_REQUEST:
      return {
        ...state,
        createConnectionLoading: true,
      };
    case ActionTypes.CREATE_CONNECTION_SUCCESS:
      return {
        ...state,
        createConnectionLoading: false,
        createConnectiondata: !state.createConnectiondata,
        createConnectionError: "",
      };
    case ActionTypes.CREATE_CONNECTION_FAIL:
      return {
        ...state,
        createConnectionLoading: false,
        // createConnectiondata: action.payload.success,
        createConnectionError: action.payload.message,
      };
    case ActionTypes.EMPTY_CREATE_CONNECTION_FAIL:
      return {
        ...state,
        createConnectionLoading: false,
        createConnectiondata: undefined,
        createConnectionError: "",
      };
    default:
      return state;
  }
};

const getPendingRequestsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PENDING_REQUEST_REQUEST:
      return {
        ...state,
        getPendingRequestLoading: true,
      };
    case ActionTypes.GET_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        getPendingRequestLoading: false,
        getPendingRequestdata: action.payload.data,
        getPendingRequestError: "",
      };
    case ActionTypes.GET_PENDING_REQUEST_FAIL:
      return {
        ...state,
        getPendingRequestLoading: false,
        getPendingRequestdata: [],
        getPendingRequestError: action.payload.message,
      };
    default:
      return state;
  }
};

const getReceivedRequestsReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_RECEIVED_REQUEST_REQUEST:
      return {
        ...state,
        getReceivedRequestLoading: true,
      };
    case ActionTypes.GET_RECEIVED_REQUEST_SUCCESS:
      return {
        ...state,
        getReceivedRequestLoading: false,
        getReceivedRequestdata: action.payload.data,
        getReceivedRequestError: "",
      };
    case ActionTypes.GET_RECEIVED_REQUEST_FAIL:
      return {
        ...state,
        getReceivedRequestLoading: false,
        getReceivedRequestdata: [],
        getReceivedRequestError: action.payload.message,
      };
    default:
      return state;
  }
};

const RecievedConnectionRequestReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.RECIEVED_CONNECTION_REQUEST_REQUEST:
      return {
        ...state,
        recievedConnectionRequestLoading: true,
      };
    case ActionTypes.RECIEVED_CONNECTION_REQUEST_SUCCESS:
      return {
        ...state,
        recievedConnectionRequestLoading: false,
        isSuccess: action.payload.success,
        recievedConnectionRequestError: "",
        recievedConnectionRequestMsg: action.payload.message,
      };
    case ActionTypes.RECIEVED_CONNECTION_REQUEST_FAIL:
      return {
        ...state,
        recievedConnectionRequestLoading: false,
        isSuccess: false,
        recievedConnectionRequestError: action.payload.message,
        recievedConnectionRequestMsg: "",
      };
    case ActionTypes.EMPTY_RECIEVED_CONNECTION_REQUEST_STATE:
      return {
        ...state,
        recievedConnectionRequestLoading: false,
        isSuccess: false,
        recievedConnectionRequestError: "",
        recievedConnectionRequestMsg: "",
      };
    default:
      return state;
  }
};

const RemoveConnectionRequestReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_CONNECTION_REQUEST_REQUEST:
      return {
        ...state,
        removeConnectionRequestLoading: true,
      };
    case ActionTypes.REMOVE_CONNECTION_REQUEST_SUCCESS:
      return {
        ...state,
        removeConnectionRequestLoading: false,
        isRemoveSuccess: action.payload.success,
        removeConnectionRequestError: "",
        removeConnectionRequestMsg: action.payload.message,
      };
    case ActionTypes.REMOVE_CONNECTION_REQUEST_FAIL:
      return {
        ...state,
        removeConnectionRequestLoading: false,
        isRemoveSuccess: false,
        removeConnectionRequestError: action.payload.message,
        removeConnectionRequestMsg: "",
      };
    case ActionTypes.EMPTY_REMOVE_CONNECTION_REQUEST_STATE:
      return {
        ...state,
        removeConnectionRequestLoading: false,
        isRemoveSuccess: false,
        removeConnectionRequestError: "",
        removeConnectionRequestMsg: "",
      };
    default:
      return state;
  }
};

const GetProConnectionReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRO_CONNECTION_REQUEST:
      return {
        ...state,
        getProConnectionLoading: true,
      };
    case ActionTypes.GET_PRO_CONNECTION_SUCCESS:
      return {
        ...state,
        getProConnectionLoading: false,
        getProConnectiondata: action.payload.data,
        getProConnectionError: "",
      };
    case ActionTypes.GET_PRO_CONNECTION_FAIL:
      return {
        ...state,
        getProConnectionLoading: false,
        getProConnectiondata: [],
        getProConnectionError: action.payload.message,
      };
    default:
      return state;
  }
};

const RemoveConnectionReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_CONNECTION_REQUEST:
      return {
        ...state,
        removeConnectionLoading: true,
      };
    case ActionTypes.REMOVE_CONNECTION_SUCCESS:
      return {
        ...state,
        removeConnectionLoading: false,
        isRemoveConnectionSuccess: action.payload.success,
        removeConnectionError: "",
        removeConnectionMsg: action.payload.message,
      };
    case ActionTypes.REMOVE_CONNECTION_FAIL:
      return {
        ...state,
        removeConnectionLoading: false,
        isRemoveConnectionSuccess: false,
        removeConnectionError: action.payload.message,
        removeConnectionMsg: "",
      };
    case ActionTypes.EMPTY_REMOVE_CONNECTION_STATE:
      return {
        ...state,
        removeConnectionLoading: false,
        isRemoveConnectionSuccess: false,
        removeConnectionError: "",
        removeConnectionMsg: "",
      };
    default:
      return state;
  }
};

export {
  SearchConnectionReducer,
  CreateConnectionReducer,
  getPendingRequestsReducer,
  getReceivedRequestsReducer,
  RecievedConnectionRequestReducer,
  RemoveConnectionRequestReducer,
  GetProConnectionReducer,
  RemoveConnectionReducer,
};
