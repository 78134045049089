import { Rating } from "@mui/material";
import React from "react";

import styles from "./RatingCmp.module.css";

const RatingCmp = ({ readOnly, style, color, ratingValue, onChange }) => {
  return (
    <>
      <Rating
        className={styles.ratingBar}
        name="half-rating"
        // defaultValue={}
        value={ratingValue ? ratingValue : 0}
        precision={0.5}
        readOnly={readOnly && true}
        sx={{
          span: {
            color: 'yellow',
          },
          ...style,
        }}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
      />
    </>
  );
};

export default RatingCmp;
