// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

// function useGetUserRole() {
//   const param = useParams();
//   const [userRole, setUserRole] = useState();
//   useEffect(() => {
//     const role = param["*"].slice("/");
//     console.log(role, "role");
//     setUserRole(role);
//   }, [param]);
//   return userRole;
// }

// export default useGetUserRole;
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserRoleAction } from "../Store/Actions/AuthActions";
import { useDispatch } from "react-redux";

function useGetUserRole() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(location.pathname.split("/")[1]);
  useEffect(() => {
    const role = localStorage.getItem("userRole");
    // setUserRole(role);
    setUserRole(location.pathname.split("/")[1]);
    // dispatch(UserRoleAction(location.pathname.split("/")[1]));
  }, [location.pathname.split("/")[1]]);
  return userRole;
}

export default useGetUserRole;
