import { Box, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../website.module.css";
import logo from "../../../Assets/websiteImages/logo2.png";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
];

const Footer = () => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
      }}
    >
      {/* <Grid
        item
        lg={2}
        columns={12}
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index-customer-support.html" className="logo">
          <img src={logo} alt="brand" />
        </a>
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={6}
        className={styles.Footer_list}
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className={styles.footer_title}>Products</h5>
        <ul>
          <li>
            <Link to="/about-cs">Take the tour</Link>
          </li>
          <li>
            <Link to="/">Live chat</Link>
          </li>
          <li>
            <Link to="/solution-management">Self-service</Link>
          </li>
          <li>
            <Link to="/blog-details">Social</Link>
          </li>
          <li>
            <Link to="/blog-details">Mobile</Link>
          </li>
          <li>
            <Link to="/blog-details">Collaboration</Link>
          </li>
          <li>
            <Link to="/blog-details">build links Reviews</Link>
          </li>
        </ul>
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={6}
        className={styles.Footer_list}
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className={styles.footer_title}>Services</h5>
        <ul>
          <li>
            <Link to="/blog-details">Web Design</Link>
          </li>
          <li>
            <Link to="/blog-details">Development</Link>
          </li>
          <li>
            <Link to="/blog-details">Wordpress</Link>
          </li>
          <li>
            <Link to="/blog-details">Online Marketing</Link>
          </li>
          <li>
            <Link to="/blog-details">Content</Link>
          </li>
        </ul>
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={6}
        className={styles.Footer_list}
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className={styles.footer_title}>About us</h5>
        <ul>
          <li>
            <Link to="/about-cs">About us</Link>
          </li>
          <li>
            <Link to="/faq-details">Faq Details</Link>
          </li>
          <li>
            <Link to="/team-6">Team</Link>
          </li>
          <li>
            <Link to="/pricing-cs">Plan & Pricing</Link>
          </li>
          <li>
            <Link to="/blog-5">News</Link>
          </li>
        </ul>
      </Grid>
      <Grid
        item
        lg={2}
        md={3}
        sm={6}
        className={styles.Footer_list}
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className={styles.footer_title}>Address</h5>
        <ul className="info">
          <li>
            <a href="mailto:ib-themes21@gmail.com">buildlinkssupportinc@gmail.com</a>
          </li>
          <li>
            <a href="Tel: 7614123224" className={styles.footer_mobilenum}>
              +761 412 3224
            </a>
          </li>
        </ul>
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </Grid> */}
    </Grid>
  );
};

export default Footer;
