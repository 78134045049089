import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function LoaderCmp({text=""}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "93vh",
      }}
      style={{display:"flex", flexDirection:"column"}}
    >
        <CircularProgress />
        {text && <span>{text}</span>}
    </Box>
  );
}
