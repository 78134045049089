import React, { useState, useEffect } from "react";
import { AccountAlert, CloseIcon } from "../../Assets";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCmp, DropDownCmp, WhiteButtonCmp } from "..";
import {
  HorizontalScrollContainer,
  HorizontalScrollItem,
} from "react-simple-horizontal-scroller";
import { useForm } from "react-hook-form";
import styles from "./ProfileForms.module.css";
import { Box, Typography, Grid } from "@mui/material";
import {
  GetLicensedServicesAction,
  GetOtherServicesAction,
} from "../../Store/Actions/GetActions";
import { UpdateProfileAction } from "../../Store/Actions/ProfileAction";
import useAlert from "../../Hooks/useAlert/useAlert";

const ServicesForm = ({ handleCancel, profileDetailsdata }) => {
  const { services_licensed, services_other } = profileDetailsdata;
  const [servicesArr, setServicesArr] = useState(
    services_licensed?.map((i, index) => i.service) || []
  );
  const [otherServicesArr, setOtherServicesArr] = useState(
    services_other?.map((i, index) => i.service) || []
  );

  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  // const { servicesdata } = useSelector((state) => state.ServicesReducer);
  const { licensedServicesdata } = useSelector(
    (state) => state.LicensedServicesReducer
  );
  const { otherServicesdata } = useSelector(
    (state) => state.OtherServicesReducer
  );

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      licensedService: "",
      otherService: "",
    },
  });

  const licensedServiceState = watch("licensedService");
  const otherServiceState = watch("otherService");

  useEffect(() => {
    if (licensedServiceState) {
      const alreadyExist = servicesArr.find(
        (i) => i.slug === licensedServiceState.slug
      );
      if (alreadyExist) {
        setAlert("This service already exist", "error");
        setValue("licensedService", "");
        return;
      }
      setServicesArr([...servicesArr, licensedServiceState]);
      setValue("licensedService", "");
    }
  }, [licensedServiceState]);

  useEffect(() => {
    if (otherServiceState) {
      const alreadyExist = otherServicesArr.find(
        (i) => i.slug === otherServiceState.slug
      );
      if (alreadyExist) {
        setAlert("This service already exist", "error");
        setValue("otherService", "");
        return;
      }
      setOtherServicesArr([...otherServicesArr, otherServiceState]);
      setValue("otherService", "");
    }
  }, [otherServiceState]);

  useEffect(() => {
    dispatch(GetLicensedServicesAction());
    dispatch(GetOtherServicesAction());
  }, []);

  const onSubmit = (obj) => {
    const objToSend = {
      servicesArr,
      otherServicesArr,
    };
    console.log(objToSend);
    dispatch(UpdateProfileAction(objToSend, "services", setAlert));
  };

  const removeService = (ind, whichService) => {
    if (whichService === "licensedService") {
      servicesArr.splice(ind, 1);
      setServicesArr([...servicesArr]);
    } else {
      otherServicesArr.splice(ind, 1);
      setOtherServicesArr([...otherServicesArr]);
    }
  };
  return (
    <>
      <section className={styles.profile_services}>
        <div className={styles.profile_information_heading}>
          <Typography noWrap variant="h5" fontWeight={"bold"}>
            Services Provided
          </Typography>
          {/* <IconCmp>
                        <BiEdit />
                    </IconCmp> */}
        </div>

        <section className={styles.servicesLicensed}>
          <Typography noWrap mt={"40px"} variant="h6" fontWeight={"600"}>
            Licensed Service
          </Typography>
          <Box sx={{ my: "20px" }}>
            <Grid container>
              <Grid item xs={6}>
                <DropDownCmp
                  name="licensedService" //imporant
                  control={control} //imporant
                  label="Select Services"
                  options={licensedServicesdata && licensedServicesdata}
                  disableClearable
                  freeSolo={false}
                />
              </Grid>
            </Grid>
          </Box>
          {/* ///tags// */}
          <HorizontalScrollContainer>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {servicesArr.length > 0 &&
                servicesArr.map((service, ind) => {
                  return (
                    <HorizontalScrollItem
                      key={ind}
                      className={styles.scrollTabsBar}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          alt="serviceLogo"
                          src={AccountAlert}
                          style={{ marginBottom: "2px" }}
                        />
                        {service.label}
                      </Box>
                      <Box>
                        <CloseIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            mt: "5px",
                          }}
                          onClick={() => removeService(ind, "licensedService")}
                        />
                      </Box>
                    </HorizontalScrollItem>
                  );
                })}
            </Box>
          </HorizontalScrollContainer>
        </section>

        {/* ////other services// */}
        <section className={styles.servicesLicensed}>
          <Typography noWrap variant="h6" fontWeight={"600"}>
            Other Service
          </Typography>
          <Box sx={{ my: "20px" }}>
            <Grid container>
              <Grid item xs={6}>
                <DropDownCmp
                  name="otherService" //imporant
                  control={control} //imporant
                  label="Select Services"
                  options={otherServicesdata && otherServicesdata}
                  disableClearable
                  freeSolo={false}
                />
              </Grid>
            </Grid>
          </Box>
          {/* ///tags// */}
          <HorizontalScrollContainer>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {otherServicesArr.length > 0 &&
                otherServicesArr.map((service, ind) => {
                  return (
                    <HorizontalScrollItem
                      key={ind}
                      className={styles.scrollTabsBar}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          alt="serviceLogo"
                          src={AccountAlert}
                          style={{ marginBottom: "2px" }}
                        />
                        {service.label}
                      </Box>
                      <Box>
                        <CloseIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            mt: "5px",
                          }}
                          onClick={() => removeService(ind, "otherService")}
                        />
                      </Box>
                    </HorizontalScrollItem>
                  );
                })}
            </Box>
          </HorizontalScrollContainer>
        </section>
      </section>
      <div
        className={styles.buttonContainer}
        style={{
          paddingRight: "40px",
        }}
      >
        {/* <section> */}

        <WhiteButtonCmp
          title="Cancel"
          onClick={() => handleCancel("servicesInfo")}
        />
        {/* </section>
                    <section> */}
        <ButtonCmp
          title="Save"
          style={{ marginLeft: "10px" }}
          onClick={handleSubmit(onSubmit)}
        />
        {/* </section> */}
      </div>
    </>
  );
};

export default ServicesForm;
