import { Grid, Typography, Box, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";

import {
  BodyLayout,
  ManufacturerProductCard,
  WhiteButtonCmp,
  LoaderCmp,
  IconCmp,
  BackDropCmp,
  ButtonCmp,
} from "../../../Components";
// import EditProductForm from "./EditProductForm";
import Icon from "../../../Assets/Images/star hammer.png";
import styles from "./ViewProductDetail.module.css";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetailsAction } from "../../../Store/Actions/ManufacturerActions";
import useAlert from "../../../Hooks/useAlert/useAlert";
import { BiEdit } from "../../../Assets/index";
import { GetAddedServicesAction } from "../../../Store/Actions/GetActions";

const images1 = [
  {
    original:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/Lamborghini/Aventador/6721/Lamborghini-Aventador-SVJ/1621849426405/front-left-side-47.jpg",
    originalClass: styles.originalImg,

    thumbnail:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/Lamborghini/Aventador/6721/Lamborghini-Aventador-SVJ/1621849426405/front-left-side-47.jpg",
  },
  {
    original:
      "https://mclaren.scene7.com/is/image/mclaren/McLarenArtura-16:crop-16x9?wid=1980&hei=1114",
    originalClass: styles.originalImg,

    thumbnail:
      "https://mclaren.scene7.com/is/image/mclaren/McLarenArtura-16:crop-16x9?wid=1980&hei=1114",
  },
];

const ProductDetails = ({ setOpen }) => {
  // const { productSlug } = useParams();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { addedServices } = useSelector((state) => state.AddedServicesReducer);
  const { selectedService } = useSelector(
    (state) => state.SelectedServiceReducer
  );
  const { productDetailsdata, productDetailsLoading } = useSelector(
    (state) => state.ProductDetailsReducer
  );
  const { updateProductLoading, updateProductdata } = useSelector(
    (state) => state.UpdateProductReducer
  );

  const [productDetails, setProductDetails] = useState(
    productDetailsdata?.product_detail || ""
  );
  const [showLess, setShowLess] = useState(false);
  const [editProduct, setEditProduct] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const productSlug = searchParams.get("product"); // "testCode"

  useEffect(() => {
    dispatch(ProductDetailsAction(productSlug, setAlert));
  }, [productSlug]);

  useEffect(() => {
    if (productDetailsdata) {
      setProductDetails(productDetailsdata?.product_detail);
    }
  }, [productDetailsdata]);

  //   useEffect(() => {
  //     if (updateProductdata) {
  //       setProductDetails(updateProductdata);
  //       setEditProduct(false);
  //     }
  //   }, [updateProductdata]);

  const selectProductHandler = () => {
    const temAddedServices = [...addedServices];
    const selectedSer = { ...selectedService };
    if (!temAddedServices.length || !selectedSer) return;
    const productSlug = productDetails.sku || "";
    const addedSerId = temAddedServices.findIndex(
      (s) => s.id === selectedSer.custom?.service_id
    );
    if (addedSerId === -1) return;
    temAddedServices[addedSerId].addedServices = temAddedServices[
      addedSerId
    ].addedServices.map((ser) => {
      if (ser.ref_id === selectedSer.ref_id) {
        if (!ser.products.find((s) => s.slug === ser.slug)) {
          ser.products.push(productDetails);
        }
      }
      return ser;
    });

    dispatch(GetAddedServicesAction([...temAddedServices]));
    setOpen(false);
  };

  const {
    title,
    brand,
    sku,
    description,
    model_no,
    colors,
    type,
    material,
    finish,
    width,
    height,
    length,
    distributor,
    supplier,
    rooms,
    quantity,
    style,
    sdl,
    coat_one,
    coat_two,
    primer,
    pattern,
    joint,
    glass_type,
    installer,
    other,
    images,
    model_name,
    category,
    symbols,
    depth,
    item,
    item_no,
    do_not_include,
    custom,
  } = productDetails || {};

  const imageUrl = "https://buildlink.designfinix.com/uploads/products/";

  const temp =
    images?.length > 0 &&
    images
      .filter((item) => item.is_thumbnail != 1)
      .map((item) => {
        return {
          original: imageUrl + item.image,
          thumbnail: imageUrl + item.image,
          originalClass: styles.originalImg,
        };
      });

  return productDetailsLoading ? (
    <LoaderCmp />
  ) : (
    // productDetailsdata && productDetails && (
    <>
      <section className={styles.productDetail_wrapper}>
        <>
          <div className={styles.product_wrapper}>
            <Grid container>
              <Grid item lg={4} sx={{ width: "100%" }}>
                <ImageGallery
                  items={temp || images1}
                  showFullscreenButton={false}
                  lazyLoad={true}
                  autoPlay={true}
                  showPlayButton={false}
                  showNav={false}
                  showThumbnails={true}
                  additionalClass={styles.imagesSlider}
                />
              </Grid>

              <Grid item lg={8}>
                <section className={styles.details_wrapper}>
                  <div className={styles.details_productName}>
                    <section>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {title ? title : "GenAir Elite"}
                        </Typography>
                        <div onClick={() => setEditProduct(!editProduct)}>
                          <ButtonCmp
                            title="Select Product"
                            onClick={() => selectProductHandler()}
                          />
                        </div>
                      </div>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Box sx={{ gap: "10px" }}>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                              mt: "3px",
                            }}
                          >
                            SKU
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: "bold" }}
                          >
                            {sku ? sku : "00708"}
                          </Typography>
                        </Box>
                      </Box>
                    </section>
                    <Box sx={{ mt: "20px" }}>
                      <Typography sx={{ fontSize: "14px" }}>
                        {description
                          ? description
                          : "lorem ipsum dolor sit amet "}
                      </Typography>
                    </Box>
                  </div>

                  {/* ////Category  */}
                  <section className={styles.productCategory}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Category
                    </Typography>
                    <Grid container rowSpacing={3} sx={{ mt: 1 }}>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Brand
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {brand?.label ? brand.label : "GenAir"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Model Number
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {model_no ? model_no : "43434-20e"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Color
                          </Typography>

                          <div style={{ display: "flex" }}>
                            {colors?.length > 0 ? (
                              colors.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    height: "23px",
                                    width: "23px",
                                    borderRadius: "50%",
                                    backgroundColor: item.color.code,
                                    marginRight: "2px",
                                  }}
                                ></div>
                              ))
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                this is color
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Type
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {type ? type : "this is type"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Material
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {material?.label ? material.label : "Wood"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Finish
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {finish ? finish : "Matte"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Width
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {width ? width : "20"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Height
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {height ? height : "20"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </section>
                  <section className={styles.productCategory}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Category
                    </Typography>
                    <Grid container rowSpacing={3} sx={{ mt: 1 }}>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Length
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {length ? length : "20"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Distributor
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {distributor?.label ? distributor.label : "GenAir"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Supplier
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {supplier?.label ? supplier.label : "GenAir"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Room
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {rooms ? rooms : "Living Room"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Quantity
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {quantity ? quantity : "20"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            Style
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {style ? style : "Modern"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            SDL
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {sdl ? sdl : "20ds"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={3}>
                        <div>
                          <Typography
                            sx={{
                              color: "var(--textColor)",
                              fontSize: "14px",
                            }}
                          >
                            First Coat
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {coat_one?.label ? coat_one.label : "oliper"}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </section>
                  {showLess && (
                    <section className={styles.productCategory}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Category
                      </Typography>
                      <Grid container rowSpacing={3} sx={{ mt: 1 }}>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Second Coat
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {coat_two?.label ? coat_two.label : "oliper"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Primer
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {primer?.label ? primer.label : "oliper"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Pattern
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {pattern?.label ? pattern.label : "oliper"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Joint
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {joint ? joint : "joint??"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Glass Type
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {glass_type?.label ? glass_type?.label : "oliper"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Installer
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {installer ? installer : "this is installer"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Model Name
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {model_name ? model_name : "Model Name"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Category
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {category?.label ? category?.label : "Category"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Symbols
                            </Typography>

                            <div style={{ display: "flex" }}>
                              {symbols &&
                                symbols?.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    <img
                                      loading="lazy"
                                      width="23"
                                      height="23"
                                      srcSet={Icon}
                                      src={`${item.symbol.ImgURL}${item.symbol.svg_image}`}
                                      alt=""
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Depth
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {depth ? depth : "depth"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Item
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {item ? item : "item"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Item No
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {item_no ? item_no : "item no "}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Do not Include
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {do_not_include
                                ? do_not_include
                                : "do not include"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Custom
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {custom ? custom : "custom"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={3}>
                          <div>
                            <Typography
                              sx={{
                                color: "var(--textColor)",
                                fontSize: "14px",
                              }}
                            >
                              Other
                            </Typography>
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {other ? other : "other"}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </section>
                  )}

                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <WhiteButtonCmp
                      title={showLess ? "Show Less" : "Show More"}
                      onClick={() => setShowLess(!showLess)}
                    />
                  </Box>
                </section>
              </Grid>
            </Grid>
          </div>
        </>
      </section>
      <BackDropCmp setOpen={updateProductLoading} />
    </>
    // )
  );
};

export default ProductDetails;
