import React, { useEffect } from "react";
import styles from "./ProfileForms.module.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import {
    LanguageIcon,
    FacebookOutlinedIcon,
    TwitterIcon,
    LinkedInIcon,
} from "../../Assets";
import { ButtonCmp, InputCmp, WhiteButtonCmp } from "../../Components";
import { UpdateProfileAction } from "../../Store/Actions/ProfileAction";
import useAlert from "../../Hooks/useAlert/useAlert";

const SocialAccountsForm = ({ handleCancel, profileDetailsdata }) => {
    const dispatch = useDispatch();
    const { setAlert } = useAlert();
    const {
        website,
        facebook_link,
        twitter_link,
        linkedin_link,
    } = profileDetailsdata;

    const { control, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues: {
            website: website || "",
            facebook: facebook_link || "",
            twitter: twitter_link || "",
            linkedin: linkedin_link || "",
        },
    });


    const onSubmit = (obj) => {
        console.log(obj, "obj");
        dispatch(UpdateProfileAction(obj, "socialMedia", setAlert))
    };

    return (
        <section className={styles.profileInfo}
            style={{ borderBottom: "2px solid var(--borderColor)" }}
        >
            <div
                style={{ padding: "0 20px", marginTop: "20px" }}
            >
                <Typography noWrap variant="h5" fontWeight={"bold"}>
                    Social media
                </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <section className={styles.socialLink_wrapper}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <div>
                            <LanguageIcon
                                style={{ color: "var(--lightIcon)", fontSize: "34px" }}
                            />
                        </div>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputCmp
                                    name="website" //imporant
                                    control={control} //imporant
                                    label="Website"
                                    type={"url"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <div>
                            <FacebookOutlinedIcon
                                style={{ color: "var(--lightIcon)", fontSize: "34px" }}
                            />
                        </div>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputCmp
                                    name="facebook" //imporant
                                    control={control} //imporant
                                    label="Facebook"
                                    type={"url"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <div>
                            <TwitterIcon
                                style={{ color: "var(--lightIcon)", fontSize: "34px" }}
                            />
                        </div>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputCmp
                                    name="twitter" //imporant
                                    control={control} //imporant
                                    label="Twitter"
                                    type={"url"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                        <div>
                            <LinkedInIcon
                                style={{ color: "var(--lightIcon)", fontSize: "34px" }}
                            />
                        </div>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputCmp
                                    name="linkedin" //imporant
                                    control={control} //imporant
                                    label="Linkedin"
                                    type={"url"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </section>

                {/* ////btn */}
                <div className={styles.buttonContainer}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    {/* <section> */}

                    <WhiteButtonCmp
                        title="Cancel"
                        onClick={() => handleCancel('socialAccountsInfo')}
                        style={{ minWidth: "100px", padding: "11.5px 20px ", }}
                    />
                    {/* </section>
                    <section> */}
                    <ButtonCmp
                        title="Save"
                        style={{ marginRight: "20px", marginLeft: "10px", minWidth: "100px" }}
                        onClick={handleSubmit(onSubmit)}
                    />
                    {/* </section> */}

                </div>
            </form>
        </section>
    );
};

export default SocialAccountsForm;
