import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from "../../Assets/Images/BuildLinkCompleteLogo.png";
import buildBid from "../../Assets/Images/Build-BID2.png";
import buildStudio from "../../Assets/Images/BuildStudiologo-gold.png";
import grid from "../../Assets/Images/Group194.png";
import constructionImg from "../../Assets/Images/Group213.png";
import { useForm } from "react-hook-form";
import { AlertCmp, BackDropCmp, DropDownCmp, InputCmp } from "../../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validation } from "../../Config";
import { useDispatch, useSelector } from "react-redux";
import { SignUpAction } from "../../Store/Actions/AuthActions";
import { useEffect } from "react";
import { useState } from "react";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      userType: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validation.signupValidation),
  });

  const sigupHandler = (signupData) => {
    dispatch(SignUpAction(signupData));
  };

  const {
    userSignupLoading,
    userSignupdata,
    userSignupMessage,
    userSignupError,
  } = useSelector((state) => state.SignupReducers);

  useEffect(() => {
    if (userSignupError) {
      setAlertStatus(userSignupError);
      setOpen(true);
    }
  }, [userSignupError]);

  useEffect(() => {
    if (userSignupMessage) {
      setAlertStatus(userSignupMessage);
      setOpen(true);
      navigate("/signupconfirmation");
    }
  }, [userSignupMessage]);

  return (
    <div className={styles.signUpPage}>
      <AlertCmp
        setOpen={setOpen}
        text={alertStatus}
        error={userSignupError && true}
        open={open}
      />

      <Paper className={styles.paper}>
        <div className={styles.signUpArea}>
          <img className={styles.logo} src={logo} alt="logo" />

          <Typography className={styles.typo1}>Sign up</Typography>
          <div className={styles.typo2}>
            <Typography>Already have an account?</Typography>
            <Link className={styles.typo2Link} to="/login">
              Sign in
            </Link>
          </div>

          <form
            name="loginForm"
            onSubmit={handleSubmit(sigupHandler)}
            className={styles.form}
          >
            <div className={styles.textFields}>
              <InputCmp
                label="First Name"
                autoFocus
                name="firstName"
                control={control}
              />
              <InputCmp
                name="lastName"
                control={control}
                label="Last Name"
                variant="outlined"
              />
            </div>
            <div className={styles.textFields}>
              <InputCmp
                name="email"
                control={control}
                label="Your Email"
                type="email"
                variant="outlined"
              />
              <DropDownCmp
                name="userType"
                control={control}
                label="User Type"
                options={["Pro", "Manufacturer", "Supplier" ,"Home Owner / Client"]}
                disableClearable
                freeSolo={false}
              />
            </div>
            <div className={styles.textFields}>
              <InputCmp
                name="password"
                control={control}
                label="Password"
                type="password"
              />
              <InputCmp
                name="confirmPassword"
                control={control}
                label="Re-Password"
                type="password"
              />
            </div>

            <Button
              variant="contained"
              color="secondary"
              className={styles.button}
              aria-label="Sign up"
              size="large"
              type="submit"
            >
              Sign UP
            </Button>

            <div className={styles.belowSignUp}>
              <div className={styles.belowSignUpBorder} />
              <Typography
                className={styles.belowSignUpTypo}
                color="text.secondary"
              >
                Powered By
              </Typography>
              <div className={styles.belowSignUpBorder} />
            </div>

            <div className={styles.socialMedia}>
              <img className={styles.logo} src={buildBid} alt="logo" />
              <img className={styles.buildLogos} src={buildStudio} alt="logo" />
            </div>
          </form>
        </div>
      </Paper>

      <Box
        className={styles.secondBox}
        sx={{ backgroundColor: "primary.main" }}
      >
        <img src={grid} alt="" className={styles.grid} />
        <img src={constructionImg} alt="" className={styles.constructionImg} />
        {/* <svg
          className={styles.svg}
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          ></Box>
        </svg> */}

        <div className={styles.textArea}>
          <div className={styles.textArea1}>
            <div>Welcome to</div>
            <div>Construction community</div>
          </div>
          <div className={styles.paragraph}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At
          </div>
          <div className={styles.paragraph2}>
            More than 11k people build their floor plans, and more then 500
            successful project.
          </div>
        </div>
      </Box>
      <BackDropCmp setOpen={userSignupLoading} />
    </div>
  );
}
