import ActionTypes from "../Constants";

const INITIALSTATE = {
  //states
  statesLoading: false,
  statesdata: [],
  statesError: "",

  //citites
  citiesLoading: false,
  citiesdata: [],
  citiesError: "",

  ///Services//
  servicesLoading: false,
  servicesdata: [],
  servicesError: "",

  ///Added Services//
  addedServicesLoading: false,
  addedServices: [],
  addedServicesError: "",

  ///selected Services//
  selectedService: null,

  ///Licensed Services//
  licensedServicesLoading: false,
  licensedServicesdata: [],
  licensedServicesError: "",

  ///Other Services//
  otherServicesLoading: false,
  otherServicesdata: [],
  otherServicesError: "",

  //brands
  brandsLoading: false,
  brandsdata: [],
  brandsError: "",

  //colors
  colorsLoading: false,
  colorsdata: [],
  colorsError: "",

  //materials
  materialsLoading: false,
  materialsdata: [],
  materialsError: "",

  //coats
  coatsLoading: false,
  coatsdata: [],
  coatsError: "",

  //primers
  primersLoading: false,
  primersdata: [],
  primersError: "",

  //patterns
  patternsLoading: false,
  patternsdata: [],
  patternsError: "",

  //glassTypes
  glassTypesLoading: false,
  glassTypesdata: [],
  glassTypesError: "",

  //distributors
  distributorsLoading: false,
  distributorsdata: [],
  distributorsError: "",

  //suppliers
  suppliersLoading: false,
  suppliersdata: [],
  suppliersError: "",

  //categories
  categoriesLoading: false,
  categoriesdata: [],
  categoriesError: "",

  //SYMBOL
  symbolsLoading: false,
  symbolsdata: [],
  symbolsError: "",

  //Finishings
  finishingsLoading: false,
  finishingsdata: [],
  finishingsError: "",

  //DASHBOARD
  dashboardDataLoading: false,
  dashboardDatadata: [],
  dashboardDataError: "",
};

const StatesReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_STATES_REQUEST:
      return {
        ...state,
        statesLoading: true,
      };
    case ActionTypes.GET_STATES_SUCCESS:
      return {
        ...state,
        statesLoading: false,
        statesdata: action.payload.data,
        statesError: "",
      };
    case ActionTypes.GET_STATES_FAIL:
      return {
        ...state,
        statesLoading: false,
        statesdata: [],
        statesError: action.payload.message,
      };
    default:
      return state;
  }
};

const CitiesReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_CITIES_REQUEST:
      return {
        ...state,
        citiesLoading: true,
      };
    case ActionTypes.GET_CITIES_SUCCESS:
      return {
        ...state,
        citiesLoading: false,
        citiesdata: action.payload.data,
        citiesError: "",
      };
    case ActionTypes.GET_CITIES_FAIL:
      return {
        ...state,
        citiesLoading: false,
        citiesdata: [],
        citiesError: action.payload.message,
      };
    default:
      return state;
  }
};

const ServicesReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SERVICES_REQUEST:
      return {
        ...state,
        servicesLoading: true,
      };
    case ActionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        servicesLoading: false,
        servicesdata: action.payload.data,
        servicesError: "",
      };
    case ActionTypes.GET_SERVICES_FAIL:
      return {
        ...state,
        servicesLoading: false,
        servicesdata: [],
        servicesError: action.payload.message,
      };
    default:
      return state;
  }
};

//BY KHALID ABBAS

const AddedServicesReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADDED_SERVICES:
      return {
        ...state,
        addedServices: action.payload,
      };
    default:
      return state;
  }
};

const SelectedServiceReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SELECTED_SERVICES:
      return {
        ...state,
        selectedService: action.payload,
      };
    default:
      return state;
  }
};

////////
const LicensedServicesReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LICENSED_SERVICES_REQUEST:
      return {
        ...state,
        licensedServicesLoading: true,
      };
    case ActionTypes.GET_LICENSED_SERVICES_SUCCESS:
      return {
        ...state,
        licensedServicesLoading: false,
        licensedServicesdata: action.payload.data,
        licensedServicesError: "",
      };
    case ActionTypes.GET_LICENSED_SERVICES_FAIL:
      return {
        ...state,
        licensedServicesLoading: false,
        licensedServicesdata: [],
        licensedServicesError: action.payload.message,
      };
    default:
      return state;
  }
};
const OtherServicesReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_OTHER_SERVICES_REQUEST:
      return {
        ...state,
        otherServicesLoading: true,
      };
    case ActionTypes.GET_OTHER_SERVICES_SUCCESS:
      return {
        ...state,
        otherServicesLoading: false,
        otherServicesdata: action.payload.data,
        otherServicesError: "",
      };
    case ActionTypes.GET_OTHER_SERVICES_FAIL:
      return {
        ...state,
        otherServicesLoading: false,
        otherServicesdata: [],
        otherServicesError: action.payload.message,
      };
    default:
      return state;
  }
};

const BrandsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_BRANDS_REQUEST:
      return {
        ...state,
        brandsLoading: true,
      };
    case ActionTypes.GET_BRANDS_SUCCESS:
      return {
        ...state,
        brandsLoading: false,
        brandsdata: action.payload.data,
        brandsError: "",
      };
    case ActionTypes.GET_BRANDS_FAIL:
      return {
        ...state,
        brandsLoading: false,
        brandsdata: [],
        brandsError: action.payload.message,
      };
    default:
      return state;
  }
};

const ColorsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_COLORS_REQUEST:
      return {
        ...state,
        colorsLoading: true,
      };
    case ActionTypes.GET_COLORS_SUCCESS:
      return {
        ...state,
        colorsLoading: false,
        colorsdata: action.payload.data,
        colorsError: "",
      };
    case ActionTypes.GET_COLORS_FAIL:
      return {
        ...state,
        colorsLoading: false,
        colorsdata: [],
        colorsError: action.payload.message,
      };
    default:
      return state;
  }
};

const FinishingsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_FINISHINGS_REQUEST:
      return {
        ...state,
        finishingsLoading: true,
      };
    case ActionTypes.GET_FINISHINGS_SUCCESS:
      return {
        ...state,
        finishingsLoading: false,
        finishingsdata: action.payload.data,
        finishingsError: "",
      };
    case ActionTypes.GET_FINISHINGS_FAIL:
      return {
        ...state,
        finishingsLoading: false,
        finishingsdata: [],
        finishingsError: action.payload.message,
      };
    default:
      return state;
  }
};

const MaterialsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_MATERIALS_REQUEST:
      return {
        ...state,
        materialsLoading: true,
      };
    case ActionTypes.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        materialsLoading: false,
        materialsdata: action.payload.data,
        materialsError: "",
      };
    case ActionTypes.GET_MATERIALS_FAIL:
      return {
        ...state,
        materialsLoading: false,
        materialsdata: [],
        materialsError: action.payload.message,
      };
    default:
      return state;
  }
};

const CoatsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_COATS_REQUEST:
      return {
        ...state,
        coatsLoading: true,
      };
    case ActionTypes.GET_COATS_SUCCESS:
      return {
        ...state,
        coatsLoading: false,
        coatsdata: action.payload.data,
        coatsError: "",
      };
    case ActionTypes.GET_COATS_FAIL:
      return {
        ...state,
        coatsLoading: false,
        coatsdata: [],
        coatsError: action.payload.message,
      };
    default:
      return state;
  }
};

const PrimersReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRIMERS_REQUEST:
      return {
        ...state,
        primersLoading: true,
      };
    case ActionTypes.GET_PRIMERS_SUCCESS:
      return {
        ...state,
        primersLoading: false,
        primersdata: action.payload.data,
        primersError: "",
      };
    case ActionTypes.GET_PRIMERS_FAIL:
      return {
        ...state,
        primersLoading: false,
        primersdata: [],
        primersError: action.payload.message,
      };
    default:
      return state;
  }
};

const PatternsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PATTERNS_REQUEST:
      return {
        ...state,
        patternsLoading: true,
      };
    case ActionTypes.GET_PATTERNS_SUCCESS:
      return {
        ...state,
        patternsLoading: false,
        patternsdata: action.payload.data,
        patternsError: "",
      };
    case ActionTypes.GET_PATTERNS_FAIL:
      return {
        ...state,
        patternsLoading: false,
        patternsdata: [],
        patternsError: action.payload.message,
      };
    default:
      return state;
  }
};

const GlassTypesReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_GLASS_TYPES_REQUEST:
      return {
        ...state,
        glassTypesLoading: true,
      };
    case ActionTypes.GET_GLASS_TYPES_SUCCESS:
      return {
        ...state,
        glassTypesLoading: false,
        glassTypesdata: action.payload.data,
        glassTypesError: "",
      };
    case ActionTypes.GET_GLASS_TYPES_FAIL:
      return {
        ...state,
        glassTypesLoading: false,
        glassTypesdata: [],
        glassTypesError: action.payload.message,
      };
    default:
      return state;
  }
};

const DistributorsReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_DISTRIBUTORS_REQUEST:
      return {
        ...state,
        distributorsLoading: true,
      };
    case ActionTypes.GET_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        distributorsLoading: false,
        distributorsdata: action.payload.data,
        distributorsError: "",
      };
    case ActionTypes.GET_DISTRIBUTORS_FAIL:
      return {
        ...state,
        distributorsLoading: false,
        distributorsdata: [],
        distributorsError: action.payload.message,
      };
    default:
      return state;
  }
};

const SuppliersReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUPPLIERS_REQUEST:
      return {
        ...state,
        suppliersLoading: true,
      };
    case ActionTypes.GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliersLoading: false,
        suppliersdata: action.payload.data,
        suppliersError: "",
      };
    case ActionTypes.GET_SUPPLIERS_FAIL:
      return {
        ...state,
        suppliersLoading: false,
        suppliersdata: [],
        suppliersError: action.payload.message,
      };
    default:
      return state;
  }
};

const CategoriesReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_CATEGORY_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
      };
    case ActionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        categoriesdata: action.payload.data,
        categoriesError: "",
      };
    case ActionTypes.GET_CATEGORY_FAIL:
      return {
        ...state,
        categoriesLoading: false,
        categoriesdata: [],
        categoriesError: action.payload.message,
      };
    default:
      return state;
  }
};

const SymbolReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SYMBOL_REQUEST:
      return {
        ...state,
        symbolsLoading: true,
      };
    case ActionTypes.GET_SYMBOL_SUCCESS:
      return {
        ...state,
        symbolsLoading: false,
        symbolsdata: action.payload.data,
        symbolsError: "",
      };
    case ActionTypes.GET_SYMBOL_FAIL:
      return {
        ...state,
        symbolsLoading: false,
        symbolsdata: [],
        symbolsError: action.payload.message,
      };
    default:
      return state;
  }
};

const DashboardDataReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        dashboardDataLoading: true,
      };
    case ActionTypes.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardDatadata: action.payload.data,
        dashboardDataError: "",
      };
    case ActionTypes.DASHBOARD_DATA_FAIL:
      return {
        ...state,
        dashboardDataLoading: false,
        dashboardDatadata: [],
        dashboardDataError: action.payload.message,
      };
    default:
      return state;
  }
};

export {
  StatesReducers,
  CitiesReducers,
  ServicesReducer,
  AddedServicesReducer,
  SelectedServiceReducer,
  LicensedServicesReducer,
  OtherServicesReducer,
  BrandsReducers,
  ColorsReducers,
  MaterialsReducers,
  CoatsReducers,
  PrimersReducers,
  PatternsReducers,
  GlassTypesReducers,
  DistributorsReducers,
  SuppliersReducers,
  CategoriesReducers,
  SymbolReducers,
  DashboardDataReducers,
  FinishingsReducers
};
