import {
  putMethodCustomHeader,
  getMethodCustomHeader,
  postMethodCustomHeader,
  postMethodMultiPartHeader,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const ProfileWizardAction = (obj, whichForm, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.PROFILE_WIZARD_REQUEST,
      });

      var objToSend = {};

      if (whichForm === "profileInformation") {
        objToSend = {
          first_name: obj.contactFirstName ? obj.contactFirstName : null,
          last_name: obj.contactLastName ? obj.contactLastName : null,
          phone: obj.companyPhone ? obj.companyPhone : null,
          company_name: obj.companyName ? obj.companyName : null,
          email: obj.companyEmailAddress ? obj.companyEmailAddress : null,
          street_address: obj.companyStreetAddress
            ? obj.companyStreetAddress
            : null,
          city_id: obj.companyCity ? obj.companyCity.id : null,
          state_id: obj.companyState ? obj.companyState.id : null,
          zip: obj.companyZip ? obj.companyZip : null,
          year_est: obj.yearEst ? obj.yearEst : null,
          website: obj.website ? obj.website : null,
        };
      } else if (whichForm === "documentAndRegistration") {
        objToSend = {
          licensed_profession: obj.licensedProfession,
          licensed_number: obj.licenseNumber,
          fein_corporate_charter_no: obj.FEIN,
          bonded: obj.bonded === "YES" ? true : false,
          general_liability_insurance:
            obj.generalLiabilityInsurance === "YES" ? true : false,
          gli_policy: obj.gliPolicy,
          gli_max: obj.gliPolicyMax,
          worker_comp: obj.workersComp === "YES" ? true : false,
          wc_policy: obj.wcPolicy,
          wc_max: obj.wcPolicyMax,
          business_description: obj.businessDescription,
          affiliations: obj.affiliations,
          certifications: obj.certifications,
        };
      } else if (whichForm === "services") {
        const servicesLicensed = obj.servicesArr.map((obj, ind) => {
          return obj.slug;
        });
        const otherServicesArr = obj.otherServicesArr.map((obj, ind) => {
          return obj.slug;
        });
        objToSend = {
          services_licensed: servicesLicensed,
          services_other: otherServicesArr,
        };
      } else if (whichForm === "socialMedia") {
        objToSend = {
          website: obj.website,
          facebook_link: obj.facebook,
          twitter_link: obj.twitter,
          linkedin_link: obj.linkedin,
        };
      }
      console.log("objToSend", objToSend);
      // return;
      // const objToSend2 = {
      //   // dob:obj ,
      //   // gender:obj ,
      //   country_id: obj,
      //   youtube_link: obj,
      //   password: obj,
      //   image: obj,
      //   cover: obj,
      // };
      const response = await postMethodCustomHeader(`user/update`, objToSend);
      console.log("profile udpate", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.PROFILE_WIZARD_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.PROFILE_WIZARD_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.PROFILE_WIZARD_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const UpdateProfileAction = (obj, whichForm, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_REQUEST,
      });

      var objToSend = {};

      if (whichForm === "profileInformation") {
        objToSend = {
          first_name: obj.contactFirstName ? obj.contactFirstName : null,
          last_name: obj.contactLastName ? obj.contactLastName : null,
          phone: obj.companyPhone ? obj.companyPhone : null,
          company_name: obj.companyName ? obj.companyName : null,
          company_email: obj.companyEmailAddress
            ? obj.companyEmailAddress
            : null,
          street_address: obj.companyStreetAddress
            ? obj.companyStreetAddress
            : null,
          city_id: obj.companyCity ? obj.companyCity.id : null,
          state_id: obj.companyState ? obj.companyState.id : null,
          zip: obj.companyZip ? obj.companyZip : null,
          year_est: obj.yearEst ? obj.yearEst : null,
          website: obj.website ? obj.website : null,
        };
      } else if (whichForm === "documentAndRegistration") {
        objToSend = {
          licensed_profession: obj.licensedProfession,
          licensed_number: obj.licenseNumber,
          fein_corporate_charter_no: obj.FEIN,
          bonded: obj.bonded === "YES" ? true : false,
          general_liability_insurance:
            obj.generalLiabilityInsurance === "YES" ? true : false,
          gli_policy: obj.gliPolicy,
          gli_max: obj.gliPolicyMax,
          worker_comp: obj.workersComp === "YES" ? true : false,
          wc_policy: obj.wcPolicy,
          wc_max: obj.wcPolicyMax,
          business_description: obj.businessDescription,
          affiliations: obj.affiliations,
          certifications: obj.certifications,
        };
      } else if (whichForm === "services") {
        const servicesLicensed = obj.servicesArr.map((obj, ind) => {
          return obj.slug;
        });
        const otherServicesArr = obj.otherServicesArr.map((obj, ind) => {
          return obj.slug;
        });
        objToSend = {
          services_licensed: servicesLicensed,
          services_other: otherServicesArr,
        };
      } else if (whichForm === "socialMedia") {
        objToSend = {
          website: obj.website,
          facebook_link: obj.facebook,
          twitter_link: obj.twitter,
          linkedin_link: obj.linkedin,
        };
      }
      console.log("objToSend", objToSend);
      const response = await postMethodCustomHeader(`user/update`, objToSend);
      console.log("profile udpate", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.UPDATE_PROFILE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.UPDATE_PROFILE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EmptyProfileWizardState = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_PROFILE_WIZARD_STATE,
    });
  };
};

const GetUserProfileAction = (setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_USER_PROFILE_REQUEST,
      });

      const response = await getMethodCustomHeader(`user`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_USER_PROFILE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response?.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_USER_PROFILE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_USER_PROFILE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetSingleUserProfileAction = (userEmail, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_SINGLE_USER_PROFILE_REQUEST,
      });
      const response = await getMethodCustomHeader(
        `user/single-connections?email=${userEmail}`
      );
      console.log(
        "🚀 ~ file: ProfileAction.js:252 ~ return ~ response:",
        response
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_SINGLE_USER_PROFILE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response?.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_SINGLE_USER_PROFILE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.GET_SINGLE_USER_PROFILE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const UpdateProfilePicAction = (fileName, whichform, setAlert, setOpen) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_REQUEST,
      });

      const formData = new FormData();
      formData.append("image", fileName);

      const response = await postMethodMultiPartHeader(`user/update`, formData);
      console.log(response, "response");

      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.UPDATE_PROFILE_SUCCESS,
          payload: response.data,
        });
        setOpen(false);
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.UPDATE_PROFILE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetCoverProjectAction = (setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PROFILE_COVER_PROJECT_REQUEST,
      });

      const response = await getMethodCustomHeader(`my-gallery-covers`);
      console.log(response, "get top six project");

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PROFILE_COVER_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_PROFILE_COVER_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PROFILE_COVER_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

export {
  ProfileWizardAction,
  UpdateProfileAction,
  EmptyProfileWizardState,
  GetUserProfileAction,
  UpdateProfilePicAction,
  GetCoverProjectAction,
  GetSingleUserProfileAction,
};
