import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = () => {
  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("userData"))?.token
  );
  const { userLogindata } = useSelector((state) => state.LoginReducers);

  return isAuth ? <Navigate to="/jobs" /> : <Outlet />;
};

export default AuthRoute;
