import ActionTypes from "../Constants";

const INITIALSTATE = {
  //search connection
  getNotificationLoading: false,
  getNotificationdata: "",
  getNotificationError: "",

  // COUNT
  notificationCountLoading: false,
  notificationCountdata: "",
  notificationCountError: "",
};

const GetNotificationReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        getNotificationLoading: true,
      };
    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      console.log(action.payload, "action.payload");
      return {
        ...state,
        getNotificationLoading: false,
        getNotificationdata: action.payload.data,
        getNotificationError: "",
      };
    case ActionTypes.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        getNotificationLoading: false,
        getNotificationdata: [],
        getNotificationError: action.payload.message,
      };
    default:
      return state;
  }
};

const GetNotificationCountReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_NOTIFICATION_COUNT_REQUEST:
      return {
        ...state,
        notificationCountLoading: true,
      };
    case ActionTypes.GET_NOTIFICATION_COUNT_SUCCESS:
      console.log(action.payload, "action.payload");
      return {
        ...state,
        notificationCountLoading: false,
        notificationCountdata: action.payload.data,
        notificationCountError: "",
      };
    case ActionTypes.GET_NOTIFICATION_COUNT_FAIL:
      return {
        ...state,
        notificationCountLoading: false,
        notificationCountdata: [],
        notificationCountError: action.payload.message,
      };
    case ActionTypes.NOTIFICATION_COUNT_ZERO:
      return {
        ...state,
        notificationCountdata: 0,
      };

    default:
      return state;
  }
};

export { GetNotificationReducer, GetNotificationCountReducer };
