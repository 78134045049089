import ActionTypes from "../Constants";

const INITIALSTATE = {
  //ForgetReducers
  userForgetLoading: false,
  userForgetdata: "",
  userForgetMessage: "",
  userForgetError: "",

  //code verification
  codeVerificationLoading: false,
  codeVerificationdata: "",

  //confirm password
  confirmPasswordLoading: false,
  confirmPassworddata: "",

  //LoginReducers
  userLoginLoading: false,
  userLogindata: "",
  userLoginMessage: "",
  userLoginError: "",

  ///signup
  userSignupLoading: false,
  userSignupdata: "",
  userSignupMessage: "",
  userSignupError: "",

  userRole: "",

  //get all user
  getAllUserLoading: false,
  getAllUserdata: [],

  //logout
  userLogoutLoading: false,
};

const ForgetReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_FORGET_REQUEST:
      return {
        ...state,
        userForgetLoading: true,
      };
    case ActionTypes.USER_FORGET_SUCCESS:
      return {
        ...state,
        userForgetLoading: false,
        userForgetdata: action.payload,
        userForgetMessage: action.payload.message,
        userForgetError: "",
      };
    case ActionTypes.USER_FORGET_FAIL:
      return {
        ...state,
        userForgetLoading: false,
        userForgetdata: "",
        userForgetMessage: "",
        userForgetError: action.payload.message,
      };
    default:
      return state;
  }
};

const OtpVerificationReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_FORGET_REQUEST:
      return {
        ...state,
        codeVerificationLoading: true,
      };
    case ActionTypes.USER_FORGET_SUCCESS:
      return {
        ...state,
        codeVerificationLoading: false,
        codeVerificationdata: action.payload,
      };
    case ActionTypes.USER_FORGET_FAIL:
      return {
        ...state,
        codeVerificationLoading: false,
        codeVerificationdata: "",
      };
    default:
      return state;
  }
};

const ConfirmPasswordReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_CONFIRMPASSWORD_REQUEST:
      return {
        ...state,
        confirmPasswordLoading: true,
      };
    case ActionTypes.USER_CONFIRMPASSWORD_SUCCESS:
      return {
        ...state,
        confirmPasswordLoading: false,
        confirmPassworddata: action.payload,
      };
    case ActionTypes.USER_CONFIRMPASSWORD_FAIL:
      return {
        ...state,
        confirmPasswordLoading: false,
        confirmPassworddata: "",
      };
    default:
      return state;
  }
};

const LoginReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        userLoginLoading: true,
      };
    case ActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        userLoginLoading: false,
        userLogindata: action.payload,
        userLoginMessage: action.payload.message,
        userLoginError: "",
      };
    case ActionTypes.USER_LOGIN_FAIL:
      return {
        ...state,
        userLoginLoading: false,
        userLogindata: "",
        userLoginMessage: "",
        userLoginError: action.payload.message,
      };
    default:
      return state;
  }
};

const SignupReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_SIGNUP_REQUEST:
      return {
        ...state,
        userSignupLoading: true,
        // userSignupdata: "",
        // userSignupMessage: "",
        // userSignupError: "",
      };
    case ActionTypes.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        userSignupLoading: false,
        userSignupdata: action.payload,
        userSignupMessage: action.payload.message,
        userSignupError: "",
      };
    case ActionTypes.USER_SIGNUP_FAIL:
      return {
        ...state,
        userSignupLoading: false,
        userSignupdata: "",
        userSignupMessage: "",
        userSignupError: action.payload.message,
      };
    default:
      return state;
  }
};

const UserRoleReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };

    default:
      return state;
  }
};

const GetAllUserReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_USER_REQUEST:
      return {
        ...state,
        getAllUserLoading: true,
      };
    case ActionTypes.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        getAllUserLoading: false,
        getAllUserdata: action.payload.data,
      };
    case ActionTypes.GET_ALL_USER_FAIL:
      return {
        ...state,
        getAllUserLoading: false,
        getAllUserdata: [],
      };
    default:
      return state;
  }
};

const UserLogoutReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGOUT_REQUEST:
      return {
        ...state,
        userLogoutLoading: true,
      };
    case ActionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userLogoutLoading: false,
      };
    case ActionTypes.USER_LOGOUT_FAIL:
      return {
        ...state,
        userLogoutLoading: false,
      };
    default:
      return state;
  }
};

export {
  ForgetReducers,
  LoginReducers,
  SignupReducers,
  UserRoleReducer,
  OtpVerificationReducers,
  ConfirmPasswordReducers,
  GetAllUserReducers,
  UserLogoutReducer,
};
