import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
	BidIcon,
	BiEdit,
	LocationOnOutlinedIcon,
	RiDeleteBin6Line,
	EventAvailableOutlinedIcon,
	EventBusyOutlinedIcon,
} from "../../Assets";
import { postedDuration } from "../../Utils/utils";
import { DeleteJobAction } from "../../Store/Actions/BuildBidsJobAction";
import styles from "./ListView.module.css";
import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from "..";

const ListViewCard = ({
	deleteBtn,
	color,
	path,
	getAllUSerProjectdata,
	setAlert,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [paraLine, setParaLine] = useState(true);
	const [hover, setHover] = useState(false);

	const paraHandler = (e) => {
		e.stopPropagation();
		setParaLine(!paraLine);
	};

	const hoverHandler = (event) => {
		if (event == "enter") {
			setHover(true);
			return;
		}
		setHover(false);
	};

	const {
		biding_end_date,
		biding_start_date,
		description,
		title,
		project_start_date,
		proposals_count,
		services_count,
		services,
		slug,
		created_at,
		address,
		state,
		zipcode,
		status,
	} = getAllUSerProjectdata;

	const deleteJob = (e) => {
		e.stopPropagation();
		dispatch(DeleteJobAction(slug, setAlert));
	};

	const editJob = (e) => {
		e.stopPropagation();
		console.log("edit");
		navigate(`/buildbid/updatejob/${slug}`);
	};

	console.log("getAllUSerProjectdata", status);
	console.log("getAllUSerProjectdata", getAllUSerProjectdata);
	return (
		<section
			className={styles.jobCardWrapper}
			onMouseEnter={() => hoverHandler("enter")}
			onMouseLeave={() => hoverHandler("leave")}
			onClick={() => {
				navigate(`${path}/${slug}`);
			}}
		>
			<Box
				className={styles.cardHeader}
				// onClick={() => navigate(`${path}/${slug}`)}
			>
				<Box>
					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold" }}
						>
							{title}
						</Typography>
						{status === "Draft" && (
							<Box
								sx={{
									backgroundColor: "var(--editBtnLight)",
									padding: "5px 10px",
									borderRadius: "5px",
									color: "var(--milestoneText)",
								}}
								alignSelf={"flex-end"}
								className={styles.editbtn}
							>
								<Box sx={{ display: "flex", gap: "10px" }}>
									<Typography fontSize={"12px"}>Draft</Typography>
								</Box>
							</Box>
						)}
					</Box>
					<Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
						{postedDuration(created_at)}
					</Typography>
				</Box>
				<section className={styles.cardmodifyBtn}>
					<Box
						sx={{
							backgroundColor: "var(--light-green)",
							padding: "5px 10px",
							borderRadius: "5px",
							color: "var(--green)",
						}}
						alignSelf={"flex-end"}
						className={styles.cardSeriveWrapper}
					>
						<div>
							<Typography fontSize={"12px"}>
								{services_count} Services required
							</Typography>
						</div>
					</Box>
					<Box
						sx={{
							backgroundColor: "var(--editBtnLight)",
							padding: "5px 10px",
							borderRadius: "5px",
							color: "var(--milestoneText)",
						}}
						alignSelf={"flex-end"}
						className={styles.editbtn}
						onClick={editJob}
					>
						<Box sx={{ display: "flex", gap: "10px" }}>
							<Typography fontSize={"12px"}>Edit</Typography>
							<BiEdit />
						</Box>
					</Box>
					<Box
						sx={{
							backgroundColor: "var(--lightRed)",
							padding: "5px 10px",
							borderRadius: "5px",
							color: "var(--redColor)",
						}}
						alignSelf={"flex-end"}
						className={styles.deleteBtn}
					>
						<Box
							sx={{ display: "flex", gap: "10px" }}
							onClick={deleteJob}
						>
							<Typography fontSize={"12px"}>Delete</Typography>
							<RiDeleteBin6Line />
						</Box>
					</Box>
				</section>
			</Box>
			<section
				className={styles.headerList}
				// onClick={() => navigate(`${path}/${slug}`)}
			>
				{/* {headerList.map((list, index) => { */}
				{/* return ( */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "10px",
						my: "5px",
						// ml: index !== 0 && "20px",
					}}
					className={styles.jobDetails}
				>
					<LocationOnOutlinedIcon
						sx={{
							marginRight: " 5px",
							color: "var(--green)",
							ml: "-5px",
							fontSize: "18px",
						}}
					/>
					<small>
						{address}, {state?.label}, {zipcode}
					</small>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "10px",
						my: "5px",
						ml: "20px",
					}}
					className={styles.jobDetails}
				>
					<EventAvailableOutlinedIcon
						sx={{
							marginRight: " 5px",
							color: "var(--primary-color)",
							fontSize: "18px",
						}}
					/>
					<small>Create Date: {created_at}</small>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "10px",
						my: "5px",
						ml: "20px",
					}}
					className={styles.jobDetails}
				>
					<EventBusyOutlinedIcon
						sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
					/>
					<small>Bid Closing Date: {biding_end_date}</small>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "10px",
						my: "5px",
						ml: "20px",
					}}
					className={styles.jobDetails}
				>
					<EventBusyOutlinedIcon
						sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
					/>
					<small># of Bid Received: {proposals_count}</small>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "10px",
						my: "5px",
						ml: "20px",
					}}
					className={styles.jobDetails}
				>
					<img
						src={BidIcon}
						style={{ marginRight: "5px" }}
					/>
					<small>Job Start Date: {project_start_date}</small>
				</Box>
				{/* ); */}
				{/* })} */}
			</section>
			<section
				className={styles.cardBody}
				// onClick={() => navigate(`${path}/${slug}`)}
			>
				<TextTruncate
					line={paraLine && 3}
					element="span"
					truncateText="…"
					text={description}
					// textTruncateChild={}
					style={{ color: "var(--textColor)", fontSize: "medium" }}
				/>
			</section>
			<small
				className={styles.paraMoreBtn}
				onClick={paraHandler}
			>
				{paraLine ? "More" : "Less"}
			</small>
			<section className={styles.cardFooter}>
				<div>
					<Typography
						sx={{
							fontWeight: "bold",
						}}
					>
						Services:
					</Typography>
				</div>
				<ScrollableTabs
					bgColor={color}
					hover={hover}
					servicesTags={services}
				/>
			</section>
			{deleteBtn && (
				<Box
					sx={{ display: "flex", justifyContent: "flex-end", my: 2, gap: 2 }}
				>
					{/* <ButtonCmp
            variant={"outlined"}
            color="error"
            style={{ width: "max-content", padding: "10px 15px" }}
          /> */}
					<WhiteButtonCmp
						title="Permanent Delete Job"
						style={{ borderColor: " red !important", color: "red" }}
					/>
					<ButtonCmp
						style={{ width: "max-content", padding: "10px 15px" }}
						title="Restore Job"
					/>
				</Box>
			)}
		</section>
	);
};

const headerList = [
	{
		title: "New Orleans, LA, 701115",
		icon: (
			<LocationOnOutlinedIcon
				sx={{
					marginRight: " 5px",
					color: "var(--green)",
					ml: "-5px",
					fontSize: "18px",
				}}
			/>
		),
	},
	{
		title: "Create Date: 6/3/2021",
		icon: (
			<EventAvailableOutlinedIcon
				sx={{
					marginRight: " 5px",
					color: "var(--primary-color)",
					fontSize: "18px",
				}}
			/>
		),
	},
	{
		title: "Bid Closing Date: 12/25/2021",
		icon: (
			<EventBusyOutlinedIcon
				sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
			/>
		),
	},
	{
		title: "# of Bid Received: 250",
		icon: (
			<EventBusyOutlinedIcon
				sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
			/>
		),
	},
	{
		title: "Job Start Date: 5/25/2021",
		icon: (
			// <EventBusyOutlinedIcon
			//   sx={{ marginRight: " 5px", color: "red", fontSize: "18px" }}
			// />
			<img
				src={BidIcon}
				style={{ marginRight: "5px" }}
			/>
		),
	},
];

export default ListViewCard;
