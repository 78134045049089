import moment from "moment";
import { IMG_URL } from "./response";

const dateFormat = (date) => {
  var formatDate = new Date(date);
  var dd = String(formatDate.getDate()).padStart(2, "0");
  var mm = String(formatDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = formatDate.getFullYear();

  return (formatDate = yyyy + "-" + mm + "-" + dd);
};

const postedDuration = (date) => {
  var duration = moment(date).startOf("data").fromNow(date);

  return `Posted - ${duration} ago`;
};

function toggleFullScreen() {
  if (
    !document.fullscreenElement && // alternative standard method
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

const diffTwoTime = (start_date, end_date) => {
  console.log(start_date, "start_date");
  console.log(end_date, "end_date");
  const endDate = new Date(end_date);
  const startDate = new Date(start_date);

  var a = moment(endDate);
  var b = moment(startDate);
  var years = a.diff(b, "year");
  b.add(years, "years");

  var months = a.diff(b, "months");
  b.add(months, "months");

  var days = a.diff(b, "days");

  var response;

  if (years > 0) {
    return years + " years " + months + " months " + days + " days";
  }
  if (months > 0) {
    return months + " months " + days + " days";
  }
  if (days > 0) {
    return days + " days";
  }
  return "null";
  // 8 years 5 months 2 days
};

const averageNumber = (...args) =>
  (args.reduce((a, b) => a + b) / args.length).toFixed(2);

const imagePath = (image) => {
  console.log(`${IMG_URL}/${image}`);
  return `${IMG_URL}/${image}`;
};

export {
  dateFormat,
  postedDuration,
  toggleFullScreen,
  diffTwoTime,
  imagePath,
  averageNumber,
};
