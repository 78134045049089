import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProductTabPanel from "./ProductTabPanel";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AddProduct.module.css";
import {
	DragNDrop,
	InputCmp,
	TextAreaCmp,
	DropDownCmp,
	VIewImagesModal,
} from "../../../Components";
import {
	GetBrandsAction,
	GetCategory,
} from "../../../Store/Actions/GetActions";
import { BiEdit, RiDeleteBin6Line } from "../../../Assets";

const OtherTab = (props) => {
	const dispatch = useDispatch();

	const { tabValue, control, watch, imageReplace } = props;

	const [open, setOpen] = useState(false);

	return (
		<ProductTabPanel
			value={tabValue}
			index={4}
		>
			<section className={styles.productIntro_wrapper}>
				<Grid
					container
					columnSpacing={2}
					rowSpacing={5}
				>
					<Grid
						item
						lg={4}
					>
						<InputCmp
							name="type"
							control={control}
							label="Type"
						/>
					</Grid>
					<Grid
						item
						lg={4}
					>
						<InputCmp
							name="installer"
							control={control}
							label="Installer"
						/>
					</Grid>
					{/* <Grid
						item
						lg={4}
					>
						<InputCmp
							name="sku"
							control={control}
							label="SKU"
						/>
					</Grid> */}
				</Grid>
			</section>
		</ProductTabPanel>
	);
};

export default OtherTab;
