import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import MenuIconRight from "../../Assets/Icons/Navigation-Right.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import styles from "./Navbar.module.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import Avatar from "../../Assets/Images/Mask Group 17.png";
import {
	ZoomOutMapOutlinedIcon,
	NotificationsOutlinedIcon,
	Logo2,
	MenuOutlinedIcon,
} from "../../Assets";
import { useWindowDimensions } from "../../Hooks";
import { AppSwitcher, BackDropCmp } from "..";
import { useNavigate } from "react-router-dom";
import { toggleFullScreen } from "../../Utils/utils";
import { AvatarCmp } from "..";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../Hooks/useAlert/useAlert";
import { LoaderCmp } from "..";
import useGetUserRole from "../../Hooks/getUserRole";
import { GetNotificationCount } from "../../Store/Actions/NotificationAction";
import { Badge } from "@mui/material";
import { Briefcase } from "../../Assets";

const Navbar = ({
	sideBarClose,
	drawerWidth,
	handleDrawerToggle,
	handleSideBarToggle,
}) => {
	const navigate = useNavigate();

	const [open, setOpen] = React.useState(true);

	const { width } = useWindowDimensions();

	const { profileDetails, profileDetailsLoading } = useSelector(
		(state) => state.UserProfileReducer
	);

	const userRole = useGetUserRole();
	return width >= "768" ? (
		<AppNavBar
			profileDetails={profileDetails}
			navigate={navigate}
			sideBarClose={sideBarClose}
			drawerWidth={drawerWidth}
			handleDrawerToggle={handleDrawerToggle}
			handleSideBarToggle={handleSideBarToggle}
			profileDetailsLoading={profileDetailsLoading}
		/>
	) : (
		<MobileNavBar
			sideBarClose={sideBarClose}
			drawerWidth={drawerWidth}
			handleDrawerToggle={handleDrawerToggle}
			handleSideBarToggle={handleSideBarToggle}
		/>
	);
};

const AppNavBar = ({
	profileDetails,
	navigate,
	sideBarClose,
	drawerWidth,
	handleDrawerToggle,
	handleSideBarToggle,
	profileDetailsLoading,
}) => {
	const { notificationCountdata, notificationCountLoading } = useSelector(
		(state) => state.GetNotificationCountReducer
	);
	const dispatch = useDispatch();
	const { setAlert } = useAlert();

	useEffect(() => {
		dispatch(GetNotificationCount(setAlert));
	}, []);

	const goToShop = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		const shopUserData = {
			company_name: userData.company_name,
			all_roles: userData.all_roles,
			name: userData.name,
			email: userData.email,
			company_email: userData.company_email,
			token: userData.token,
			first_name: userData.first_name,
			last_name: userData.last_name,
			phone: userData.phone,
			street_address: userData.street_address,
			zip: userData.zip,
		};

		const str = `?data=${encodeURIComponent(JSON.stringify(shopUserData))}`;
		window.location.href = `https://shop.buildlink.com/login${str}`;
	};

	const { all_roles } = profileDetails || [];

	return (
		<AppBar
			position="fixed"
			sx={{
				width: {
					lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
				},
				ml: { sm: `${drawerWidth}px` },
				background: "white",
			}}
		>
			<Toolbar sx={{ pr: "0 !important" }}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { lg: "none" } }}
				>
					{/* <MenuOpenIcon /> */}
					<img
						// onClick={() => handleDrawerToggle()}
						src={MenuIconRight}
						alt="icon"
					/>
				</IconButton>
				<Typography
					variant="h6"
					noWrap
					component="div"
				>
					{!sideBarClose && (
						<IconButton
							// color="inherit"
							// aria-label="open drawer"
							// edge="start"
							onClick={handleSideBarToggle}
							sx={{
								display: { xs: "none", lg: "inline-block", marginRight: 12 },
							}}
						>
							<MenuOpenIcon />
							{/* <img src={MenuIconRight} alt="icon" /> */}
						</IconButton>
					)}
				</Typography>

				<section className={styles.navbarWrapper}>
					<div className={styles.navbarOptions}>
						<ul>
							<NavLink
								to="/dashboard"
								className={({ isActive }) =>
									isActive ? styles.activeClassName : undefined
								}
							>
								<ListItemButton className={styles.listBtn}>
									<ListItemText
										className={styles.list}
										primary="Dashboard"
									/>
								</ListItemButton>
							</NavLink>
							{all_roles &&
								(all_roles.includes("Pro Connection - Connections") ||
									all_roles.includes(
										"Pro Connection - My Project Gallery"
									)) && (
									<NavLink
										to="/buildlink/proconnection"
										className={({ isActive }) =>
											isActive ? styles.activeClassName : undefined
										}
									>
										<ListItemButton className={styles.listBtn}>
											<ListItemText
												className={styles.list}
												primary="Pro Connections"
											/>
										</ListItemButton>
									</NavLink>
								)}
							{all_roles &&
								(all_roles.includes("Build Bid - Jobs") ||
									all_roles.includes("Build Bid - My Bids")) && (
									<NavLink
										to="/buildbid/jobs"
										className={({ isActive }) =>
											isActive ? styles.activeClassName : undefined
										}
									>
										<ListItemButton className={styles.listBtn}>
											<ListItemText
												className={styles.list}
												primary="Build Bid"
											/>
										</ListItemButton>
									</NavLink>
								)}
							{all_roles && all_roles.includes("Build Studio - Jobs") && (
								<NavLink
									to="/buildstudio/jobs"
									className={({ isActive }) =>
										isActive ? styles.activeClassName : undefined
									}
								>
									<ListItemButton className={styles.listBtn}>
										<ListItemText
											className={styles.list}
											primary="Build Studio"
										/>
									</ListItemButton>
								</NavLink>
							)}
							{all_roles &&
								(all_roles.includes("Shop - Products") ||
									all_roles.includes("Shop - Orders")) && (
									<NavLink
										to="/buildshop/viewproduct"
										className={({ isActive }) =>
											isActive ? styles.activeClassName : undefined
										}
									>
										<ListItemButton className={styles.listBtn}>
											<ListItemText
												className={styles.list}
												primary="Build Shop"
											/>
										</ListItemButton>
									</NavLink>
								)}
							{all_roles && all_roles.includes("Linksurance - Orders") && (
								<NavLink
									to="/buildinsurance/orders/general"
									className={({ isActive }) =>
										isActive ? styles.activeClassName : undefined
									}
								>
									<ListItemButton>
										<ListItemText primary="Linksurance" />
									</ListItemButton>
								</NavLink>
							)}
							{all_roles && all_roles.includes("Chat") && (
								<NavLink
									to="/chat"
									className={({ isActive }) =>
										isActive ? styles.activeClassName : undefined
									}
								>
									<ListItemButton>
										<ListItemText primary="Chat" />
									</ListItemButton>
								</NavLink>
							)}
						</ul>
					</div>
					<div className={styles.navOtherOptions}>
						<section className={styles.navIconWrapper}>
							<div>
								<ZoomOutMapOutlinedIcon
									onClick={() => {
										toggleFullScreen();
									}}
									fontSize={"small"}
								/>
							</div>
							<div onClick={() => navigate("/notification")}>
								{/* <NotificationsOutlinedIcon fontSize={"small"} /> */}
								<Badge
									badgeContent={notificationCountdata || 0}
									color="primary"
								>
									<NotificationsOutlinedIcon
										fontSize={"small"}
										color="action"
									/>
								</Badge>
							</div>
						</section>
						<section className={styles.shoplink}>
							<a onClick={goToShop}>Go to Shop</a>
						</section>
						<section className={styles.navAvatar}>
							<div className={styles.navAvatarName}>
								{profileDetailsLoading ? (
									<LoaderCmp />
								) : (
									<>
										<p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
										<small>{profileDetails?.type}</small>
									</>
								)}
							</div>
							{/* <Link to="profile">
                <img src={Avatar} alt="" />
              </Link> */}
							<AvatarCmp
								img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
								firstLetter={profileDetails?.first_name}
							/>
						</section>
						{/* <section className={styles.navProfile}>
              <TocIcon />
            </section> */}
					</div>
				</section>
			</Toolbar>
		</AppBar>
	);
};

const MobileNavBar = ({
	sideBarClose,
	drawerWidth,
	handleDrawerToggle,
	handleSideBarToggle,
}) => {
	return (
		<AppBar
			position="fixed"
			sx={{
				width: {
					lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
				},
				ml: { sm: `${drawerWidth}px` },
				background: "white",
			}}
		>
			<Toolbar>
				<img
					src={Logo2}
					style={{ marginRight: "20px" }}
					alt=""
				/>
				{/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <AppsOutlinedIcon sx={{ color: "var(--textColor)" }} />
        </IconButton> */}
				<AppSwitcher />
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { lg: "none" } }}
				>
					<MenuOutlinedIcon sx={{ color: "var(--textColor)" }} />
				</IconButton>

				<section className={styles.mobileNavbarWrapper}>
					{/* <div className={styles.navbarOptions}>
            <ul>
              <li>Pro Connections</li>
              <li>Build Bid</li>
              <li>Build Studios</li>
            </ul>
          </div> */}
					<div className={styles.navOtherOptions}>
						<section className={styles.navIconWrapper}>
							<div>
								<NotificationsOutlinedIcon fontSize={"small"} />
							</div>
						</section>
						<section className={styles.mobileNavAvatar}>
							<div className={styles.mobileNavAvatarName}>
								<p>Eliel Joel</p>
								<small>Pro Connection</small>
							</div>
							<img
								src={Avatar}
								alt=""
							/>
						</section>
					</div>
				</section>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
