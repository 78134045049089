import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	RemoveConnectionAction,
	CreateConnectionAction,
	RecievedConnectionRequestAction,
} from "../../Store/Actions/ProConnectionAction";
import useAlert from "../../Hooks/useAlert/useAlert";

import ProfilePic from "../../Assets/Images/profilepicCard.png";
import styles from "./UserModelCard.module.css";
import {
	CloseIcon,
	DraftsOutlinedIcon,
	LanguageIcon,
	LocalPhoneOutlinedIcon,
	LocationOnOutlinedIcon,
	MessageOutlinedIcon,
	VerifiedOutlinedIcon,
} from "../../Assets";
import { ButtonCmp, IconCmp, RatingCmp, WhiteButtonCmp } from "..";
import { useNavigate } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	outline: "none",
	borderRadius: "15px",
};

export default function UserModelCard({
	open,
	setOpen,
	userDetails,
	setUserDetails,
	isRecievedRequestsPage,
}) {
	const dispatch = useDispatch();
	const { setAlert } = useAlert();
	const {
		first_name,
		last_name,
		email,
		phone,
		street_address,
		company_name,
		company_email,
		city,
		state,
		zip,
		website,
		year_est,
		comapny_email,
		rating,
		ImgURL,
		image,
		sent_request_pending,
		received_request_pending,
		is_connected,
	} = userDetails || {};

	console.log("userDetails user", userDetails);

	// const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setUserDetails("");
	};

	const removeConnection = () => {
		dispatch(RemoveConnectionAction(email, setAlert));
	};
	const createConnection = () => {
		dispatch(CreateConnectionAction(email, setAlert));
	};

	const cancelConnectionRequest = () => {
		dispatch(RecievedConnectionRequestAction(email, "Rejected", setAlert));
	};

	const acceptConnectionRequest = () => {
		dispatch(RecievedConnectionRequestAction(email, "Accepted", setAlert));
	};

	const navigate = useNavigate();
	const sendMessage = () => {
		navigate("/chat", {
			state: {
				userLocation: userDetails,
			},
		});
	};

	return (
		<div>
			{/* <Button onClick={handleOpen}>Open modal</Button> */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<section className={styles.profileCardWrapper}>
							<div className={styles.closeBtn}>
								<CloseIcon
									onClick={() => {
										setOpen(!open);
									}}
								/>
							</div>
							<Grid container>
								<Grid
									className={styles.profileSection}
									item
									lg={3}
								>
									<section className={styles.userCardProfile}>
										<img
											src={ImgURL + image}
											alt=""
										/>
									</section>
									<section>
										<div className={styles.profileHeading}>
											<Typography
												noWrap
												variant="h6"
												fontWeight={"bold"}
											>
												Personal Details:
											</Typography>
										</div>
										<div>
											<section>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Account Email *
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{email}
												</Typography>
											</section>
											<Box className={styles.profile_mediaLinks}>
												<IconCmp style={{ backgroundColor: "transparent" }}>
													<LocalPhoneOutlinedIcon
														style={{ color: "var(--primary-color)" }}
													/>
												</IconCmp>
												<Typography>
													{phone ? phone : "Not Available"}
												</Typography>
											</Box>
											<Box className={styles.profile_mediaLinks}>
												<IconCmp
													style={{ backgroundColor: "transparent" }}
													onClick={sendMessage}
												>
													<MessageOutlinedIcon
														style={{ color: "var(--primary-color)" }}
													/>
												</IconCmp>
												<Typography noWrap>Message</Typography>
											</Box>
											<Box className={styles.profile_mediaLinks}>
												<IconCmp style={{ backgroundColor: "transparent" }}>
													<DraftsOutlinedIcon
														style={{ color: "var(--primary-color)" }}
													/>
												</IconCmp>
												<Typography noWrap>
													{comapny_email ? comapny_email : "Not Available"}
												</Typography>
											</Box>
											<Box className={styles.profile_mediaLinks}>
												<IconCmp style={{ backgroundColor: "transparent" }}>
													<LanguageIcon
														style={{ color: "var(--primary-color)" }}
													/>
												</IconCmp>
												<Typography noWrap>
													{website ? website : "Not Available"}
												</Typography>
											</Box>
										</div>
									</section>
								</Grid>

								{/* ////company wrapper */}
								<Grid
									item
									lg={9}
									className={styles.companyDetails_wrapper}
								>
									<Grid
										container
										className={styles.comapnyInfo}
									>
										<Grid
											item
											lg={9}
										>
											<Box>
												<Box sx={{ display: "flex", alignItems: "center" }}>
													<Typography
														variant="h5"
														fontWeight="bold"
														className={styles.bidName}
													>
														{company_name ? company_name : "N/A"}
													</Typography>
													<Typography fontSize={"15px"}>
														<VerifiedOutlinedIcon
															sx={{ color: "var(--primary-color)" }}
														/>
													</Typography>
												</Box>
												<Box sx={{ margin: "10px 0 " }}>
													<Typography>
														{state?.label ? `${state.label}, USA` : "N/A"}
													</Typography>
												</Box>
												<Box sx={{ margin: "5px 0 ", display: "flex", gap: 1 }}>
													<RatingCmp
														ratingValue={rating}
														readOnly={true}
													/>
												</Box>
											</Box>
										</Grid>
										<Grid
											lg={3}
											item
											className={styles.connectBtns}
										>
											<ButtonCmp
												title={"View Complete Profile"}
												onClick={() => {
													navigate(`/buildlink/userprofile/${email}`);
												}}
											/>
											{isRecievedRequestsPage ? (
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														gap: 1,
													}}
												>
													{/* <ButtonCmp
                              title="Reject"
                              style={{ backgroundColor: "var(--redColor)" }}
                            // onClick={cancelConnectionRequest}
                            /> */}
													<WhiteButtonCmp
														title="Reject"
														onClick={cancelConnectionRequest}
													/>
													<ButtonCmp
														title="Accept"
														onClick={acceptConnectionRequest}
													/>
												</Box>
											) : is_connected ? (
												<ButtonCmp
													title="DisConnect"
													style={{ backgroundColor: "var(--redColor)" }}
													onClick={removeConnection}
												/>
											) : sent_request_pending ? (
												<ButtonCmp
													title="pending Request"
													disabled
												/>
											) : (
												<ButtonCmp
													title="Connect"
													onClick={createConnection}
												/>
											)}
										</Grid>
									</Grid>

									<section>
										<div className={styles.profileHeading}>
											<Typography
												noWrap
												variant="h6"
												fontWeight={"bold"}
											>
												Company Details:
											</Typography>
										</div>

										{/* ///// Company Details: */}
										<Grid
											container
											rowSpacing={5}
										>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Contact First Name
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{first_name ? first_name : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Contact Last Name
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{last_name ? last_name : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company Name
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{company_name ? company_name : "N/A"}
												</Typography>
											</Grid>

											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company City
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{city?.label ? city.label : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company State
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{state?.label ? state.label : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company Zip
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{zip ? zip : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company Phone
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{phone ? phone : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company Email Address
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{company_email ? company_email : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Website
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{website ? website : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Company Street Address
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{street_address ? street_address : "N/A"}
												</Typography>
											</Grid>
											<Grid
												item
												lg={4}
											>
												<Typography
													fontSize="16px"
													sx={{ color: "var(--textColor)" }}
												>
													Year Est.
												</Typography>
												<Typography
													fontSize="18px"
													sx={{ mt: "10px" }}
												>
													{year_est ? year_est : "N/A"}
												</Typography>
											</Grid>
										</Grid>
									</section>
								</Grid>
							</Grid>
						</section>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
