import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useGetBusinessArea() {
	const location = useLocation();
	const [businessArea, setBusinessArea] = useState(
		location.pathname.split("/")[1]
	);
	useEffect(() => {
		setBusinessArea(location.pathname.split("/")[1]);
	}, [location.pathname.split("/")[1]]);
	return businessArea;
}

export default useGetBusinessArea;
