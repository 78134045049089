import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { CustomAccordion } from "..";
import {
  KeyboardArrowDownOutlinedIcon,
  KeyboardArrowUpOutlinedIcon,
} from "../../Assets";
import CustomAccordion from "../CustomAccordion/CustomAccordion";

import styles from "./ProjectDetailsCard.module.css";

const ProjectDetailCard = ({
  setAccordion,
  accordion,
  singleProjectDetailsdata,
}) => {
  const box = useRef();
  const [boxHeight, setBoxHeight] = useState("");

  useEffect(() => {
    setBoxHeight(box.current?.scrollHeight);
  }, []);

  const {
    biding_end_date,
    biding_start_date,
    description,
    title,
    project_start_date,
    proposals_count,
    services_count,
    services,
    created_at,
    address,
    state,
    zipcode,
    status,
    city,
  } = singleProjectDetailsdata;

  console.log("singleProjectDetailsdata", singleProjectDetailsdata);
  return (
    <>
      <div
        style={{
          maxHeight: !accordion ? boxHeight + "px" : "0px",
          transition: "max-height 0.3s ease-out",
        }}
        ref={box}
        className={styles.customAccordion}
      >
        <section className={styles.projectDetailsCard}>
          <div className={styles.projectDetails}>
            <div className={styles.projectDesc}>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Job Title
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {title}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Job Description & Requirements
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {description?.slice(0, 25)}....
                </Typography>
              </div>
            </div>
            <div className={styles.projectTime}>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Bidding Start Date
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {biding_start_date}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Bidding Closing Date
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {biding_end_date}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Project Start Date
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {project_start_date}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Job Status
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {status}
                </Typography>
              </div>
            </div>
            <div className={styles.projectAddress}>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Street
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {address}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  City
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {city?.label}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  State
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {state?.label}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: "small", color: "var(--textColor)" }}
                >
                  Zip Code
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {zipcode}
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.bidsCount}>
            <Box>
              <Typography variant="h5" fontWeight="bold">
                Bids
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: "var(--primary-color)" }}
              >
                {proposals_count}
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "100" }}>
                Total Received Bids
              </Typography>
            </Box>
          </div>
        </section>
      </div>
      <section className={styles.closeBtnWrapper}>
        {accordion && (
          <>
            <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>

            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Total Received Bids{" "}
              </Typography>
              <Typography
                sx={{ color: "var(--primary-color)" }}
                fontWeight="bold"
              >
                {proposals_count}
              </Typography>
            </Box>
          </>
        )}
        <div
          className={styles.closeBtn}
          onClick={() => {
            setAccordion(!accordion);
          }}
        >
          {accordion ? (
            <KeyboardArrowDownOutlinedIcon />
          ) : (
            <KeyboardArrowUpOutlinedIcon />
          )}
        </div>
      </section>
    </>
  );
};

export default ProjectDetailCard;
