import { TextField } from "@mui/material";
import React from "react";
import { Controller, useController } from "react-hook-form";

import styles from "./InputCmp.module.css";

const InputCmp = ({
	label,
	onChange,
	variant,
	style,
	placeholder,
	type,
	name,
	control,
	rules,
	...props
}) => {
	return (
		<>
			<Controller
				name={name}
				rules={rules}
				control={control ? control : "test"}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextField
						label={label ? label : "Outlined"}
						variant={variant ? variant : "outlined"}
						onChange={onChange}
						placeholder={placeholder}
						sx={{ width: "100%", ...style }}
						InputLabelProps={{
							shrink: true,
						}}
						type={type ? type : "text"}
						className={styles.inputBox}
						{...props}
						// {...field}
						value={value}
						error={!!error}
						helperText={error ? error.message : null}
					/>
				)}
			/>
		</>
	);
};

export default InputCmp;
