import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../Assets/Images/logo.png";
import Logo2 from "../../Assets/Images/logo2.png";
import BuildLinkLogo from "../../Assets/Images/BuildLinkCompleteLogo.png";
import BuildStudioLogo from "../../Assets/Images/BuildStudiologo-gold.png";

import { Stack, Typography } from "@mui/material";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import styles from "./SideBar.module.css";
import {
	DashboardOutlinedIcon,
	Briefcase,
	BriefcasePlus,
	CheckBox,
	MultiCheckBox,
	TrashSvg,
	GavelSvg,
	ClockCheckSvg,
	WhishListSvg,
	DashboardSvg,
	ProfileSvg,
	ProConnectionSvg,
	NewConnectionSvg,
	ConnectionRequestsSvg,
	ChatSvg,
	EyeSvg,
} from "../../Assets";
import useGetUserPermissions from "../../Hooks/getUserPermissions";
import useGetBusinessArea from "../../Hooks/getBusinessArea";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";

//NEW SIDEBAR
const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const SideBarNav = ({
	handleDrawerToggle,
	icon,
	sideBarClose,
	setSideBarClose,
}) => {
	const userPermissions = useGetUserPermissions();
	const businessArea = useGetBusinessArea();

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			<Drawer
				variant="permanent"
				open={sideBarClose}
			>
				{/* SIDEBAR BAR HEADER */}

				<SideBarHeader
					sideBarClose={sideBarClose}
					icon={icon}
					handleDrawerToggle={handleDrawerToggle}
				/>

				{/* main content */}

				{businessArea &&
					businessArea === "buildlink" &&
					userPermissions &&
					userPermissions.includes("Pro Connection - Connections") && (
						<BuildLinkProConnections sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildlink" &&
					userPermissions &&
					userPermissions.includes("Pro Connection - My Project Gallery") && (
						<BuildLinkMyProjectGallery sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildbid" &&
					userPermissions &&
					userPermissions.includes("Build Bid - Jobs") && (
						<BuildBidJobs sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildbid" &&
					userPermissions &&
					userPermissions.includes("Build Bid - My Bids") && (
						<BuildBidMyBids sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildstudio" &&
					userPermissions &&
					userPermissions.includes("Build Studio - Jobs") && (
						<BuildStudioOptions sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildshop" &&
					userPermissions &&
					userPermissions.includes("Shop - Products") && (
						<BuildShopProducts sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildshop" &&
					userPermissions &&
					userPermissions.includes("Shop - Orders") && (
						<BuildShopOrders sideBarClose={sideBarClose} />
					)}

				{businessArea &&
					businessArea === "buildinsurance" &&
					userPermissions &&
					userPermissions.includes("Linksurance - Orders") && (
						<InsuranceOptions sideBarClose={sideBarClose} />
					)}
			</Drawer>
		</Box>
	);
};

const BuildBidJobs = ({ sideBarClose }) => {
	let navigate = useNavigate();
	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<NavLink
				to="/buildbid/jobs"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Jobs"
					/>
					{/* {open ? <ExpandLess /> : <ExpandMore />} */}
				</ListItemButton>
			</NavLink>

			<List component="div">
				{[
					{
						icon: <BriefcasePlus />,
						text: "Create New Job",
						to: "buildbid/createjob",
					},
					{
						icon: <CheckBox />,
						text: "Awarded Jobs",
						to: "buildbid/awardedjob",
					},
					{
						icon: <MultiCheckBox />,
						text: "Completed Jobs",
						to: "buildbid/completedjob",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						to: "buildbid/trashjob",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>{list.icon}</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};

const BuildBidMyBids = ({ sideBarClose }) => {
	let navigate = useNavigate();
	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{" "}
			<NavLink
				to="/buildbid/mybids"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<GavelSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="My Bids"
					/>
					{/* {open ? <ExpandLess /> : <ExpandMore />} */}
				</ListItemButton>
			</NavLink>
			{/* <Collapse in={open} timeout="auto" unmountOnExit> */}
			<List component="div">
				{[
					{
						icon: <ClockCheckSvg />,
						text: "Applied Bids",
						onClick: () => navigate("/"),
						to: "buildbid/appliedprojects",
					},
					{
						icon: <CheckBox />,
						text: "Awarded Bids",
						onClick: () => navigate("/about"),
						to: "buildbid/awardedproject",
					},
					{
						icon: <MultiCheckBox />,
						text: "Completed Bids",
						onClick: () => navigate("/"),
						to: "buildbid/completedproject",
					},
					{
						icon: <WhishListSvg />,
						text: "Wish list",
						onClick: () => navigate("/"),
						to: "buildbid/wishlist",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>{list.icon}</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
			{/* </Collapse> */}
		</List>
	);
};

const BuildLinkProConnections = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* /// Jobs Listing// */}

			<NavLink
				to="/buildlink/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Pro Connection"
					/>
				</ListItemButton>
			</NavLink>
			<List component="div">
				{[
					{
						icon: <NewConnectionSvg />,
						text: "Search Connection",
						onClick: () => navigate("/about"),
						to: "buildlink/searchproconnection",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Pending Requests",
						onClick: () => navigate("/"),
						to: "buildlink/pendingrequests",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Recevied Requests",
						onClick: () => navigate("/"),
						to: "buildlink/receviedrequests",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};

const BuildLinkMyProjectGallery = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<NavLink
				to="/buildlink/myprojectgallery"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="My Project Gallery"
					/>
				</ListItemButton>
			</NavLink>
			<List component="div">
				{[
					{
						icon: <BriefcasePlus />,
						text: "Add New Project",
						onClick: () => navigate("/"),
						to: "buildlink/addnewproject",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "buildlink/trashgallery",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};
const BuildStudioOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? styles.activeClassName : undefined
        }
      >
        <ListItemButton
          sx={{ margin: "10px !important" }}
          className={styles.listBtn}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText className={styles.list} primary="Dashboard" />
        </ListItemButton>
      </NavLink>
      <Divider /> */}

			{/* /// Jobs Listing// */}

			<NavLink
				to="/buildstudio/jobs"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "8px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Jobs"
					/>
				</ListItemButton>
			</NavLink>
			<List component="div">
				{[
					{
						icon: <BriefcasePlus />,
						text: "Create New Project",
						// onClick: () => navigate("/createjob"),
						to: "buildstudio/createnewproject",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/about"),
						to: "buildstudio/trash",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};

const BuildShopProducts = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* /// Jobs Listing// */}

			<NavLink
				to="/buildshop/viewproduct"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "6px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Products"
					/>
				</ListItemButton>
			</NavLink>
			<List component="div">
				{[
					// {
					//   icon: <BriefcasePlus />,
					//   text: "View Products",
					//   // onClick: () => navigate("/createjob"),
					//   to: "/",
					// },
					// {
					//   icon: <EyeSvg />,
					//   text: "Edit Product",
					//   onClick: () => navigate("/about"),
					//   to: "/",
					// },
					{
						icon: <CheckBox />,
						text: "Add A New Product",
						onClick: () => navigate("/"),
						to: "buildshop/addproduct",
					},
					{
						icon: <CheckBox />,
						text: "Import Products",
						onClick: () => navigate("/"),
						to: "buildshop/importproduct",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "buildshop/trashedproducts",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};

const BuildShopOrders = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* /// Jobs Listing// */}

			<NavLink to="buildshop/orders/pending">
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<List>
				<NavLink
					to={`buildshop/orders/pending`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Pending Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`buildshop/orders/shipped`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Shipped Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`buildshop/orders/delivered`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Delivered Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`buildshop/orders/completed`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Completed Orders"}
						/>
					</ListItemButton>
				</NavLink>
			</List>
		</List>
	);
};

const InsuranceOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* /// Jobs Listing// */}

			<NavLink
				to="/buildinsurance/orders/general"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildinsurance/orders/Pending"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ pl: sideBarClose ? 4 : 2 }}
				>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="New Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildinsurance/orders/Accepted"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ pl: sideBarClose ? 4 : 2 }}
				>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="In Development"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildinsurance/orders/Completed"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ pl: sideBarClose ? 4 : 2 }}
				>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Completed"
					/>
				</ListItemButton>
			</NavLink>
		</List>
	);
};

const SideBarHeader = ({ sideBarClose, icon, handleDrawerToggle }) => {
	const businessArea = useGetBusinessArea();

	return (
		<Toolbar
			sx={{
				p: !sideBarClose ? "0 !important" : "auto",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{icon && sideBarClose && (
				<Stack
					component="div"
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
					sx={{ width: "100%" }}
				>
					{/* <Box
						p={"8px"}
						borderRight={"1px solid var(--borderColor)"}
					>
						<img
							src={Logo2}
							alt="icon"
							className={styles.menuIcon}
						/>
					</Box> */}

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "10px",
							width: "100%",
							padding: "0 8px",
						}}
					>
						{businessArea && businessArea === "buildlink" && (
							<img
								src={BuildLinkLogo}
								alt="icon"
								className={styles.menuIcon}
								width={100}
								height={40}
								style={{
									objectFit: "cover",
								}}
							/>
						)}
						{businessArea && businessArea === "buildstudio" && (
							<img
								src={BuildStudioLogo}
								alt="icon"
								className={styles.menuIcon}
								width={100}
								height={40}
								style={{
									objectFit: "cover",
								}}
							/>
						)}
						{businessArea && businessArea === "buildbid" && (
							<img
								src={Logo}
								alt="icon"
								className={styles.menuIcon}
								// width={105}
								// height={40}
								// style={{
								//   objectFit: "contain",
								// }}
							/>
						)}
						{businessArea && businessArea === "buildshop" && (
							<>
								<img
									src={BuildLinkLogo}
									alt="icon"
									className={styles.menuIcon}
									width={100}
									height={40}
									style={{
										objectFit: "cover",
									}}
								/>
							</>
						)}

						{businessArea && businessArea === "buildinsurance" && (
							<>
								<img
									src={BuildLinkLogo}
									alt="icon"
									className={styles.menuIcon}
									width={100}
									height={40}
									style={{
										objectFit: "cover",
									}}
								/>
							</>
						)}

						{businessArea &&
							(businessArea === "dashboard" || businessArea === "chat") && (
								<>
									<img
										src={BuildLinkLogo}
										alt="icon"
										className={styles.menuIcon}
										width={100}
										height={40}
										style={{
											objectFit: "cover",
										}}
									/>
								</>
							)}

						<MenuOpenIcon
							onClick={() => handleDrawerToggle()}
							className={styles.menuIcon}
						/>
					</Box>
				</Stack>
			)}
			{!sideBarClose && (
				<Typography
					variant="h6"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					noWrap
					component="div"
				>
					{!sideBarClose && (
						<IconButton
							onClick={handleDrawerToggle}
							sx={{
								display: {
									xs: "none",
									lg: "inline-block",
								},
							}}
						>
							<MenuOpenIcon />
						</IconButton>
					)}
				</Typography>
			)}
		</Toolbar>
	);
};

export default SideBarNav;
