import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
	BidIcon,
	BiEdit,
	LocationOnOutlinedIcon,
	RiDeleteBin6Line,
	EventAvailableOutlinedIcon,
	EventBusyOutlinedIcon,
} from "../../Assets";
import { postedDuration } from "../../Utils/utils";
import { DeleteJobAction } from "../../Store/Actions/BuildBidsJobAction";
import styles from "./ListView.module.css";
import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from "..";

const InsuranceListViewCard = ({ deleteBtn, color, path, order, setAlert }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const slug = "ad";

	const [paraLine, setParaLine] = useState(true);
	const [hover, setHover] = useState(false);

	const paraHandler = (e) => {
		e.stopPropagation();
		setParaLine(!paraLine);
	};

	const hoverHandler = (event) => {
		if (event == "enter") {
			setHover(true);
			return;
		}
		setHover(false);
	};

	const { requested_for_user, requested_by_user } = order;

	return (
		<section
			className={styles.jobCardWrapper}
			onMouseEnter={() => hoverHandler("enter")}
			onMouseLeave={() => hoverHandler("leave")}
			onClick={() => {
				navigate(`../orders/detail/${order.ref_number}`, { state: order });
			}}
		>
			<Box
				className={styles.cardHeader}
				sx={{ marginTop: "-20px" }}
				// onClick={() => navigate(`${path}/${slug}`)}
			>
				<Box>
					{/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
					<Typography
						variant="h5"
						sx={{ fontWeight: "bold", paddingTop: "-20" }}
					>
						{/* {title} */}
						{requested_for_user?.name}
					</Typography>
					{/* </Box> */}
				</Box>
			</Box>
			<section className={styles.headerList}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "8px",
						marginTop: "-20px",
						// my: "5px",
						// ml: index !== 0 && "20px",
					}}
					className={styles.jobDetails}
				>
					Verification Requested By {order.requested_by_user.company_name}
				</Box>
			</section>
			<section className={styles.headerList}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginRight: "8px",
						marginTop: "-20px",
						// my: "5px",
						// ml: index !== 0 && "20px",
					}}
					className={styles.jobDetails}
				>
					{order.status}
				</Box>
			</section>
			{/* <section className={styles.cardFooter}> */}
			<div>
				<Typography
					sx={{
						fontWeight: "bold",
						marginTop: "-10px",
					}}
				>
					{order.requested_for_user.certificates?.length} Certificates
				</Typography>
			</div>
			{/* </section> */}
		</section>
	);
};

export default InsuranceListViewCard;
