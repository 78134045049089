import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCmp, WhiteButtonCmp } from "..";
import { LocationOnOutlinedIcon, AccountAlert } from "../../Assets";

import ConnectionPic from "../../Assets/Images/connectionPic.png";
import useAlert from "../../Hooks/useAlert/useAlert";
import {
  RecievedConnectionRequestAction,
  EmptyRecievedConnectionRequestAction,
} from "../../Store/Actions/ProConnectionAction";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
import styles from "./ConnectionCard.module.css";

const ConnectionCard = ({ pending, open, setOpen, user, setUserDetails }) => {
  console.log("user", user);
  const [pendingRequest, setPendingRequest] = useState(false);
  const {
    name,
    image,
    ImgURL,
    email,
    services,
  } = user?.sender || {};
  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const cancelConnectionRequest = () => {
    dispatch(RecievedConnectionRequestAction(email, "Rejected", setAlert));
  };

  const acceptConnectionRequest = () => {
    dispatch(RecievedConnectionRequestAction(email, "Accepted", setAlert));
  };

  return (
    <section className={styles.connectionCard}>
      <div className={styles.connectionCardImg}>
        <img src={ImgURL + image} alt="" />
        {pending && (
          <div className={styles.threeDots}>
            <ThreeDotsMenu />
          </div>
        )}
      </div>
      <div className={styles.connectionCardName}>
        <Typography
          onClick={() => {
            setOpen(!open);
            setUserDetails(user?.sender);
          }}
          noWrap
          variant="h5"
          fontWeight={"bold"}
          sx={{ cursor: "pointer" }}
        >
          {name && name}
        </Typography>
        <Typography noWrap sx={{ fontSize: "14px", my: "5px" }}>
          Contractor
        </Typography>
        <Typography
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            justifyContent: "center",
            my: "5px",
          }}
        >
          <LocationOnOutlinedIcon sx={{ color: "var(--primary-color)" }} />
          New Orleans, LA
        </Typography>
      </div>
      <div className={styles.connectionCardTags}>
        {services && services?.length > 0 ? (
          <>
            <section className={styles.scrollTabsBar2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={AccountAlert} />
                <Typography sx={{ fontSize: "14px" }}>
                  {services[0].service.title}
                </Typography>
              </Box>
            </section>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                {services.length - 1} more...
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <section className={styles.scrollTabsBar2}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={AccountAlert} />
                <Typography sx={{ fontSize: "14px" }}>No service</Typography>
              </Box>
            </section>
          </>
        )}
      </div>
      <Box>
        <WhiteButtonCmp
          title="Reject"
          style={{
            width: "100%",
          }}
          onClick={cancelConnectionRequest}
        />
        <ButtonCmp
          title="Accept"
          style={{
            marginTop: "10px",
            width: "100%",
          }}
          onClick={acceptConnectionRequest}
        />
      </Box>
    </section>
  );
};

export default ConnectionCard;
