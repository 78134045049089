import React, { useEffect, useState } from "react";
import ProductTabPanel from "./ProductTabPanel";
import styles from "./AddProduct.module.css";
import { DropDownCmp } from "../../../Components";
import GridInputBox from "../../../Components/GridInputBox/GridInputBox";
import { useForm, Controller } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
	Box,
	MenuItem,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormGroup,
	InputLabel,
	Select,
	TextField,
	Button,
	Container,
	Grid,
	Typography,
	Divider,
	Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const PriceTab = (props) => {
	const {
		tabValue,
		control,
		watch,
		setValue,
		priceFields,
		priceAppend,
		priceRemove,
		priceReplace,
	} = props;
	const dispatch = useDispatch();

	const measurements = watch("measurements");
	const colors = watch("colors");
	const prices = watch("prices");

	const DEFAULT = "Default";

	// useEffect(() => {
	// 	let size_color = {
	// 		size: DEFAULT,
	// 		color: DEFAULT,
	// 		color_hex: "",
	// 		price: 0,
	// 		discount_price: 0,
	// 		quantity: 0,
	// 		model_no: "",
	// 		sku: "",
	// 		color_image: "",
	// 		available: true,
	// 		is_default: false,
	// 	};

	// 	priceAppend(size_color);
	// }, []);

	useEffect(() => {
		let size_color = {
			size: DEFAULT,
			color: DEFAULT,
			color_slug: DEFAULT,
			color_hex: "",
			price: 0,
			discount_price: 0,
			quantity: 0,
			model_no: "",
			sku: "",
			color_image: "",
			available: true,
			is_default: false,
		};

		if (
			(!colors || colors?.length === 0) &&
			(!measurements || measurements?.length === 0)
		) {
			priceReplace([size_color]);
		} else {
			const uniqueColors = new Set();

			prices?.forEach((obj) => {
				if (obj["color"] != DEFAULT) {
					const propertyValue = obj["color"];
					uniqueColors.add(propertyValue);
				}
			});

			const uniqueSizes = new Set();

			prices?.forEach((obj) => {
				if (obj["size"] != DEFAULT) {
					const propertyValue = obj["size"];

					uniqueSizes.add(propertyValue);
				}
			});

			if (colors?.length >= uniqueColors.size) {
				colors?.forEach((color) => {
					let foundColor = uniqueColors.has(color.color_title);
					if (!foundColor) {
						let newPrices = prices.filter((item) => item.color !== DEFAULT);
						if (measurements.length == 0) {
							let size_color = {
								size: DEFAULT,
								color: color.color_title,
								color_hex: color.color_code,
								color_slug: color.color_slug,
								price: 0,
								discount_price: 0,
								quantity: 0,
								model_no: "",
								sku: "",
								color_image: "",
								available: true,
								is_default: false,
							};

							newPrices.push(size_color);
						} else {
							measurements?.forEach((size) => {
								let size_color = {
									size: size.title,
									color: color.color_title,
									color_hex: color.color_code,
									color_slug: color.color_slug,
									price: 0,
									discount_price: 0,
									quantity: 0,
									model_no: "",
									sku: "",
									color_image: "",
									available: true,
									is_default: false,
								};

								newPrices.push(size_color);
							});
						}

						priceReplace(newPrices);
					}
				});
			} else {
				uniqueColors?.forEach((color) => {
					let index = colors?.findIndex((x) => x.color_title === color);
					if (index == -1) {
						let newPrices = prices.filter((item) => item.color !== color);
						if (colors?.length == 0) {
							measurements?.forEach((size) => {
								let size_color = {
									size: size.title,
									color: DEFAULT,
									color_slug: DEFAULT,
									color_hex: "",
									price: 0,
									discount_price: 0,
									quantity: 0,
									model_no: "",
									sku: "",
									color_image: "",
									color_image_src: "",
									available: true,
									is_default: false,
								};

								newPrices.push(size_color);
							});
						}
						priceReplace(newPrices);
					}
				});
			}

			if (measurements?.length >= uniqueSizes.size) {
				measurements?.forEach((size) => {
					let foundSize = uniqueSizes.has(size.title);
					if (!foundSize) {
						let newPrices = prices.filter((item) => item.size !== DEFAULT);
						if (colors.length == 0) {
							let size_color = {
								size: size.title,
								color_slug: DEFAULT,
								color_hex: "",
								color: DEFAULT,
								price: 0,
								discount_price: 0,
								quantity: 0,
								model_no: "",
								sku: "",
								color_image: "",
								available: true,
								is_default: false,
							};

							newPrices.push(size_color);
						} else {
							colors?.forEach((color) => {
								let size_color = {
									size: size.title,
									color: color.color_title,
									color_hex: color.color_code,
									color_slug: color.color_slug,
									price: 0,
									discount_price: 0,
									quantity: 0,
									model_no: "",
									sku: "",
									color_image: "",
									color_image_src: "",
									available: true,
									is_default: false,
								};

								newPrices.push(size_color);
							});
						}
						priceReplace(newPrices);
					}
				});
			} else {
				uniqueSizes?.forEach((size) => {
					let index = measurements?.findIndex((x) => x.label === size);
					if (index == -1) {
						let newPrices = prices.filter((item) => item.size !== size);
						if (measurements?.length === 0) {
							colors?.forEach((color) => {
								let size_color = {
									size: DEFAULT,
									color: color.color_title,
									color_hex: color.color_code,
									color_slug: color.color_slug,
									price: 0,
									discount_price: 0,
									quantity: 0,
									model_no: "",
									sku: "",
									color_image: "",
									color_image_src: "",
									available: true,
									is_default: false,
								};

								newPrices.push(size_color);
							});
						}

						priceReplace(newPrices);
					}
				});
			}
		}
	}, [colors, measurements]);

	const handleAvailable = (event, index) => {
		const updatedPrices = prices.map((item, i) => {
			if (i === index) {
				return {
					...item,
					available: event.target.checked,
				};
			}
			return item;
		});

		priceReplace(updatedPrices);
	};

	const handleIsDefault = (event, index) => {
		const updatedPrices = prices.map((item, i) => {
			if (i === index) {
				return {
					...item,
					is_default: true,
				};
			} else {
				return {
					...item,
					is_default: false,
				};
			}
		});

		priceReplace(updatedPrices);
	};

	return (
		<ProductTabPanel
			value={tabValue}
			index={3}
		>
			<Grid
				container
				columnSpacing={2}
				rowSpacing={5}
			>
				<Grid
					item
					lg={12}
				>
					<table className={styles.priceTable}>
						<thead>
							<tr>
								<th style={{ width: "160px", fontSize: "0.9rem" }}>Size</th>
								<th style={{ width: "250px", fontSize: "0.9rem" }}>Color</th>
								<th style={{ fontSize: "0.9rem" }}>Price</th>
								<th style={{ fontSize: "0.9rem" }}>Disc. Price</th>
								<th style={{ fontSize: "0.9rem" }}>Quantity</th>
								<th style={{ fontSize: "0.9rem" }}>Model No.</th>
								<th style={{ fontSize: "0.9rem" }}>SKU</th>
								<th style={{ fontSize: "0.9rem" }}>Color Image</th>
								<th style={{ width: "80px", fontSize: "0.9rem" }}>Active</th>
								<th style={{ width: "80px", fontSize: "0.9rem" }}>Default</th>
							</tr>
						</thead>
						<tbody>
							{prices?.map((price, index) => (
								<tr key={price.size + price.color}>
									<td>
										{price.size === DEFAULT ? (
											<span className={styles.defaultSize}></span>
										) : (
											<Box>
												<span
													className={styles[price.size?.replace(" ", "")]}
												></span>
												<span>{price.size}</span>
											</Box>
										)}
									</td>
									<td>
										{price.color === DEFAULT ? (
											<span className={styles.defaultColor}></span>
										) : (
											<Box>
												<span
													style={{
														width: "20px",
														height: "20px",
														backgroundColor: `${price.color_hex}`,
														paddingLeft: "20px",
														paddingRight: "20px",
														borderRadius: "5px",
														marginRight: "20px",
													}}
												></span>
												<span>{`${price.color}`}</span>
											</Box>
										)}
									</td>

									<td className={styles.amountColumn}>
										<GridInputBox
											name={`prices[${index}].price`}
											type="number"
											control={control}
										/>
									</td>
									<td className={styles.amountColumn}>
										{" "}
										<GridInputBox
											name={`prices[${index}].discount_price`}
											type="number"
											control={control}
										/>
									</td>
									<td className={styles.amountColumn}>
										{" "}
										<GridInputBox
											name={`prices[${index}].quantity`}
											type="number"
											control={control}
										/>
									</td>
									<td>
										{" "}
										<GridInputBox
											name={`prices[${index}].model_no`}
											type="text"
											control={control}
										/>
									</td>
									<td>
										{" "}
										<GridInputBox
											name={`prices[${index}].sku`}
											type="text"
											control={control}
										/>
									</td>
									<td>
										{" "}
										{price.color === DEFAULT ? (
											<span className={styles.defaultColor}></span>
										) : (
											<Box>
												{colors.find(
													(x) => x.color_slug == price.color_slug
												) && (
													<div>
														{colors.find(
															(x) => x.color_slug == price.color_slug
														)?.imageSrc == null ? (
															<p>No image</p>
														) : (
															<img
																src={
																	colors.find(
																		(x) => x.color_slug == price.color_slug
																	)?.imageSrc
																}
																alt="Thumbnail"
																style={{
																	maxWidth: "70px",
																	maxHeight: "70px",
																}}
															/>
														)}
													</div>
												)}
											</Box>
										)}
										{/* <Box>
											<div className={styles.colorImageContainer}>
												{prices[index].color_image_src && (
													<img
														src={prices[index]?.color_image_src}
														alt="Thumbnail"
														style={{
															maxWidth: "70px",
															maxHeight: "70px",
														}}
													/>
												)}

												<div>
													<Controller
														name={`prices[${index}].color_image`}
														control={control}
														defaultValue=""
														render={({ field }) => (
															<>
																<input
																	accept="image/*"
																	style={{ display: "none" }}
																	id={`prices-${index}-imgupload`}
																	type="file"
																	onChange={(e) => {
																		const file = e.target.files[0];

																		const reader = new FileReader();

																		watch(`prices[${index}].color_image`);
																		watch(`prices[${index}].color_image_src`);

																		setValue(
																			`prices[${index}].color_image`,
																			file
																		);
																		reader.onload = (event) => {
																			setValue(
																				`prices[${index}].color_image_src`,
																				event.target.result
																			);
																			// Set the image data to the form field
																		};

																		reader.readAsDataURL(file);
																	}}
																/>
																<label htmlFor={`prices-${index}-imgupload`}>
																	{prices[index].color_image_src ? (
																		<Button
																			sx={{
																				position: "absolute",
																				top: "50%",
																				left: "50%",

																				transform: "translate(-50%, -50%)",
																				backgroundColor: "rgba(0, 86, 153, 0)",
																				color: "rgba(255, 255, 255, 0)",
																				transition:
																					"background-color 0.3s ease, color 0.3s ease",
																				fontSize: "8px",
																				minWidth: "70px",
																				padding: "6px 10px ",
																				":hover": {
																					backgroundColor:
																						"rgba(0, 86, 153, 1)", // Semi-transparent green background on hover
																					color: "rgba(255, 255, 255, 1)", // Fully opaque white text on hover
																				},
																			}}
																			component="span"
																		>
																			Browse
																		</Button>
																	) : (
																		<Button
																			variant="contained"
																			color="buttonColor"
																			sx={{
																				position: "absolute",
																				top: "50%",
																				left: "50%",
																				transform: "translate(-50%, -50%)",
																				color: "white",
																				backgroundColor: "#005699",
																				fontSize: "8px",
																				minWidth: "70px",
																				padding: "6px 10px ",
																			}}
																			component="span"
																		>
																			Browse
																		</Button>
																	)}
																</label>
															</>
														)}
													/>
												</div>
											</div>
										</Box> */}
									</td>
									<td>
										<Switch
											checked={
												prices[index].available
													? prices[index].available
													: false
											}
											onChange={(event) => handleAvailable(event, index)}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</td>
									<td>
										<Switch
											checked={
												prices[index].is_default
													? prices[index].is_default
													: false
											}
											onChange={(event) => handleIsDefault(event, index)}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Grid>
				{/* <Grid
					item
					lg={4}
				>
					<DropDownCmp
						name="weight_unit"
						label="Weight Unit"
						control={control}
						options={weightunitdata && weightunitdata}
						disableClearable
						freeSolo={false}
					/>
				</Grid>
				<Grid
					item
					lg={4}
				>
					<DropDownCmp
						name="dimension_unit"
						label="Dimension Unit"
						control={control}
						options={dimensionunitdata && dimensionunitdata}
						disableClearable
						freeSolo={false}
					/>
				</Grid> */}
			</Grid>
		</ProductTabPanel>
	);
};

export default PriceTab;
