import React from "react";
import InsuranceListViewCard from "./InsuranceListViewCard";
import { BodyLayout } from "../../Components";
import { Box, Typography } from "@mui/material";
import { BASE_URL } from "../../Utils/response";
import { getUserToken } from "../../Utils/LoginAuth";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const InsuranceList = () => {
	const getHeader = () => {
		return {
			Authorization: "Bearer " + getUserToken(),
			"content-type": "multipart/form-data",
		};
	};
	const [orders, setOrders] = useState([]);
	const { status } = useParams();

	useEffect(() => {
		axios
			.get(`${BASE_URL}/user-verifications-orders?status=${status}`, {
				headers: getHeader(),
			})
			.then((response) => {
				setOrders(response.data.data);
			});
	}, [status]);
	return (
		<>
			<BodyLayout sideBar={false}>
				<section>
					{orders.records &&
						orders.records.map((order) => (
							<Box
								sx={{
									width: "100%",
									backgroundColor: "#f1f5f9",
									marginTop: "20px",
								}}
							>
								<InsuranceListViewCard order={order} />
							</Box>
						))}
				</section>
				<section>
					{!orders.records && (
						<Box
							sx={{
								width: "100%",
								backgroundColor: "#f1f5f9",
								marginTop: "20px",
							}}
						>
							<Typography
								fontSize="16px"
								sx={{ color: "var(--black)", fontWeight: "bold" }}
							>
								No orders right now
							</Typography>
						</Box>
					)}
				</section>
			</BodyLayout>
		</>
	);
};

export default InsuranceList;
