import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./BidsCard.module.css";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { LocationOnOutlinedIcon, VerifiedOutlinedIcon } from "../../Assets";
import { RatingCmp } from "..";
import TextTruncate from "react-text-truncate";
import { useNavigate } from "react-router-dom";

const BidsCard = ({
	setAccordion,
	accordion,
	bidsDetails,
	setBidsDetails,
	proposal,
	slug,
}) => {
	const navigate = useNavigate();

	const { bid_amount, user, proposal_bid_note, id, created_at } = proposal;
	console.log("proposal", proposal);
	return (
		<section
			className={styles.jobCardWrapper}
			onClick={() => {
				// setAccordion(!accordion);
				// setBidsDetails(!bidsDetails);
				navigate(`/buildbid/jobs/bid/${slug}/${id}`);
			}}
		>
			<Box className={styles.cardHeader}>
				<Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
						<Typography
							variant="h5"
							fontWeight="bold"
							className={styles.bidName}
						>
							{user?.name}.
						</Typography>
						{user?.is_verify === 1 && (
							<Typography
								fontSize={"15px"}
								sx={{ display: "flex", alignItems: "center", gap: 1 }}
							>
								<VerifiedOutlinedIcon sx={{ color: "var(--primary-color)" }} />
								Verified
							</Typography>
						)}
					</Box>
					<Box sx={{ margin: "10px 0 " }}>
						<Typography
							sx={{ fontSize: "small", display: "flex", alignItems: "center" }}
						>
							<LocationOnOutlinedIcon
								sx={{ color: "var(--primary-color)", ml: "-5px" }}
							/>
							{proposal?.user?.street_address
								? proposal?.user?.street_address
								: "312 Main Street, New Orleans LA, 70115"}
						</Typography>
					</Box>
					<Box sx={{ margin: "5px 0 ", display: "flex", gap: 1 }}>
						<Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
							Response Date :
						</Typography>
						<Typography sx={{ fontSize: "small" }}>{created_at}</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						// backgroundColor: "var(--light-green)",
						padding: "5px 10px",
						borderRadius: "5px",
						// color: "var(--green)",
					}}
					className={styles.cardSeriveWrapper}
				>
					<div>
						<Typography
							fontSize={"15px"}
							sx={{ textAlign: "right" }}
						>
							Bid Amount
						</Typography>
						<Typography
							variant="h4"
							fontWeight="bold"
							className={styles.bidName}
						>
							${bid_amount}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<RatingCmp ratingValue={user?.rating} />
						</Box>
					</div>
				</Box>
			</Box>

			<section className={styles.cardBody}>
				{/* <Typography sx={{ color: "var(--black)", fontSize: "medium" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat,
          accusantium velit officia sapiente tempore ab dolor distinctio
          delectus pariatur similique aliquam
        </Typography> */}
				<TextTruncate
					line={1}
					element="span"
					truncateText="…"
					text={proposal_bid_note}
					style={{ color: "var(--black)", fontSize: "medium" }}
				/>
			</section>
			<section className={styles.cardFooter}>
				<div className={styles.attactedFiles}>
					<InsertDriveFileOutlinedIcon fontSize={"small"} />
					03 Attach files
				</div>
			</section>
		</section>
	);
};

export default BidsCard;
