import {
  deleteMethodCustomHeader,
  getMethodCustomHeader,
  postMethodCustomHeader,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const SearchConnectionAction = (perPage, page, setAlert, filterObj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.SEARCH_CONNECTION_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `pro-connections?per_page=${perPage}&page=${page}`
      // );

      const response = await getMethodCustomHeader(
        `pro-connections?per_page=${perPage}&page=${page}&search=${
          filterObj?.searchByName || ""
        }&company_name=${filterObj?.companyName || ""}&location=${
          filterObj?.location || ""
        }&service=${filterObj?.service || ""}`
      );
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.SEARCH_CONNECTION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.SEARCH_CONNECTION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error?.response?.data.message, "errro");
      dispatch({
        type: ActionTypes.SEARCH_CONNECTION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const CreateConnectionAction = (email, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CREATE_CONNECTION_REQUEST,
      });
      const objToSend = {
        email,
      };
      const response = await postMethodCustomHeader(`connections`, objToSend);
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.CREATE_CONNECTION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.CREATE_CONNECTION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.CREATE_CONNECTION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const getPendingRequestsAction = (perPage, page, setAlert, searchParam) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PENDING_REQUEST_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/pending-connections-request?per_page=${perPage}&page=${page}`
      // );

      const response = await getMethodCustomHeader(
        `user/pending-connections-request?per_page=${perPage}&page=${page}&search=${
          searchParam || ""
        }`
      );
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PENDING_REQUEST_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_PENDING_REQUEST_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PENDING_REQUEST_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const getReceivedRequestsAction = (perPage, page, setAlert, searchParam) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_RECEIVED_REQUEST_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/received-connections-request?per_page=${perPage}&page=${page}`
      // );
      const response = await getMethodCustomHeader(
        `user/received-connections-request?per_page=${perPage}&page=${page}&search=${
          searchParam || ""
        }`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_RECEIVED_REQUEST_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_RECEIVED_REQUEST_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_RECEIVED_REQUEST_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const RecievedConnectionRequestAction = (email, status, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.RECIEVED_CONNECTION_REQUEST_REQUEST,
      });
      const objToSend = {
        email,
        status,
      };
      const response = await postMethodCustomHeader(
        `connection-request-action`,
        objToSend
      );
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.RECIEVED_CONNECTION_REQUEST_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.RECIEVED_CONNECTION_REQUEST_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.RECIEVED_CONNECTION_REQUEST_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const RemoveConnectionRequestAction = (email, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.REMOVE_CONNECTION_REQUEST_REQUEST,
      });
      const objToSend = {
        email,
      };
      console.log("objToSend", objToSend);
      const response = await deleteMethodCustomHeader(
        `user/remove-connection-request`,
        objToSend
      );
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.REMOVE_CONNECTION_REQUEST_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.REMOVE_CONNECTION_REQUEST_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.REMOVE_CONNECTION_REQUEST_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const getProConnectionsAction = (perPage, page, setAlert, searchParam) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PRO_CONNECTION_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/pro-connections?per_page=${perPage}&page=${page}`
      // );

      const response = await getMethodCustomHeader(
        `user/pro-connections?per_page=${perPage}&page=${page}&search=${
          searchParam || ""
        }`
      );
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PRO_CONNECTION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_PRO_CONNECTION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PRO_CONNECTION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const RemoveConnectionAction = (email, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.REMOVE_CONNECTION_REQUEST,
      });
      const objToSend = {
        email,
      };
      console.log("objToSend", objToSend);
      const response = await deleteMethodCustomHeader(
        `user/remove-connection`,
        objToSend
      );
      console.log("response pro connection", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.REMOVE_CONNECTION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.REMOVE_CONNECTION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.REMOVE_CONNECTION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EmptyCreateConnectionAction = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_CREATE_CONNECTION_FAIL,
    });
  };
};

const EmptyRecievedConnectionRequestAction = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_RECIEVED_CONNECTION_REQUEST_STATE,
    });
  };
};

const EmptyRemoveConnectionRequestAction = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_REMOVE_CONNECTION_REQUEST_STATE,
    });
  };
};

const EmptyRemoveConnectionAction = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_REMOVE_CONNECTION_STATE,
    });
  };
};

export {
  SearchConnectionAction,
  CreateConnectionAction,
  EmptyCreateConnectionAction,
  getPendingRequestsAction,
  getReceivedRequestsAction,
  RecievedConnectionRequestAction,
  EmptyRecievedConnectionRequestAction,
  RemoveConnectionRequestAction,
  EmptyRemoveConnectionRequestAction,
  getProConnectionsAction,
  RemoveConnectionAction,
  EmptyRemoveConnectionAction,
};
