import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import style from "./Alert.module.css";

export default function AlertCmp({ text, open, setOpen, error, styles }) {
  useEffect(() => {
    if (open) {
      var timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [open]);

  return (
    <section>
      <Box
        sx={{
          // width: "50%",
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          zIndex: 10,
        }}
        className={style.alertBoxWrapper}
      >
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              mb: 2,
              backgroundColor: error ? "var(--redColor)" : "var(--green)",
            }}
            variant="filled"
          >
            {text ? text : "Close me!"}
          </Alert>
        </Collapse>
      </Box>
    </section>
  );
}
