import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackDropCmp,
  ButtonCmp,
  DropDownCmp,
  MuiSelectBox,
  WhiteButtonCmp,
} from "../../Components";
import useAlert from "../../Hooks/useAlert/useAlert";
import {
  GetGlassTypesAction,
  GetBrandsAction,
  GetCategory,
  GetCoatsAction,
  GetColorsAction,
  GetDistributorsAction,
  GetMaterialsAction,
  GetPatternsAction,
  GetPrimersAction,
  GetSuppliersAction,
  GetSymbolAction,
} from "../../Store/Actions/GetActions";
import { BulkImportAction } from "../../Store/Actions/ManufacturerActions";
import MultiSelectBox from "./CheckMarkSelectBox";
import styles from "./Example.module.css";
import TableSelectOpt from "./TableSelectOpt";

const BulkImport = () => {
  const { control } = useForm();

  const location = useLocation();
  const { rows, columns } = location.state;

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  const { brandsLoading, brandsdata } = useSelector(
    (state) => state.BrandsReducers
  );
  const { colorsLoading, colorsdata } = useSelector(
    (state) => state.ColorsReducers
  );
  const { materialsLoading, materialsdata } = useSelector(
    (state) => state.MaterialsReducers
  );
  const { coatsLoading, coatsdata } = useSelector(
    (state) => state.CoatsReducers
  );
  const { primersLoading, primersdata } = useSelector(
    (state) => state.PrimersReducers
  );
  const { patternsLoading, patternsdata } = useSelector(
    (state) => state.PatternsReducers
  );
  const { symbolsLoading, symbolsdata } = useSelector(
    (state) => state.SymbolReducers
  );
  const { glassTypesLoading, glassTypesdata } = useSelector(
    (state) => state.GlassTypesReducers
  );
  const { categoriesLoading, categoriesdata } = useSelector(
    (state) => state.CategoriesReducers
  );
  const { distributorsLoading, distributorsdata } = useSelector(
    (state) => state.DistributorsReducers
  );
  const { suppliersLoading, suppliersdata } = useSelector(
    (state) => state.SuppliersReducers
  );

  const { productBulkImportLoading } = useSelector(
    (state) => state.ProductBulkImportReducer
  );

  const [productThumbnail, setProductThumbnail] = useState();
  const [productGallery, setProductGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  ////progess bar states
  useEffect(() => {
    dispatch(GetBrandsAction());
    dispatch(GetCategory());
    dispatch(GetColorsAction());
    dispatch(GetMaterialsAction());
    dispatch(GetCoatsAction());
    dispatch(GetPrimersAction());
    dispatch(GetDistributorsAction());
    dispatch(GetSuppliersAction());
    dispatch(GetSymbolAction());
    dispatch(GetPatternsAction());
    dispatch(GetGlassTypesAction());
  }, []);

  useEffect(() => {
    console.log("kitni dafa chala");
    setTableData(rows);
  }, []);
  console.log("kitni dafa chala", tableData);

  const productThumbnailHandler = (e, index) => {
    // setProductThumbnail({ is_thumbnail: true, image: e.target.files[0] });

    if (tableData[index]["images"]?.length > 0) {
      console.log("if chala bhai");
      tableData[index]["images"] = [
        ...tableData[index]["images"],
        { is_thumbnail: true, image: e.target.files[0] },
      ];
    } else {
      console.log("else chala bhai");

      tableData[index]["images"] = [];
      tableData[index]["images"] = [
        ...tableData[index]["images"],
        { is_thumbnail: true, image: e.target.files[0] },
      ];
    }

    // console.log(tableData, index);
    setTableData([...tableData]);
  };

  const productGalleryHandler = (e, index) => {
    const imagesArray = Array.from(e.target.files);

    const multiImages = imagesArray.map((img) => {
      return { is_thumbnail: false, image: img };
    });

    if (tableData[index]["images"]?.length > 0) {
      console.log("if chala bhai");
      tableData[index]["images"] = [
        ...tableData[index]["images"],
        ...multiImages,
      ];
    } else {
      console.log("else chala bhai");

      tableData[index]["images"] = [];
      tableData[index]["images"] = [
        ...tableData[index]["images"],
        ...multiImages,
      ];
    }
    // setProductGallery([...productGallery, ...multiImages]);
  };

  const removeProductHandler = (ind) => {
    tableData.splice(ind, 1);
    setTableData([...tableData]);
  };

  const addValueInTableData = (index, value, fieldName) => {
    // setTableData
    tableData[index][fieldName] = value;
    // setTableData([...tableData]);
  };

  const bulkImportHandler = () => {
    setBulkImportData([...tableData]);

    // dispatch(BulkImportAction(tableData));
  };

  const [bulkImportData, setBulkImportData] = useState([]);
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  useEffect(() => {
    if (bulkImportData.length > 0) {
      dispatch(BulkImportAction(tableData, setAlert, navigate));
    }
  }, [bulkImportData]);

  const [rowIndexNumber, setRowIndexNumber] = useState("");
  const [colIndexNumber, setColIndexNumber] = useState("");
  const editTableRowData = (rowIndex, colIndex, e) => {
    setRowIndexNumber(rowIndex);
    setColIndexNumber(colIndex);
  };

  return (
    <div className={styles.table_wrapper}>
      <table border={"1px"} className={styles.fl_table}>
        <thead>
          <tr>
            <th>Product Delete</th>
            {columns?.map((th) => {
              return <th key={th.accessor}>{th.Header}</th>;
            })}
            <th>Brand Name</th>
            <th>Category</th>
            <th>Color</th>
            <th>Material</th>
            <th>Distributor</th>
            <th>Supplier</th>
            <th>First Coat</th>
            <th>Second Coat</th>
            <th>Primer</th>
            <th>Symbols</th>
            <th>Product Thumbnail</th>
            <th>Product Gallary</th>
            <th>Pattern</th>
            <th>Glass Type</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((row, index) => {
            if (loading) {
              setLoading(false);
            }
            return (
              <tr key={index}>
                <td>
                  <RiDeleteBin6Line
                    color="var(--redColor)"
                    size="20px"
                    style={{ cursor: "pointer" }}
                    onClick={() => removeProductHandler(index)}
                  />
                </td>

                {columns.map((col, ind) => {
                  return (
                    <td
                      key={col.accessor}
                      onClick={(e) => {
                        editTableRowData(index, ind, e);
                      }}
                    >
                      {/* {index === rowIndexNumber && ind === colIndexNumber ? (
                        <input
                          value={row[col.accessor]}
                          className={styles.editInput}
                        /> */}
                      {/* ) : ( */}
                      {row[col.accessor]}
                      {/* )} */}
                    </td>
                  );
                })}

                {/* //dynamic fields */}
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    label="Brand Name"
                    options={brandsdata}
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "brand_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    options={categoriesdata}
                    style={{ height: "50px" }}
                    label="Category"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "category_id");
                    }}
                  />
                </td>
                <td>
                  <MultiSelectBox
                    style={{ height: "50px" }}
                    options={colorsdata}
                    label="Color"
                    onChange={(values) => {
                      addValueInTableData(index, values, "colors");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    options={materialsdata}
                    label="Material"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "material_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    label="Distributor"
                    options={distributorsdata}
                    onChange={(e) => {
                      addValueInTableData(
                        index,
                        e.target.value,
                        "distributor_id"
                      );
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    options={suppliersdata}
                    label="Supplier"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "supplier_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    options={coatsdata}
                    label="First Coat"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "coat_one_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    label="Second Coat"
                    options={coatsdata}
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "coat_two_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    options={primersdata}
                    label="Primer"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "primer_id");
                    }}
                  />
                </td>
                <td>
                  <MultiSelectBox
                    style={{ height: "50px" }}
                    label="Symbols"
                    options={symbolsdata}
                    onChange={(values) => {
                      addValueInTableData(index, values, "symbols");
                    }}
                  />
                </td>
                <td>
                  <input
                    type="file"
                    onChange={(e) => productThumbnailHandler(e, index)}
                  />
                </td>
                <td>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => productGalleryHandler(e, index)}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    options={patternsdata}
                    style={{ height: "50px" }}
                    label="Pattern"
                    onChange={(e) => {
                      addValueInTableData(index, e.target.value, "pattern_id");
                    }}
                  />
                </td>
                <td>
                  <TableSelectOpt
                    style={{ height: "50px" }}
                    label="Glass Type"
                    options={glassTypesdata}
                    onChange={(e) => {
                      addValueInTableData(
                        index,
                        e.target.value,
                        "glass_type_id"
                      );
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          px: "20px",
          mt: "10px",
        }}
      >
        <WhiteButtonCmp
          title={"Cancel"}
          onClick={() => navigate("/manufacturer/viewproduct")}
        />
        <ButtonCmp title="Bulk Import" onClick={bulkImportHandler} />
      </Box>

      <BackDropCmp
        setOpen={
          brandsLoading ||
          colorsLoading ||
          materialsLoading ||
          coatsLoading ||
          primersLoading ||
          patternsLoading ||
          glassTypesLoading ||
          distributorsLoading ||
          suppliersLoading ||
          categoriesLoading ||
          symbolsLoading ||
          loading ||
          productBulkImportLoading
        }
      />
    </div>
  );
};

export default BulkImport;
