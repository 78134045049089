import { Typography, Box } from "@mui/material";
import React, { useState } from "react";
import TextTruncate from "react-text-truncate";

import styles from "./ProjectSideBar.module.css";

const ProjectSideBar = ({ project }) => {
  const [paraLine, setParaLine] = useState(true);

  const paraHandler = (e) => {
    e.stopPropagation();
    setParaLine(!paraLine);
  };

  const {
    label,
    address,
    zipcode,
    biding_end_date,
    biding_start_date,
    description,
    state,
    city,
    status
  } = project;

  return (
    <section className={styles.sideBarWrapper}>
      <div className={styles.myJobHeader}>
        <div>
          <Typography variant="h6" fontWeight={"bold"}>
            Project Details
          </Typography>
        </div>
      </div>

      <Box className={styles.project_wrapper}>
        <section>
          <Typography sx={{ color: "var(--textColor)" }}>Job Title</Typography>
          <Typography>{label}</Typography>
        </section>
        <Box className={styles.address_wrapper}>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>Street</Typography>
            <Typography>{address}</Typography>
          </section>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>City</Typography>
            <Typography>{city.label}</Typography>
          </section>
        </Box>
        <Box className={styles.address_wrapper}>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>State</Typography>
            <Typography>{state.label}</Typography>
          </section>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>Zip Code</Typography>
            <Typography>{zipcode}</Typography>
          </section>
        </Box>
        <Box className={styles.address_wrapper}>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>
              Bidding Start Date
            </Typography>
            <Typography>{biding_start_date}</Typography>
          </section>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>
              Bidding Closing Date
            </Typography>
            <Typography>{biding_end_date}</Typography>
          </section>
        </Box>
        <Box className={styles.desc_wrapper}>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>
              Job Description & Requirements
            </Typography>
            <TextTruncate
              line={paraLine && 1}
              element="span"
              truncateText="…"
              text={description}
              style={{ color: "var(--textColor)", fontSize: "medium" }}
            />
            <small className={styles.paraMoreBtn} onClick={paraHandler}>
              {paraLine ? "More" : "Less"}
            </small>
          </section>
        </Box>
        <Box className={styles.desc_wrapper}>
          <section>
            <Typography sx={{ color: "var(--textColor)" }}>
              Job Status
            </Typography>
            <Typography>{status}</Typography>
          </section>
        </Box>
      </Box>
    </section>
  );
};

export default ProjectSideBar;
