import ActionTypes from "../../Constants";

const INITIALSTATE = {
  PDFRenameLoading: false,
  PDFRenamedata: false,

  //pdf remove
  PDFRemoveLoading: false,
  PDFRemovedata: false,
};

const PDFRenameReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.PDF_RENAME_REQUEST:
      return {
        ...state,
        PDFRenameLoading: true,
      };
    case ActionTypes.PDF_RENAME_SUCCESS:
      return {
        ...state,
        PDFRenameLoading: false,
        PDFRenamedata: !state.PDFRenamedata,
      };
    case ActionTypes.PDF_RENAME_FAIL:
      return {
        ...state,
        PDFRenameLoading: false,
      };
    default:
      return state;
  }
};

const PDFRemoveReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.PDF_REMOVE_REQUEST:
      return {
        ...state,
        PDFRemoveLoading: true,
      };
    case ActionTypes.PDF_REMOVE_SUCCESS:
      return {
        ...state,
        PDFRemoveLoading: false,
        PDFRemovedata: !state.PDFRemovedata,
      };
    case ActionTypes.PDF_REMOVE_FAIL:
      return {
        ...state,
        PDFRemoveLoading: false,
      };
    default:
      return state;
  }
};

export { PDFRenameReducer, PDFRemoveReducer };
