import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";

import styles from "./BidsGridCard.module.css";
import { useNavigate } from "react-router-dom";
import {
  LottieFilesCmp,
} from "../../Components";
import {
  AppliedIcon,
  FavoriteBorderOutlinedIcon,
  LocationOnOutlinedIcon,
  EmptyAnimation
} from "../../Assets";
import { IconCmp } from "..";
import useAlert from "../../Hooks/useAlert/useAlert";
import { MyBidsAddToWishList } from "../../Store/Actions/BuildBidsMyBids";

const BidsGridCard = ({ whishList, applied, path, myBidsProjectdata }) => {
  return (
    <>
      {
        myBidsProjectdata &&
          myBidsProjectdata?.records?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <LottieFilesCmp animation={EmptyAnimation} />
            <Typography variant="h5" fontWeight="bold">
              No Jobs Found
            </Typography>
          </Box>

        ) : (
          <Grid container className={styles.jobGridCardWrapper}>
            {myBidsProjectdata.records.map((jobs, index) => {
              return (

                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    whishList={whishList}
                    color={index % 2 == 0 ? "white" : "var(--bgColor)"}
                    applied={applied}
                    data={myBidsProjectdata}
                    path={`${path}/${jobs.slug}`}
                    myBidsProjectdata={jobs}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
    </>
  );
};

const Card = ({ color, whishList, applied, path, myBidsProjectdata, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const {
    label,
    created_at,
    address,
    zipcode,
    biding_end_date,
    biding_start_date,
    services_count,
    description,
    services,
    is_in_wishlist,
  } = myBidsProjectdata;

  const handleWishlistClick = (e) => {
    e.stopPropagation();
    // setisInWishlist(!isInWishlist);
    dispatch(MyBidsAddToWishList(myBidsProjectdata.slug, data, "", setAlert));
  };


  return (
    <div className={styles.gridviewCardWrapper} onClick={() => navigate(path)}>
      <div className={styles.cardInfo}>
        <section className={styles.cardInfoDetails}>
          <Typography variant="h5" className={styles.jobName}>
            {label}
          </Typography>
          <Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
            Posted -
            {" " + moment(created_at).startOf("data").fromNow(created_at) + " "}
            ago
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}
          >
            <LocationOnOutlinedIcon
              fontSize="10px"
              sx={{
                marginRight: "5px",
                ml: "-3px",
                color: "var(--primary-color)",
              }}
            />
            <small>{address + "," + zipcode}</small>
          </Box>
        </section>
        {/* <section className={styles.cardInfoService}>
          <Box
            sx={{
              backgroundColor: "var(--light-green)",
              padding: "5px 10px",
              borderRadius: "5px",
              color: "var(--green)",
            }}
            alignSelf={"flex-end"}
            className={styles.cardSeriveWrapper}
          >
            <div>
              <Typography fontSize={"12px"}>06 Services required</Typography>
            </div>
          </Box>
        </section> */}
        {applied ? (
          <Box className={styles.whishList}>
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>Applied</Typography>
            </Box>
            <IconCmp style={{ background: "var(--green)" }}>
              {/* <AppliedIcon sx={{ color: "white" }} /> */}
              <img src={AppliedIcon} width="20" alt="" />
            </IconCmp>
          </Box>
        ) : is_in_wishlist ? (
          <Box className={styles.whishList} onClick={handleWishlistClick}>
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>
                Remove from Whish List
              </Typography>
            </Box>
            <IconCmp style={{ background: "var(--green)" }}>
              <FavoriteBorderOutlinedIcon sx={{ color: "white" }} />
            </IconCmp>
          </Box>
        ) : (
          <Box className={styles.whishList} onClick={handleWishlistClick}>
            <Box sx={{ mr: "15px" }}>
              <Typography fontSize={"medium"}>Save To Whish List</Typography>
            </Box>
            <IconCmp style={{ background: color }}>
              <FavoriteBorderOutlinedIcon />
            </IconCmp>
          </Box>
        )}
      </div>
      <Box className={styles.cardInfoDate2}>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Create Date</td>
                <td>:</td>
                <td>{biding_start_date}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Bids Closing Date</td>
                <td>:</td>
                <td>{biding_end_date}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </div>
  );
};

export default BidsGridCard;
