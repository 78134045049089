import { Routes, Route, Link } from "react-router-dom";
import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

import {
	About,
	CreateJob,
	Home,
	Jobs,
	ProjectDetail,
	TrashJob,
	CompletedJob,
	JobRating,
	AwardedJob,
	AwardedJobDetail,
	MyBids,
	AwardedProject,
	AwardedBidDetail,
	WishList,
	MyBidDetail,
	AppliedProjects,
	SubmitedBidDetails,
	CompletedProject,
	Profile,
	MyProjectGallery,
	TrashGallery,
	ProConnection,
	SearchProConnection,
	AddNewProject,
	ManufacturerViewProduct,
	AddManufacturerProduct,
	ImportProduct,
	ManufacturerTrashProduct,
	Chat,
	ViewProductDetail,
	SingleBidDetails,
	UpdateJob,
	UpdateAddNewProject,
	PendingConnectionRequests,
	ReceviedConnectionRequests,
	Projects,
	JobsDetail,
	CreateNewProject,
	EditPDF,
	BSProjectTrash,
	EditBSProject,
	BidderRating,
	UserProfile,
	InsuranceDocument,
} from "../../Pages";

import { Layout } from "../../Components";
import BulkImport from "../../Pages/BulkImport/BulkImport";
import AddProductMain from "../../Pages/Product/AddProduct/ProductMain";
import EditProductMain from "../../Pages/Product/EditProduct/ProductMain";
import ViewProductDetails from "../../Pages/Product/ViewProductDetails/ViewProductDetails";
import OrderListing from "../../Pages/Order/OrderListing/OrderListing";
import OrderDetail from "../../Pages/Order/OrderDetail/OrderDetail";
import InsuranceDetail from "../../Pages/Insurance/Detail";
import InsuranceList from "../../Pages/Insurance/InsuranceList";

const theme = createTheme({
	palette: {
		buttonColor: {
			// Purple and green play nicely together.
			main: "#005699",
			contrastText: "#fff",
		},
		successBtn: {
			// Purple and green play nicely together.
			main: "var(--green)",
			contrastText: "#fff",
		},
		buttonWhite: {
			// Purple and green play nicely together.
			main: "#fff",
			contrastText: "#111827",
			// color: "black !important",
			border: "1px solid black !important",
		},
	},
});

const AppRoutes = ({ profileDetails }) => {
	const { all_roles } = profileDetails || [];
	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Suspense fallback={<div>LOADING....</div>}>
					<Routes>
						<Route
							path="dashboard"
							element={<Home />}
						/>
						<Route
							path="profile"
							element={<Profile />}
						/>
						{all_roles && all_roles.includes("Chat") && (
							<>
								<Route
									path="chat"
									element={<Chat />}
								/>
							</>
						)}
						{all_roles &&
							all_roles.includes("Pro Connection - My Project Gallery") && (
								<Route path="/buildlink">
									<Route
										path="userprofile/:useremail"
										element={<UserProfile />}
									/>
									<Route
										path="myprojectgallery"
										element={<MyProjectGallery />}
									/>
									<Route
										path="addnewproject"
										element={<AddNewProject />}
									/>
									<Route
										path="updatenewproject/:projectSlug"
										element={<UpdateAddNewProject />}
									/>
									<Route
										path="trashgallery"
										element={<TrashGallery />}
									/>
								</Route>
							)}
						{all_roles &&
							all_roles.includes("Pro Connection - Connections") && (
								<>
									<Route path="/buildlink">
										<Route
											path="proconnection"
											element={<ProConnection />}
										/>
										<Route
											path="searchproconnection"
											element={<SearchProConnection />}
										/>
										<Route
											path="pendingrequests"
											element={<PendingConnectionRequests />}
										/>
										<Route
											path="receviedrequests"
											element={<ReceviedConnectionRequests />}
										/>
									</Route>
								</>
							)}
						{all_roles && all_roles.includes("Build Bid - Jobs") && (
							<Route path="/buildbid">
								<Route
									path="jobs"
									element={<Jobs />}
								/>
								<Route
									path="jobs/projectdetail/:slug"
									element={<ProjectDetail />}
								/>
								<Route
									path="jobs/bid/:projectslug/:bidId"
									element={<SingleBidDetails />}
								/>
								<Route
									path="createjob"
									element={<CreateJob />}
								/>
								<Route
									path="updatejob/:projectSlug"
									element={<UpdateJob />}
								/>
								<Route
									path="trashjob"
									element={<TrashJob />}
								/>
								<Route
									path="completedjob"
									element={<CompletedJob />}
								/>
								<Route
									path="completedjob/jobrating/:projectSlug"
									element={<JobRating />}
								/>
								<Route
									path="awardedjob"
									element={<AwardedJob />}
								/>
								<Route
									path="awardedjob/awardedjobdetail/:projetslug"
									element={<AwardedJobDetail />}
								/>
							</Route>
						)}
						{all_roles && all_roles.includes("Build Bid - My Bids") && (
							<Route path="/buildbid">
								{" "}
								<Route
									path="mybids"
									element={<MyBids />}
								/>
								<Route
									path="awardedproject"
									element={<AwardedProject />}
								/>
								<Route
									path="awardedproject/awardedprojectdetails/:projetslug"
									element={<AwardedBidDetail />}
								/>
								<Route
									path="wishlist"
									element={<WishList />}
								/>
								<Route
									path="mybids/biddetails/:slug"
									element={<MyBidDetail />}
								/>
								<Route
									path="appliedprojects"
									element={<AppliedProjects />}
								/>
								<Route
									path="appliedprojects/submitedbiddetails/:slug/:bidId"
									element={<SubmitedBidDetails />}
								/>
								<Route
									path="completedproject"
									element={<CompletedProject />}
								/>
								<Route
									path="completedproject/projectrating/:projectSlug"
									element={<BidderRating />}
								/>
							</Route>
						)}
						{all_roles && all_roles.includes("Shop - Orders") && (
							<Route path="/buildshop">
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
							</Route>
						)}{" "}
						{all_roles && all_roles.includes("Shop - Products") && (
							<Route path="/buildshop">
								<Route
									path="viewproduct"
									element={<ManufacturerViewProduct />}
								/>
								<Route
									path="editproduct/:productSlug"
									element={<EditProductMain />}
								/>
								<Route
									path="viewproduct/viewproductdetail/:productSlug"
									element={<ViewProductDetails />}
								/>
								<Route
									path="addproduct"
									element={<AddProductMain />}
								/>
								<Route
									path="importproduct"
									element={<ImportProduct />}
								/>
								<Route
									path="trashedproducts"
									element={<ManufacturerTrashProduct />}
								/>
								<Route
									path="bulkimport"
									element={<BulkImport />}
								/>
							</Route>
						)}
						{all_roles && all_roles.includes("Build Studio - Jobs") && (
							<Route path="buildstudio">
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
								<Route
									path="jobs"
									element={<Projects />}
								/>
								<Route
									path="jobs/jobdetail/:projectID"
									element={<JobsDetail />}
								/>
								<Route
									path="createnewproject"
									element={<CreateNewProject />}
								/>
								<Route
									path="editpdf/:projectID"
									element={<EditPDF />}
								/>
								<Route
									path="trash"
									element={<BSProjectTrash />}
								/>
								<Route
									path="editbsproject/:projectSlug"
									element={<EditBSProject />}
								/>
							</Route>
						)}
						{all_roles && all_roles.includes("Linksurance - Orders") && (
							<Route path="buildinsurance">
								{/* <Route
								path="insurancragent"
								element={<InsuranceProfile />}
							/> */}

								<Route
									path="orders/detail/:orderId"
									element={<InsuranceDetail />}
								/>
								<Route
									path="orders/:status"
									element={<InsuranceList />}
								/>

								{/* 
								<Route
									path="orders/general"
									element={<InsuranceGeneralOrderDetail />}
								/> */}
								{/* <Route
									path="orders/details"
									element={<InsuranceGeneralOrder />}
								/> */}
								{/* <Route
								path="linkSurance"
								element={<LinkSurance />}
							/> */}
								{/* <Route
									path="insuranceDocuments"
									element={<InsuranceDocument />}
								/> */}
							</Route>
						)}
						<Route
							path="*"
							element={<h1>You do not have permission for this Feature</h1>}
						/>
					</Routes>
				</Suspense>
			</Layout>
		</ThemeProvider>
	);
};

export default AppRoutes;
