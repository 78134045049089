import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ButtonCmp, WhiteButtonCmp } from "../../../Components";
import { useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo  from "../../../Assets/Images/BuildLinkCompleteLogo.png"

const drawerWidth = 240;

function Navbar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const navItems = [
    {
      title: "Home",
      onClick: () => navigate("/"),
    },
    {
      title: "About",
      onClick: () => navigate("/aboutpage"),
    },
    {
      title: "Contact",
      onClick: () => navigate("/contact"),
    },
  ];
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "white" }}
    >              <img
    width="152"
    
    src={Logo}
    
  />
      <Typography variant="h6" sx={{ my: 2 }}>
        BUILD BIDS
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} onClick={item.onClick} />
            </ListItemButton>
          </ListItem>
        ))}
{
            <ListItem  disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <a href="https://shop.buildlink.com"><ListItemText primary="Shop"  /></a>
            </ListItemButton>
          </ListItem>
}
      </List>
    </Box>
  );

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    console.log("changeBackground");
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window?.addEventListener("scroll", changeBackground);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          background: "white",
          p: 3,
          py: navbar ? 2 : 3,
          boxShadow: navbar ? "0 13px 35px -12px rgba(35,35,35,.1)" : "none",
          transition: "all .4s ease-out",
          position: "fixed",
          top: 0,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            // background: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 2,
              //   display: { xs: "none", sm: "block" },
              display: "block",
              color: "#000",
              //   background: "yellow",
            }}
          >              <img
          width="152"
        
          src={Logo}
          alt="logo"
        />
           
          </Typography>

          {/* mobile screen toggle button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: "auto", display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
              //   background: "orange",
            }}
          >
            {navItems.map((item) => (
              <Button
                onClick={item.onClick}
                key={item.title}
                sx={{ color: "#000" }}
              >
                {item.title}
              </Button>
            ))}
            <a href="https://shop.buildlink.com">
             <Button
                
          
                sx={{ color: "#000" }}
              >
                Shop
              </Button></a>
            <Box sx={{ ml: "auto" }}>
              <Button sx={{ color: "#000" }} onClick={() => navigate("/login")}>
                Login
              </Button>
              <ButtonCmp title="GET STARTED" />
            </Box>
          </Box>
          {/* <WhiteButtonCmp title="LOGIN" /> */}
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
