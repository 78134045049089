import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton } from "@mui/material";
import { AppsOutlinedIcon, DashboardOutlinedIcon } from "../../Assets";

import styles from "./AppSwitcher.module.css";
import createJob from "../../Assets/Icons/createJob.png";

export default function AppSwitcher() {
	const [state, setState] = React.useState(false);

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState(!state);
	};

	const list = () => (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				<ListItemButton
					className={styles.listBtn}
					sx={{ pl: 4 }}
				>
					<ListItemIcon sx={{ minWidth: "35px" }}>
						<DashboardOutlinedIcon />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary={"Dashboard"}
					/>
				</ListItemButton>
			</List>
			<Divider />
			<List>
				{[
					{
						icon: createJob,
						text: "Create New Job",
						// onClick: () => navigate("/createjob"),
						to: "createjob",
					},
					{
						icon: createJob,
						text: "Awarded Jobs",
						// onClick: () => navigate("/about"),
						to: "awardedjob",
					},
					{
						icon: createJob,
						text: "Completed Jobs",
						// onClick: () => navigate("/"),
						// to: "completedjob",
						to: "/",
					},
				].map((list, index) => (
					//     <NavLink
					//     to={`/${list.to}`}
					//     className={({ isActive }) =>
					//       isActive ? styles.activeClassName : undefined
					//     }
					//     key={index}
					//   >
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: 4 }}
						key={index}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "35px" }}>
							<img
								src={list.icon}
								alt="icon"
							/>
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={list.text}
						/>
					</ListItemButton>
					//   </NavLink>
				))}
			</List>
		</Box>
	);

	return (
		<div>
			<Drawer
				open={state}
				onClose={toggleDrawer(false)}
			>
				{list()}
			</Drawer>
			<IconButton
				color="inherit"
				aria-label="open drawer"
				edge="start"
				sx={{ mr: 2, display: { lg: "none" } }}
				onClick={toggleDrawer(true)}
			>
				<AppsOutlinedIcon sx={{ color: "var(--textColor)" }} />
			</IconButton>
		</div>
	);
}
