import useAlert from "../../Hooks/useAlert/useAlert";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import style from "./AlertPopup.module.css";

const AlertPopup = () => {
  const { text, type } = useAlert();
  if (text && type) {
    return (
      <Box
        sx={{
          zIndex: 10000,
        }}
        className={style.alertBoxWrapper}
      >
        <Alert
          // action={
          //   <IconButton aria-label="close" color="inherit" size="small">
          //     <CloseIcon fontSize="inherit" />
          //   </IconButton>
          // }
          sx={{
            mb: 2,
            backgroundColor:
              type === "error" ? "var(--redColor)" : "var(--green)",
          }}
          variant="filled"
        >
          {text ? text : "Close me!"}
        </Alert>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
