import {
	BodyLayout,
	ButtonCmp,
	WhiteButtonCmp,
	LoaderCmp,
} from "../../../Components";
import { Grid, Typography, Box } from "@mui/material";
import ProductTabs from "./ProductTabs";
import styles from "./AddProduct.module.css";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddProductAction } from "../../../Store/Actions/ManufacturerActions";
import useAlert from "../../../Hooks/useAlert/useAlert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserToken } from "../../../Utils/LoginAuth";
import React, { useState, useEffect } from "react";
import useGetUserRole from "../../../Hooks/getUserRole";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "../../../Config";
import { BASE_URL } from "../../../Utils/response";

const ProductMain = () => {
	const { setAlert } = useAlert();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formValidation = validation.productValidation;
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		handleSubmit,
		watch,
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(formValidation),
	});

	const {
		fields: colorFields,
		append: colorAppend,
		remove: colorRemove,
	} = useFieldArray({
		control,
		name: "colors",
		defaultValue: [],
	});

	const {
		fields: imageFields,
		append: imageAppend,
		remove: imageRemove,
		replace: imageReplace,
	} = useFieldArray({
		control,
		name: "images",
		defaultValue: [],
	});

	const {
		fields: measurementsFields,
		append: measurementsAppend,
		remove: measurementsRemove,
	} = useFieldArray({
		control,
		name: "measurements",
		defaultValue: [],
	});

	const {
		fields: priceFields,
		append: priceAppend,
		remove: priceRemove,
		replace: priceReplace,
	} = useFieldArray({
		control,
		name: "prices",
		defaultValue: [],
	});

	const getHeader = () => {
		return {
			Authorization: "Bearer" + " " + getUserToken(),
			"content-type": "multipart/form-data",
		};
	};

	let [loading, setLoading] = useState(false);

	const userRole = useGetUserRole();

	const onError = (errors, e) => {
		let errorString = [];
		for (const [key, value] of Object.entries(errors)) {
			errorString.push(value.message);
			console.log(`${value.message}`);
		}
		setAlert(errorString.join("."), "error");
	};

	const onSubmit = (data) => {
		setLoading(true);
		const formData = new FormData();

		// Append object properties to FormData

		formData.append("title", data.title);
		formData.append("brand_id", data.brandName?.id);
		formData.append("category_id", data.category?.id);
		formData.append("description", data.description);
		formData.append("type", data.type);
		formData.append("installer", data.installer);
		formData.append("sku", data.sku);
		formData.append("weight_unit", "LBS");
		formData.append("dimension_unit", "IN");

		formData.append("have_colors", data.colors.length > 0);

		data.images.forEach((x, i) => {
			formData.append(`images[${i}][image]`, x.image);
			formData.append(`images[${i}][is_thumbnail]`, x.is_thumbnail);
		});

		data.colors.forEach((x, i) => {
			formData.append(`colors[${i}][color_id]`, x.color_id);

			formData.append(`colors[${i}][material_id]`, x.material_id?.id);
			formData.append(`colors[${i}][primer_id]`, x.primer_id?.id);
			formData.append(`colors[${i}][coat_one_id]`, x.first_coat?.id);
			formData.append(`colors[${i}][coat_two_id]`, x.second_coat?.id);
			formData.append(`colors[${i}][pattern_id]`, x.pattern?.id);
			formData.append(`colors[${i}][glass_type_id]`, x.glass_type?.id);
			formData.append(`colors[${i}][joint]`, x.joint);
			formData.append(`colors[${i}][image]`, x.image);

			// x.finish.forEach((item, j) => {
			// 	formData.append(`colors[${i}][finishings][${j}]`, item?.id);
			// });
		});

		formData.append(`have_measurements`, data.measurements.length > 0);
		data.measurements.forEach((x, i) => {
			formData.append(`measurements[${i}][height]`, x.height);
			formData.append(`measurements[${i}][length]`, x.length);
			formData.append(`measurements[${i}][width]`, x.width);
			formData.append(`measurements[${i}][weight]`, x.weight);
			formData.append(`measurements[${i}][depth]`, x.depth);
			formData.append(`measurements[${i}][title]`, x.title);
			formData.append(`measurements[${i}][delivery_time]`, x.delivery_time);
		});

		data.prices.forEach((x, i) => {
			formData.append(`variants[${i}][size]`, x.size);
			formData.append(`variants[${i}][color]`, x.color);
			formData.append(`variants[${i}][color_code]`, x.color_hex);
			formData.append(`variants[${i}][quantity]`, x.quantity);
			formData.append(`variants[${i}][price]`, x.price);
			formData.append(`variants[${i}][discounted_price]`, x.discount_price);
			formData.append(`variants[${i}][model_no]`, x.model_no);
			formData.append(`variants[${i}][is_available]`, x.available);
			formData.append(`variants[${i}][is_default]`, x.is_default);
			formData.append(`variants[${i}][sku]`, x.sku);
			formData.append(
				`variants[${i}][variant]`,
				x.size.toLowerCase() + "-" + x.color.toLowerCase()
			);
		});

		axios
			.post(`${BASE_URL}/user/products`, formData, {
				headers: getHeader(),
			})
			.then((response) => {
				setAlert(response.data.message || "Product Added", "success");
				navigate(`/${userRole}/viewproduct`);
			})
			.catch((error) => {
				console.log(error.response.data.message);
				setAlert(error.response.data.message || "An error occurred", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return loading ? (
		<LoaderCmp />
	) : (
		<BodyLayout>
			<div className={styles.myJobHeader}>
				<div>
					<Typography
						variant="h5"
						sx={{ fontWeight: "bold" }}
					>
						Add Product
					</Typography>
				</div>
			</div>
			{}
			<div>
				<ProductTabs
					{...{
						control,
						watch,
						getValues,
						setValue,
						colorFields,
						colorAppend,
						colorRemove,
						measurementsFields,
						measurementsAppend,
						measurementsRemove,
						imageReplace,
						priceFields,
						priceAppend,
						priceRemove,
						priceReplace,
					}}
				></ProductTabs>
				<Box
					sx={{
						display: "flex",
						gap: "10px",
						justifyContent: "flex-end",
						mb: "1rem",
						mr: "2rem",
					}}
				>
					{" "}
					<WhiteButtonCmp title="Cancel" />{" "}
					<ButtonCmp
						title="Save"
						onClick={() => {
							handleSubmit(onSubmit, onError)();
						}}
					/>
				</Box>
			</div>
		</BodyLayout>
	);
};

export default ProductMain;
