import {
  deleteMethodCustomHeader,
  getMethodCustomHeader,
  postMethodCustomHeader,
  postMethodMultiPartHeader,
  putMethodCustomHeader,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const AddProductAction = (data, navigate, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.ADD_PRODUCT_REQUEST,
      });
      console.log("data", data);
      const {
        //firstForm 7
        productName,
        brandName,
        modelName,
        modelNo,
        sku,
        category,
        description,

        // secondform 15
        colors, //convert formData array
        material,
        finish,
        width,
        height,
        length,
        distributor,
        supplier,
        room,
        quantity,
        style,
        SDL,
        firstCoat,
        secondCoat,
        primer,

        ///gallery form third form 2
        images, //convert formData array
        symbol, //convert formData array

        // fourth form 9
        pattern,
        joint,
        glassType,
        installer,
        type,
        depth,
        item,
        itemNo,
        doNotInclude,
        custom,
        other,
        // 33
      } = data;

      const objToSend = {
        brand_id: brandName.id || "",
        primer_id: primer.id || "",
        coat_one_id: firstCoat.id || "",
        coat_two_id: secondCoat.id || "",
        supplier_id: supplier.id || "",
        distributor_id: distributor.id || "",
        pattern_id: pattern.id || "",
        category_id: category.id,
        glass_type_id: glassType.id || "",
        material_id: material.id || "",
        sku: sku,
        model_no: modelNo,
        installer: installer,
        type: type,
        model_name: modelName,
        item: item,
        item_no: itemNo,
        style: style,
        joint: joint,
        custom: custom,
        do_not_include: doNotInclude,
        other: other,
        sdl: SDL,
        rooms: room,
        width: width,
        height: height,
        length: length,
        quantity: quantity,
        title: productName,
        description: description,
        //no handle backend
        finish: finish,
        depth: depth,

        // colors: colors.map((color) => color.id),
      };
      // console.log("objToSend", objToSend);
      const formData = new FormData();

      ///all keys convert into formdata
      for (let key in objToSend) {
        formData.append(key, objToSend[key]);
      }

      // convert colors array into formdata
      for (let i = 0; i < colors.length; i++) {
        formData.append(`colors[${i}]`, colors[i].id);
      }

      // convert symbols array into formdata
      for (let i = 0; i < symbol.length; i++) {
        formData.append(`symbols[${i}]`, symbol[i].id);
      }

      // convert images array into formdata
      for (let i = 0; i < images.length; i++) {
        if (images[i]) {
          formData.append(`images[${i}][image]`, images[i].image);
          formData.append(`images[${i}][is_thumbnail]`, images[i].is_thumbnail);
        }
      }

      //   for (const pair of formData.entries()) {
      //     console.log(`${pair[0]}, ${pair[1]}`, "form data values");
      //   }

      const response = await postMethodMultiPartHeader(
        "user/products",
        formData
      );

      console.log(response)

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.ADD_PRODUCT_SUCCESS,
          payload: response.data,
        });
        navigate("/manufacturer/viewproduct");
      } else {
        setAlert(response?.data.message, "error");
        dispatch({
          type: ActionTypes.ADD_PRODUCT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response?.data.message, "error");
      console.log(error.response?.data.message, "errro");
      dispatch({
        type: ActionTypes.ADD_PRODUCT_FAIL,
        payload: error.response?.data,
      });
    }
  };
};

const UserProductsAction = (per_page, page, setAlert, sortBy, searchBy) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_USER_PRODUCTS_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/products?per_page=${per_page}&page=${page}`
      // );

      const response = await getMethodCustomHeader(
        `user/products?per_page=${per_page}&page=${page}&sortBy=${
          sortBy || ""
        }&search=${searchBy || ""}`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_USER_PRODUCTS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_USER_PRODUCTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_USER_PRODUCTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const ProductDetailsAction = (productSlug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_PRODUCT_DETAILS_REQUEST,
      });

      const response = await getMethodCustomHeader(`products/${productSlug}`);

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_PRODUCT_DETAILS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_PRODUCT_DETAILS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_PRODUCT_DETAILS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const DeleteProductAction = (productSlug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DELETE_PRODUCT_REQUEST,
      });

      const response = await deleteMethodCustomHeader(
        `user/products/${productSlug}`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.DELETE_PRODUCT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.DELETE_PRODUCT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.DELETE_PRODUCT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetDeletedProductsAction = (perPage, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_DELETED_PRODUCTS_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `user/products-trashed?per_page=${perPage}&page=${page}`
      );
      ///success true
      console.log(response, "response");
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_DELETED_PRODUCTS_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        console.log("false chalana hai ");
        dispatch({
          type: ActionTypes.GET_DELETED_PRODUCTS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_DELETED_PRODUCTS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const RestoreDeletedProductAction = (productSlug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.RESTORE_DELETED_PRODUCT_REQUEST,
      });

      const response = await postMethodCustomHeader(
        `user/products-restore/${productSlug}`
      );
      console.log("response", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.RESTORE_DELETED_PRODUCT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        console.log("false chalana hai ");
        dispatch({
          type: ActionTypes.RESTORE_DELETED_PRODUCT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.RESTORE_DELETED_PRODUCT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const PermanentDeleteProductAction = (productSlug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.PERMANENT_DELETE_PRODUCT_REQUEST,
      });

      const response = await deleteMethodCustomHeader(
        `user/products-permanent-delete/${productSlug}`
      );
      console.log("response", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");

        dispatch({
          type: ActionTypes.PERMANENT_DELETE_PRODUCT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        console.log("false chalana hai ");
        dispatch({
          type: ActionTypes.PERMANENT_DELETE_PRODUCT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.PERMANENT_DELETE_PRODUCT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const BulkImportAction = (tableData, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: ActionTypes.PRODUCT_BULK_IMPORT_REQUEST,
      // });
      tableData[0].product_name = "product 102";
      console.log("tableData 1111", tableData);

      const formData = new FormData();

      for (let index = 0; index < tableData.length; index++) {
        for (const key in tableData[index]) {
          if (key === "images") {
            for (let i = 0; i < tableData[index][key].length; i++) {
              formData.append(
                `products[${[index]}][${key}][${i}][image]`,
                tableData[index][key][i].image
              );
              formData.append(
                `products[${[index]}][${key}][${i}][is_thumbnail]`,
                tableData[index][key][i].is_thumbnail
              );
            }
          } else if (key === "colors") {
            // for (let i = 0; i < tableData[index][key].length; i++) {
            //   formData.append(
            //     `products[${[index]}][${key}][${i}]`,
            //     tableData[index][key][i]
            //   );
            // }
            formData.append(
              `products[${[index]}][${key}]`,
              JSON.stringify(tableData[index][key])
            );
          } else if (key === "symbols") {
            // for (let i = 0; i < tableData[index][key].length; i++) {
            //   formData.append(
            //     `products[${[index]}][${key}][${i}]`,
            //     tableData[index][key][i]
            //   );
            // }
            formData.append(
              `products[${[index]}][${key}]`,
              JSON.stringify(tableData[index][key])
            );
          }
          // else if (key === "product_name") {
          //   formData.append(`products[${[index]}][${key}]`, "product 101010");
          // }
          else {
            formData.append(
              `products[${[index]}][${key}]`,
              tableData[index][key]
            );
          }
        }
      }
      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`, "form data values");
      // }
      const response = await postMethodMultiPartHeader(
        `user/products-bulk-upload`,
        formData
      );
      console.log("bulk import response", response);
      ///success true
      if (response.data.success) {
        setAlert(response.data?.message, "success");
        navigate("/manufacturer/viewproduct");
        dispatch({
          type: ActionTypes.PRODUCT_BULK_IMPORT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        console.log("false chalana hai ");
        dispatch({
          type: ActionTypes.PRODUCT_BULK_IMPORT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.PRODUCT_BULK_IMPORT_FAIL,
        payload: error.response.data,
      });
    }
  };
};
const UpdateProductAction = (data, navigate, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_PRODUCT_REQUEST,
      });
      console.log("data", data);
      const {
        //firstForm 7
        productName,
        brandName,
        modelName,
        modelNo,
        sku,
        category,
        description,

        // secondform 15
        colors, //convert formData array
        material,
        finish,
        width,
        height,
        length,
        distributor,
        supplier,
        room,
        quantity,
        style,
        SDL,
        firstCoat,
        secondCoat,
        primer,

        ///gallery form third form 2
        images, //convert formData array
        symbol, //convert formData array

        // fourth form 9
        pattern,
        joint,
        glassType,
        installer,
        type,
        depth,
        item,
        itemNo,
        doNotInclude,
        custom,
        other,
        // 33
      } = data;

      const objToSend = {
        brand_id: brandName.id || "",
        primer_id: primer.id || "",
        coat_one_id: firstCoat.id || "",
        coat_two_id: secondCoat.id || "",
        supplier_id: supplier.id || "",
        distributor_id: distributor.id || "",
        pattern_id: pattern.id || "",
        category_id: category.id,
        glass_type_id: glassType.id || "",
        material_id: material.id || "",
        sku: sku,
        model_no: modelNo,
        installer: installer,
        type: type,
        model_name: modelName,
        item: item,
        item_no: itemNo,
        style: style,
        joint: joint,
        custom: custom,
        do_not_include: doNotInclude,
        other: other,
        sdl: SDL,
        rooms: room,
        width: width,
        height: height,
        length: length,
        quantity: quantity,
        title: productName,
        description: description,
        //no handle backend
        finish: finish,
        depth: depth,

        // colors: colors.map((color) => color.id),
      };
      // console.log("objToSend", objToSend);
      const formData = new FormData();

      ///all keys convert into formdata
      for (let key in objToSend) {
        formData.append(key, objToSend[key]);
      }

      // convert colors array into formdata
      for (let i = 0; i < colors.length; i++) {
        formData.append(`colors[${i}]`, colors[i].id);
      }

      // convert symbols array into formdata
      for (let i = 0; i < symbol.length; i++) {
        formData.append(`symbols[${i}]`, symbol[i].id);
      }

      // convert images array into formdata
      for (let i = 0; i < images.length; i++) {
        if (images[i]) {
          formData.append(`images[${i}][image]`, images[i].image);
          formData.append(`images[${i}][is_thumbnail]`, images[i].is_thumbnail);
        }
      }

      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`, "form data values");
      }

      const response = await postMethodMultiPartHeader(
        `user/products/${data.productSlug}`,
        formData
      );

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response?.data.message, "error");
        dispatch({
          type: ActionTypes.UPDATE_PRODUCT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response?.data.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.UPDATE_PRODUCT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EmptyDeleteProductState = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY__DELETE_PRODUCT_STATE,
    });
  };
};

const EmptyRestoreDeletedProductState = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_RESTORE_DELETED_PRODUCT_STATE,
    });
  };
};

const EmptyPermanentDeleteProductState = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.EMPTY_PERMANENT_DELETE_PRODUCT_STATE,
    });
  };
};

export {
  AddProductAction,
  UserProductsAction,
  ProductDetailsAction,
  DeleteProductAction,
  GetDeletedProductsAction,
  RestoreDeletedProductAction,
  PermanentDeleteProductAction,
  EmptyDeleteProductState,
  EmptyRestoreDeletedProductState,
  EmptyPermanentDeleteProductState,
  BulkImportAction,
  UpdateProductAction,
};
