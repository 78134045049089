import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./productModals.module.css";
import { Grid } from "@mui/material";
import DragNDrop from "../../DragNDrop/DragNDrop";
import { useForm } from "react-hook-form";
import InputCmp from "../../InputCmp/InputCmp";
import AutoCompleteWithIcon from "../../AutoCompleteWithIcon/AutoCompleteWithIcon";
import DropDownCmp from "../../DropDownCmp/DropDownCmp";
import TextAreaCmp from "../../TextAreaCmp/TextAreaCmp";
import BackDropCmp from "../../BackDrop/BackDrop";
import ButtonCmp from "../../ButtonCmp/ButtonCmp";
import WhiteButtonCmp from "../../ButtonCmp/WhiteButtonCmp";
import ProductDetails from "./ProductDetails";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  height: "90vh",
  overflowY: "hidden",
  outline: "none",
};

export default function ViewProductModal({ open, setOpen }) {
  //   const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      // firstForm
      productName: "",
    },
  });

  const onSubmit = () => {
    console.log("HELLO ADD PRODUCT FORM......!!");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className={styles.modalMainBox}>
            <Box
              sx={{
                px: 4,
                py: 2,
              }}
              className={styles.viewProductModalWrapper}
            >
              <Grid container>
                <Grid
                  item
                  lg={12}
                  sx={{
                    maxHeight: "100%",
                    overflowY: "scroll",
                  }}
                  className="scroll-remove"
                >
                  <ProductDetails
                      setOpen={setOpen}
                  // control={control}
                  // handleSubmit={handleSubmit}
                  // onSubmit={onSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
