import React from "react";
import TocIcon from "@mui/icons-material/Toc";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

import Avatar from "../../Assets/Images/Mask Group 17.png";
import { BuildLinkLogo } from "../../Assets";
import ProfileInfoForm from "./ProfileInfoForm";
import DocsAndRegsForm from "./DocsAndRegsForm";
import ServicesForm from "./ServicesForm";
import SocialMedia from "./SocialMedia";
import YourConnections from "./YourConnections";
import styles from "./ProfileCompletionForm.module.css";
import FormProgressBar from "./FormProgressBar";
import { BackDropCmp } from "../../Components";
import { useSelector } from "react-redux";

const ProfileCompletionForm = () => {
	const [barPercent, setBarPercent] = useState("2%");
	const [whichForm, setWhichForm] = useState("profileInfo");

	const [profileComplete, setprofileComplete] = useState(false);
	const [docsAndRegComplete, setDocsAndRegComplete] = useState(false);
	const [servicesComplete, setServicesComplete] = useState(false);
	const [socialMediaComplete, setSocialMediaComplete] = useState(false);
	const [connectionsComplete, setconnectionsComplete] = useState(false);

	const { profileWizardLoading } = useSelector(
		(state) => state.ProfielWizardReducer
	);

	return (
		<Box className={styles.ProfileCompletionForm_wrapper}>
			<FormNavBar />

			<Box className={styles.form_wrapper}>
				<FormProgressBar
					profileComplete={profileComplete}
					docsAndRegComplete={docsAndRegComplete}
					servicesComplete={servicesComplete}
					socialMediaComplete={socialMediaComplete}
					connectionsComplete={connectionsComplete}
					barPercent={barPercent}
					whichForm={whichForm}
				/>

				{/* ////profile Information/// */}
				{whichForm === "profileInfo" && (
					<ProfileInfoForm
						setWhichForm={setWhichForm}
						setBarPercent={setBarPercent}
						setprofileComplete={setprofileComplete}
					/>
				)}
				{whichForm === "docs&regs" && (
					<DocsAndRegsForm
						setWhichForm={setWhichForm}
						setBarPercent={setBarPercent}
						setDocsAndRegComplete={setDocsAndRegComplete}
					/>
				)}
				{whichForm === "services" && (
					<ServicesForm
						setWhichForm={setWhichForm}
						setBarPercent={setBarPercent}
						setServicesComplete={setServicesComplete}
					/>
				)}
				{whichForm === "socialMedia" && (
					<SocialMedia
						setWhichForm={setWhichForm}
						setBarPercent={setBarPercent}
						setSocialMediaComplete={setSocialMediaComplete}
					/>
				)}
				{/* {whichForm === "yourConnection" && (
          <YourConnections
            setWhichForm={setWhichForm}
            setBarPercent={setBarPercent}
            setconnectionsComplete={setconnectionsComplete}
          />
        )} */}
			</Box>
			<BackDropCmp setOpen={profileWizardLoading} />
		</Box>
	);
};

const FormNavBar = () => {
	return (
		<section className={styles.navbarWrapper}>
			<div className={styles.navbarLogo}>
				<img
					src={BuildLinkLogo}
					alt=""
				/>
			</div>
			<div className={styles.navOtherOptions}>
				<section className={styles.navAvatar}>
					<div className={styles.navAvatarName}></div>
					<Link to="/profile">
						<img
							src={Avatar}
							alt=""
						/>
					</Link>
				</section>
				<section className={styles.navProfile}>
					<TocIcon />
				</section>
			</div>
		</section>
	);
};

export default ProfileCompletionForm;
