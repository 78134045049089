import { BodyLayout, ButtonCmp } from "../../../Components";
import styles from "./OrderDetail.module.css";
import { Grid, Typography, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const OrderDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();

	let { order } = location.state;

	console.log(order);

	return (
		<BodyLayout className={styles.mainBox}>
			<Box sx={{ padding: "2rem" }}>
				<Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
					Order Details
				</Typography>
			</Box>
			<section>
				<Grid
					container
					sx={{
						alignItems: "center",
						display: "flex",
						my: "20px",
						px: "20px",
						pb: "20px",

						cursor: "pointer",
					}}
				>
					<Grid
						item
						lg={4}
					>
						<Box
							sx={{
								display: "flex",
								gap: "10px",
								flexDirection: "column",
							}}
						>
							<section>
								<Typography
									sx={{ color: "var(--textColor)", fontSize: "14px" }}
								>
									Order No.
								</Typography>
								<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
									{order?.order_number}
								</Typography>
							</section>
						</Box>
					</Grid>
					<Grid
						item
						lg={3}
					>
						<Box>
							<section>
								<Typography
									sx={{ color: "var(--textColor)", fontSize: "14px" }}
								>
									Payment Status
								</Typography>
								<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
									{order?.payment_status}
								</Typography>
							</section>
						</Box>
					</Grid>
					<Grid
						item
						lg={3}
					>
						{" "}
						<Typography sx={{ color: "var(--textColor)", fontSize: "14px" }}>
							Order Status
						</Typography>
						<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
							{order?.order_status}
						</Typography>{" "}
					</Grid>
					<Grid
						item
						lg={2}
					>
						{" "}
						<Box>
							<section>
								<ButtonCmp
									style={{ width: "max-content", padding: "10px 15px" }}
									title="Complete"
								/>
							</section>
						</Box>
					</Grid>
				</Grid>
			</section>
			<Grid>
				{order.order_details.map((item, index) => {
					return (
						<Grid
							container
							sx={{
								alignItems: "center",
								display: "flex",
								my: "20px",
								pb: "20px",
								px: "20px",
								borderBottom: "2px solid #ECECEC",

								cursor: "pointer",
							}}
						>
							<Grid
								item
								lg={3}
								sx={{ cursor: "pointer" }}
							>
								<section className={styles.cardImg}>
									<img
										alt={item.product.title}
										src={item.product.ImgURL + item.product.thumbnail}
										width="200px"
									/>
								</section>
							</Grid>
							<Grid
								item
								lg={3}
							>
								<Box
									sx={{
										display: "flex",
										gap: "10px",
										flexDirection: "column",
									}}
								>
									<section>
										<Typography
											sx={{ color: "var(--textColor)", fontSize: "14px" }}
										>
											Product Name
										</Typography>
										<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
											{item?.product.title}
										</Typography>
									</section>
								</Box>
							</Grid>
							<Grid
								item
								lg={3}
							>
								<Box>
									<section>
										<Typography
											sx={{ color: "var(--textColor)", fontSize: "14px" }}
										>
											Variant
										</Typography>
										<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
											{item?.variant}
										</Typography>
									</section>
								</Box>
							</Grid>
							<Grid
								item
								lg={3}
							>
								{" "}
								<Typography
									sx={{ color: "var(--textColor)", fontSize: "14px" }}
								>
									Quantity
								</Typography>
								<Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
									{Number(item?.quantity)}
								</Typography>{" "}
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</BodyLayout>
	);
};

export default OrderDetail;
