export const Briefcase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 15 14.25"
    >
      <path
        id="Path_briefcase-outline"
        data-name="Path / briefcase-outline"
        d="M15.5,5a1.4,1.4,0,0,1,1.065.443A1.493,1.493,0,0,1,17,6.5v8.25a1.493,1.493,0,0,1-.435,1.057,1.4,1.4,0,0,1-1.065.443H3.5a1.4,1.4,0,0,1-1.065-.443A1.493,1.493,0,0,1,2,14.75V6.5a1.493,1.493,0,0,1,.435-1.057A1.4,1.4,0,0,1,3.5,5h3V3.5a1.424,1.424,0,0,1,.435-1.065A1.424,1.424,0,0,1,8,2h3a1.424,1.424,0,0,1,1.065.435A1.424,1.424,0,0,1,12.5,3.5V5h3M3.5,6.5v8.25h12V6.5H3.5M11,5V3.5H8V5Z"
        transform="translate(-2 -2)"
        fill="#111827"
      />
    </svg>
  );
};

export const BriefcasePlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 15 15"
    >
      <path
        id="Path_briefcase-plus-outline"
        data-name="Path / briefcase-plus-outline"
        d="M8,2h3a1.5,1.5,0,0,1,1.5,1.5V5h3A1.5,1.5,0,0,1,17,6.5v4.148a4.29,4.29,0,0,0-1.5-.892V6.5H3.5v8.25H9.56a4.488,4.488,0,0,0,.54,1.5H3.5A1.5,1.5,0,0,1,2,14.75V6.5A1.5,1.5,0,0,1,3.5,5h3V3.5A1.5,1.5,0,0,1,8,2m3,3V3.5H8V5h3m0,8.25h2.25V11h1.5v2.25H17v1.5H14.75V17h-1.5V14.75H11Z"
        transform="translate(-2 -2)"
        fill="#111827"
      />
    </svg>
  );
};

export const CheckBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 15 15"
    >
      <path
        id="Path_checkbox-marked-circle-outline"
        data-name="Path / checkbox-marked-circle-outline"
        d="M15.5,9.5a6,6,0,1,1-4.35-5.767l1.178-1.178A7.367,7.367,0,0,0,9.5,2,7.5,7.5,0,1,0,17,9.5M6.432,8.06,5.375,9.125,8.75,12.5,16.25,5,15.193,3.935,8.75,10.378Z"
        transform="translate(-2 -2)"
        fill="#111827"
      />
    </svg>
  );
};
export const MultiCheckBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 15 13.5"
    >
      <path
        id="Path_checkbox-multiple-marked-circle-outline"
        data-name="Path / checkbox-multiple-marked-circle-outline"
        d="M11,2a6,6,0,1,0,6,6H15.5a4.516,4.516,0,1,1-3.547-4.395L13.16,2.4A5.876,5.876,0,0,0,11,2m4.943,1.185L11,8.128,9.215,6.342,8.158,7.408,11,10.25l6-6M4.2,4.865A6,6,0,0,0,8,15.5a5.9,5.9,0,0,0,1.41-.172,7.781,7.781,0,0,1-3.532-1.86A4.5,4.5,0,0,1,3.5,9.5a5,5,0,0,1,.053-.668A7.752,7.752,0,0,1,3.5,8,7.438,7.438,0,0,1,4.2,4.865Z"
        transform="translate(-2 -2)"
        fill="#111827"
      />
    </svg>
  );
};

export const TrashSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 13.333 15"
    >
      <path
        id="Path_trash-can-outline"
        data-name="Path / trash-can-outline"
        d="M8.167,3v.833H4V5.5h.833V16.333A1.667,1.667,0,0,0,6.5,18h8.333A1.667,1.667,0,0,0,16.5,16.333V5.5h.833V3.833H13.167V3h-5M6.5,5.5h8.333V16.333H6.5V5.5M8.167,7.167v7.5H9.833v-7.5H8.167m3.333,0v7.5h1.667v-7.5Z"
        transform="translate(-4 -3)"
      />
    </svg>
  );
};

export const TrashBigSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 13.333 15"
      fill="#EE4B2B"
    >
      <path
        id="Path_trash-can-outline"
        data-name="Path / trash-can-outline"
        d="M8.167,3v.833H4V5.5h.833V16.333A1.667,1.667,0,0,0,6.5,18h8.333A1.667,1.667,0,0,0,16.5,16.333V5.5h.833V3.833H13.167V3h-5M6.5,5.5h8.333V16.333H6.5V5.5M8.167,7.167v7.5H9.833v-7.5H8.167m3.333,0v7.5h1.667v-7.5Z"
        transform="translate(-4 -3)"
      />
    </svg>
  );
};

export const GavelSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16.737 16.752"
    >
      <path
        id="Path_gavel"
        data-name="Path / gavel"
        d="M2.251,17.216l8-8L9.085,8.033l-.6.592a.83.83,0,0,1-1.175,0l-.592-.592a.83.83,0,0,1,0-1.175l4.717-4.717a.83.83,0,0,1,1.175,0l.592.592a.83.83,0,0,1,0,1.175l-.592.575,1.183,1.192a.83.83,0,0,1,1.175,0,.84.84,0,0,1,0,1.183l1.175,1.175.592-.592a.84.84,0,0,1,1.183,0l.583.592a.84.84,0,0,1,0,1.183l-4.708,4.708a.84.84,0,0,1-1.183,0l-.583-.583a.825.825,0,0,1,0-1.183l.583-.592-1.175-1.175L3.426,18.4a.83.83,0,0,1-1.175,0,.84.84,0,0,1,0-1.183M17,16.15a1.667,1.667,0,0,1,1.667,1.667v.833H10.335v-.833A1.667,1.667,0,0,1,12,16.15Z"
        transform="translate(-2.007 -1.897)"
      />
    </svg>
  );
};

export const ClockCheckSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16.125 15"
    >
      <path
        id="Path_clock-check-outline"
        data-name="Path / clock-check-outline"
        d="M18.125,13.25,14.375,17,11.75,14.375l1.125-1.125,1.5,1.5L17,12.125l1.125,1.125m-7.8,2.175A3.076,3.076,0,0,1,9.5,15.5a6,6,0,1,1,6-6,3.076,3.076,0,0,1-.075.825,4.525,4.525,0,0,1,1.425.45A7.389,7.389,0,0,0,17,9.5,7.5,7.5,0,1,0,9.5,17a6.6,6.6,0,0,0,1.275-.15,3.981,3.981,0,0,1-.45-1.425m1.875-4.35L9.875,9.725V5.75H8.75v4.5l2.625,1.575A3.931,3.931,0,0,1,12.2,11.075Z"
        transform="translate(-2 -2)"
        fill="#111827"
      />
    </svg>
  );
};

export const WhishListSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17.25 15.675"
    >
      <path
        id="Path_heart-multiple-outline"
        data-name="Path / heart-multiple-outline"
        d="M9.125,16.2,8,17.175C3.05,12.75.5,10.425.5,7.575a4.334,4.334,0,0,1,.75-2.4v.45c0,3.525,2.925,6.15,7.875,10.575m1.2-3.075c3.525-3.225,5.925-5.325,6-7.5A2.563,2.563,0,0,0,13.7,3,2.885,2.885,0,0,0,11,4.8H9.575A3.046,3.046,0,0,0,6.875,3,2.563,2.563,0,0,0,4.25,5.625c0,2.175,2.325,4.275,5.925,7.5l.075.075M13.625,1.5A4.067,4.067,0,0,1,17.75,5.625c0,2.775-2.55,5.1-7.5,9.6-4.95-4.425-7.5-6.75-7.5-9.6A4.067,4.067,0,0,1,6.875,1.5,4.507,4.507,0,0,1,10.25,3.075,4.507,4.507,0,0,1,13.625,1.5Z"
        transform="translate(-0.5 -1.5)"
      />
    </svg>
  );
};
export const DashboardSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 13.5 13.5"
    >
      <path
        id="Path_view-dashboard-outline"
        data-name="Path / view-dashboard-outline"
        d="M15,4.5V6H12V4.5h3m-7.5,0V9h-3V4.5h3m7.5,6V15H12V10.5h3m-7.5,3V15h-3V13.5h3M16.5,3h-6V7.5h6V3M9,3H3v7.5H9V3m7.5,6h-6v7.5h6V9M9,12H3v4.5H9Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export const BackburgerSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 13.5 13.5"
    >
      <path
        id="Path_view-dashboard-outline"
        data-name="Path / view-dashboard-outline"
        d="M15,4.5V6H12V4.5h3m-7.5,0V9h-3V4.5h3m7.5,6V15H12V10.5h3m-7.5,3V15h-3V13.5h3M16.5,3h-6V7.5h6V3M9,3H3v7.5H9V3m7.5,6h-6v7.5h6V9M9,12H3v4.5H9Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export const ProfileSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 13.333 13.333"
    >
      <path
        id="Path_account-outline"
        data-name="Path / account-outline"
        d="M10.667,4A3.333,3.333,0,1,1,7.333,7.333,3.333,3.333,0,0,1,10.667,4m0,1.667a1.667,1.667,0,1,0,1.667,1.667,1.667,1.667,0,0,0-1.667-1.667m0,5.833c2.225,0,6.667,1.108,6.667,3.333v2.5H4v-2.5C4,12.608,8.442,11.5,10.667,11.5m0,1.583c-2.475,0-5.083,1.217-5.083,1.75v.917H15.75v-.917C15.75,14.3,13.142,13.083,10.667,13.083Z"
        transform="translate(-4 -4)"
        fill="#000"
      />
    </svg>
  );
};

export const EditProfileSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16.548 14.125"
    >
      <path
        id="Path_account-edit-outline"
        data-name="Path / account-edit-outline"
        d="M2,14.833v2.5H8.667V15.758H3.583v-.925c0-.533,2.608-1.75,5.083-1.75a9.745,9.745,0,0,1,2.358.317l1.267-1.267A12.09,12.09,0,0,0,8.667,11.5C6.442,11.5,2,12.608,2,14.833M8.667,4A3.333,3.333,0,1,0,12,7.333,3.332,3.332,0,0,0,8.667,4m0,5a1.667,1.667,0,1,1,1.667-1.667A1.666,1.666,0,0,1,8.667,9m9.75,2.792-.833.833-1.708-1.667.833-.833a.458.458,0,0,1,.642,0l1.067,1.067a.458.458,0,0,1,0,.642M10.333,16.45l5.05-5.05,1.708,1.667-5,5.058H10.333V16.45"
        transform="translate(-2 -4)"
      />
    </svg>
  );
};

export const ProConnectionSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16.667 12.5"
    >
      <path
        id="Path_account-multiple-outline"
        data-name="Path / account-multiple-outline"
        d="M11.225,9.342a4.167,4.167,0,0,0,0-4.85A2.825,2.825,0,0,1,12.833,4a2.917,2.917,0,0,1,0,5.833,2.825,2.825,0,0,1-1.608-.492M4.917,6.917A2.917,2.917,0,1,1,7.833,9.833,2.917,2.917,0,0,1,4.917,6.917m1.667,0a1.25,1.25,0,1,0,1.25-1.25,1.25,1.25,0,0,0-1.25,1.25m7.083,7.917V16.5H2V14.833S2,11.5,7.833,11.5s5.833,3.333,5.833,3.333m-1.667,0c-.117-.65-1.108-1.667-4.167-1.667s-4.108,1.092-4.167,1.667M13.625,11.5a4.433,4.433,0,0,1,1.708,3.333V16.5h3.333V14.833s0-3.025-5.05-3.333Z"
        transform="translate(-2 -4)"
      />
    </svg>
  );
};

export const NewConnectionSvg = () => {
  return (
    <svg
      id="MDI_account-clock-outline"
      data-name="MDI / account-clock-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Boundary" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="20" height="20" stroke="none" />
        <rect x="0.5" y="0.5" width="20" height="20" fill="none" />
      </g>
      <path
        id="Path_account-clock-outline"
        data-name="Path / account-clock-outline"
        d="M13.5,12.333h1.25v2.35l2.033,1.175-.625,1.083L13.5,15.408V12.333m.833-1.667A4.167,4.167,0,1,0,18.5,14.833a4.167,4.167,0,0,0-4.167-4.167m0-1.667a5.833,5.833,0,1,1-5.275,8.333H1v-2.5C1,12.617,5.442,11.5,7.667,11.5a11.161,11.161,0,0,1,1.767.167A5.827,5.827,0,0,1,14.333,9M8.5,14.833a5.715,5.715,0,0,1,.242-1.667,6.6,6.6,0,0,0-1.075-.083c-2.475,0-5.083,1.217-5.083,1.75v.917H8.575a5.591,5.591,0,0,1-.075-.917M7.667,4A3.333,3.333,0,1,1,4.333,7.333,3.333,3.333,0,0,1,7.667,4m0,1.583a1.75,1.75,0,1,0,1.75,1.75A1.75,1.75,0,0,0,7.667,5.583Z"
        transform="translate(-0.167 -2.333)"
      />
    </svg>
  );
};

export const ConnectionRequestsSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 11.667"
    >
      <path
        id="Path_account-multiple-plus-outline"
        data-name="Path / account-multiple-plus-outline"
        d="M10.833,10a2.5,2.5,0,1,0-2.5-2.5,2.5,2.5,0,0,0,2.5,2.5m0-3.333A.833.833,0,1,1,10,7.5a.833.833,0,0,1,.833-.833m3.425,3.217a4.167,4.167,0,0,0,0-4.767A2.425,2.425,0,0,1,15,5a2.5,2.5,0,1,1,0,5,2.425,2.425,0,0,1-.742-.117m-3.425,1.783c-5,0-5,3.333-5,3.333v1.667h10V15s0-3.333-5-3.333M7.5,15c0-.242.267-1.667,3.333-1.667,2.917,0,3.283,1.3,3.333,1.667M20,15v1.667H17.5V15A4.667,4.667,0,0,0,16,11.717C20,12.125,20,15,20,15M6.667,10.833h-2.5v2.5H2.5v-2.5H0V9.167H2.5v-2.5H4.167v2.5h2.5Z"
        transform="translate(0 -5)"
      />
    </svg>
  );
};

export const ChatSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 16.667 16.667"
    >
      <path
        id="Path_message-text-outline"
        data-name="Path / message-text-outline"
        d="M17,2a1.667,1.667,0,0,1,1.667,1.667v10A1.667,1.667,0,0,1,17,15.333H5.333L2,18.667v-15A1.666,1.666,0,0,1,3.667,2H17M3.667,3.667V14.642l.975-.975H17v-10H3.667m1.667,2.5h10V7.833h-10V6.167m0,3.333h7.5v1.667h-7.5Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export const EyeSvg = () => {
  return (
    <svg
      id="MDI_eye-circle-outline"
      data-name="MDI / eye-circle-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Boundary" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="18" height="18" stroke="none" />
        <rect x="0.5" y="0.5" width="17" height="17" fill="none" />
      </g>
      <path
        id="Path_eye-circle-outline"
        data-name="Path / eye-circle-outline"
        d="M9.5,17A7.5,7.5,0,1,1,17,9.5,7.5,7.5,0,0,1,9.5,17m0-1.5a6,6,0,1,0-6-6,6,6,0,0,0,6,6m0-6.75a.75.75,0,1,1-.75.75.75.75,0,0,1,.75-.75m0-2.25a4.854,4.854,0,0,1,4.5,3,4.875,4.875,0,0,1-9,0,4.854,4.854,0,0,1,4.5-3m0,1.125A1.875,1.875,0,1,0,11.375,9.5,1.875,1.875,0,0,0,9.5,7.625"
        transform="translate(-0.5 -0.5)"
      />
    </svg>
  );
};

export const Rotate3DIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 19.908 20"
    >
      <path
        id="Path_rotate-3d"
        data-name="Path / rotate-3d"
        d="M6.225,17.917A8.789,8.789,0,0,1,1.25,10.833H0A9.989,9.989,0,0,0,9.958,20l.55-.025L7.333,16.8,6.225,17.917m.742-5.45a1.431,1.431,0,0,1-.433-.067.891.891,0,0,1-.333-.2.826.826,0,0,1-.217-.308.994.994,0,0,1-.075-.392H4.825A1.658,1.658,0,0,0,5,12.292a2.054,2.054,0,0,0,.467.575,2.105,2.105,0,0,0,.683.342,2.514,2.514,0,0,0,.8.125,2.811,2.811,0,0,0,.858-.125,2.032,2.032,0,0,0,.692-.367,1.52,1.52,0,0,0,.458-.6,1.946,1.946,0,0,0,.167-.808,1.748,1.748,0,0,0-.058-.467,1.6,1.6,0,0,0-.192-.425,1.672,1.672,0,0,0-.333-.358,1.936,1.936,0,0,0-.508-.258,1.917,1.917,0,0,0,.433-.275,1.722,1.722,0,0,0,.308-.35,1.607,1.607,0,0,0,.183-.383,1.329,1.329,0,0,0,.058-.4,2.014,2.014,0,0,0-.15-.8,1.845,1.845,0,0,0-.425-.575A1.714,1.714,0,0,0,7.8,6.783a3.037,3.037,0,0,0-.85-.117,2.531,2.531,0,0,0-.833.133,2.12,2.12,0,0,0-.658.375,1.684,1.684,0,0,0-.425.558,1.661,1.661,0,0,0-.15.708H5.967a.886.886,0,0,1,.075-.375.783.783,0,0,1,.208-.283A.988.988,0,0,1,6.567,7.6a1.22,1.22,0,0,1,.4-.067.956.956,0,0,1,.742.258,1.007,1.007,0,0,1,.242.717,1.55,1.55,0,0,1-.067.408.725.725,0,0,1-.208.308,1.057,1.057,0,0,1-.342.2A1.4,1.4,0,0,1,6.85,9.5H6.208v.858H6.85a1.872,1.872,0,0,1,.5.058,1,1,0,0,1,.375.192.882.882,0,0,1,.242.333,1.237,1.237,0,0,1,.083.475,1,1,0,0,1-.292.775,1.068,1.068,0,0,1-.792.275m7.125-4.933a2.775,2.775,0,0,0-.95-.642,3.135,3.135,0,0,0-1.217-.225H9.958v6.667h1.917a3.382,3.382,0,0,0,1.258-.225,2.746,2.746,0,0,0,.967-.633,2.7,2.7,0,0,0,.617-.992,3.812,3.812,0,0,0,.217-1.308V9.842a3.812,3.812,0,0,0-.217-1.308,2.682,2.682,0,0,0-.625-1m-.342,2.633a3.629,3.629,0,0,1-.1.942,2.026,2.026,0,0,1-.358.708,1.7,1.7,0,0,1-.592.442,2.142,2.142,0,0,1-.825.15h-.758V7.6h.808a1.653,1.653,0,0,1,1.367.575,2.538,2.538,0,0,1,.458,1.658M9.958,0l-.55.025L12.583,3.2l1.108-1.117a8.751,8.751,0,0,1,4.967,7.083h1.25A9.981,9.981,0,0,0,9.958,0Z"
      />
    </svg>
  );
};

export const ProSymbolSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16.55 16.667"
    >
      <path
        id="Path_progress-star"
        data-name="Path / progress-star"
        d="M11.175,2V3.667a6.667,6.667,0,0,1,0,13.233v1.667A8.333,8.333,0,0,0,11.175,2M9.508,2A8.164,8.164,0,0,0,5.067,3.833L6.258,5.117a6.663,6.663,0,0,1,3.25-1.4V2.05M3.892,5.058A8.174,8.174,0,0,0,2.05,9.5H3.717A6.681,6.681,0,0,1,5.083,6.25L3.892,5.058M2.058,11.167A8.366,8.366,0,0,0,3.9,15.608l1.183-1.192a6.669,6.669,0,0,1-1.358-3.25H2.058m4.167,4.475L5.067,16.783a8.328,8.328,0,0,0,4.442,1.883V17a6.669,6.669,0,0,1-3.25-1.358H6.225m1.267-1.308.767-3.242-2.5-2.15L9.05,8.633l1.292-3.008,1.292,3.042,3.292.275-2.5,2.15.767,3.242-2.85-1.717-2.85,1.717"
        transform="translate(-2.05 -2)"
      />
    </svg>
  );
};

export const AlphaDSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 14 14"
    >
      <path
        id="Path_alpha-d-circle-outline"
        data-name="Path / alpha-d-circle-outline"
        d="M6.9,5.5H9.7a1.4,1.4,0,0,1,1.4,1.4v4.2a1.4,1.4,0,0,1-1.4,1.4H6.9v-7M8.3,6.9v4.2H9.7V6.9H8.3M9,2A7,7,0,1,1,2,9,7,7,0,0,1,9,2M9,3.4A5.6,5.6,0,1,0,14.6,9,5.6,5.6,0,0,0,9,3.4Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export const AlphaJSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 13.5 13.5"
    >
      <path
        id="Path_alpha-j-box-outline"
        data-name="Path / alpha-j-box-outline"
        d="M10.5,6H12v6a1.5,1.5,0,0,1-1.5,1.5H9A1.5,1.5,0,0,1,7.5,12v-.75H9V12h1.5V6m-6-3H15a1.5,1.5,0,0,1,1.5,1.5V15A1.5,1.5,0,0,1,15,16.5H4.5A1.5,1.5,0,0,1,3,15V4.5A1.5,1.5,0,0,1,4.5,3m0,1.5V15H15V4.5Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};

export const ShapeCirclePlusSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 14.25 14.25"
    >
      <path
        id="Path_shape-circle-plus"
        data-name="Path / shape-circle-plus"
        d="M9,14.75a4.5,4.5,0,0,0,4.5-4.5H15a6,6,0,1,1-6-6v1.5a4.5,4.5,0,0,0,0,9m6-10.5h2.25v1.5H15V8H13.5V5.75H11.25V4.25H13.5V2H15Z"
        transform="translate(-3 -2)"
      />
    </svg>
  );
};

export const PlusIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 14 14"
    >
      <path
        id="Path_plus"
        data-name="Path / plus"
        d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
        transform="translate(-5 -5)"
      />
    </svg>
  );
};

export const MinusIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="10"
      viewBox="0 0 15 3"
    >
      <path
        id="Path_plus"
        data-name="Path / plus"
        d="M19,13H5V11H19Z"
        transform="translate(-4.5 -10.5)"
        stroke="#dbe4ee"
        strokeWidth="1"
      />
    </svg>
  );
};

export const RotateSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 22 22"
    >
      <path
        id="Path_phone-rotate-landscape"
        data-name="Path / phone-rotate-landscape"
        d="M9,1H3A2,2,0,0,0,1,3V16a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V3A2,2,0,0,0,9,1M9,15H3V3H9V15m12-2H13v2h8v6H9V20H6v1a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V15a2,2,0,0,0-2-2m2-3L19,8l1.91-.91A7.516,7.516,0,0,0,14,2.5V1A9,9,0,0,1,23,10Z"
        transform="translate(-1 -1)"
      />
    </svg>
  );
};

export const MouseWheelSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 21 20"
    >
      <path
        id="Path_mouse-move-vertical"
        data-name="Path / mouse-move-vertical"
        d="M20,6h3L19,2,15,6h3V18H15l4,4,4-4H20V6M9,3.09A6.015,6.015,0,0,1,14,9H9V3.09M14,11v4A6,6,0,0,1,2,15V11H14M7,9H2A6.015,6.015,0,0,1,7,3.09Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};
