import ActionTypes from "../Constants";

const INITIALSTATE = {
  //add new project Reducers
  addNewProjectLoading: false,
  addNewProjectdata: "",
  addNewProjectError: "",

  //get portfolio project
  getPortfolioProjectLoading: false,
  getPortfolioProjectdata: "",
  getPortfolioProjectError: "",

  //delete portfolio project
  deletePortfolioProjectLoading: false,
  deletePortfolioProjectdata: "",
  deletePortfolioProjectError: "",

  //get sinlge portfolio project
  getSinglePortfolioProjectLoading: false,
  getSinglePortfolioProjectdata: "",
  getSinglePortfolioProjectError: "",

  //update sinlge portfolio project
  udpatePortfolioProjectLoading: false,
  udpatePortfolioProjectdata: "",
  udpatePortfolioProjectError: "",

  //profile cover portfolio project
  coverProfileProjectLoading: false,
  coverProfileProjectdata: "",
  coverProfileProjectError: "",
  updatedPortfolioProjectdata: "",
  //get sinlge portfolio project
  getDeletePortfolioProjectLoading: false,
  getDeletePortfolioProjectdata: "",
  getDeletePortfolioProjectError: "",

  //permanent DELETE portfolio project
  permanentDeletePortfolioProjectLoading: false,
  isPermanentDeleteProject: false,
  permanentDeletePortfolioProjectError: "",

  //restore DELETE portfolio project
  restoreDeletePortfolioProjectLoading: false,
  isRestoreDeleteProject: false,
  restoreDeletePortfolioProjectError: "",
};

const AddNewProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.ADD_NEW_PROJECT_REQUEST:
      return {
        ...state,
        addNewProjectLoading: true,
      };
    case ActionTypes.ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        addNewProjectLoading: false,
        addNewProjectdata: action.payload.data,
        addNewProjectError: "",
      };
    case ActionTypes.ADD_NEW_PROJECT_FAIL:
      return {
        ...state,
        addNewProjectLoading: false,
        addNewProjectdata: "",
        userLoginMessage: "",
        addNewProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const GetPortfolioProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_PORTFOLIO_PROJECT_REQUEST:
      return {
        ...state,
        getPortfolioProjectLoading: true,
      };
    case ActionTypes.GET_PORTFOLIO_PROJECT_SUCCESS:
      return {
        ...state,
        getPortfolioProjectLoading: false,
        getPortfolioProjectdata: action.payload.data,
        getPortfolioProjectError: "",
      };
    case ActionTypes.GET_PORTFOLIO_PROJECT_FAIL:
      return {
        ...state,
        getPortfolioProjectLoading: false,
        getPortfolioProjectdata: "",
        getPortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const DeletePortfolioProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.DELETE_PORTFOLIO_PROJECT_REQUEST:
      return {
        ...state,
        deletePortfolioProjectLoading: true,
      };
    case ActionTypes.DELETE_PORTFOLIO_PROJECT_SUCCESS:
      return {
        ...state,
        deletePortfolioProjectLoading: false,
        deletePortfolioProjectMessage: action.payload.message,
        deletePortfolioProjectError: "",
      };
    case ActionTypes.DELETE_PORTFOLIO_PROJECT_FAIL:
      return {
        ...state,
        deletePortfolioProjectLoading: false,
        deletePortfolioProjectMessage: "",
        deletePortfolioProjectError: action.payload.message,
      };
    case ActionTypes.EMPTY__DELETE_PORTFOLIO_PROJECT_STATE:
      return {
        ...state,
        deletePortfolioProjectLoading: false,
        deletePortfolioProjectMessage: "",
        deletePortfolioProjectError: "",
      };
    default:
      return state;
  }
};

const GetSinglePortfolioProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_SINGLE_PORTFOLIO_PROJECT_REQUEST:
      return {
        ...state,
        getSinglePortfolioProjectLoading: true,
      };
    case ActionTypes.GET_SINGLE_PORTFOLIO_PROJECT_SUCCESS:
      return {
        ...state,
        getSinglePortfolioProjectLoading: false,
        getSinglePortfolioProjectdata: action.payload.data,
        getSinglePortfolioProjectError: "",
      };
    case ActionTypes.GET_SINGLE_PORTFOLIO_PROJECT_FAIL:
      return {
        ...state,
        getSinglePortfolioProjectLoading: false,
        getSinglePortfolioProjectdata: "",
        getSinglePortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const UpdatePortfolioProjectReducers = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PORTFOLIO_PROJECT_REQUEST:
      return {
        ...state,
        udpatePortfolioProjectLoading: true,
      };
    case ActionTypes.UPDATE_PORTFOLIO_PROJECT_SUCCESS:
      return {
        ...state,
        udpatePortfolioProjectLoading: false,
        udpatePortfolioProjectdata: action.payload.data,
        udpatePortfolioProjectError: "",
      };
    case ActionTypes.UPDATE_PORTFOLIO_PROJECT_FAIL:
      return {
        ...state,
        udpatePortfolioProjectLoading: false,
        udpatePortfolioProjectdata: "",
        udpatePortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};
const ProfileCoverProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_COVER_PROJECT_REQUEST:
      return {
        ...state,
        coverProfileProjectLoading: true,
      };
    case ActionTypes.PROFILE_COVER_PROJECT_SUCCESS:
      const arr = action.payload.allProjectsData
      const index =
        action.payload.allProjectsData.records.findIndex((item) => item.slug === action.payload.projectSlug)
      arr.records[index] = action.payload.updatedProjectData

      return {
        ...state,
        coverProfileProjectLoading: false,
        coverProfileProjectdata: action.payload.updatedProjectData,
        updatedPortfolioProjectdata: arr,
        coverProfileProjectError: "",
      };
    case ActionTypes.PROFILE_COVER_PROJECT_FAIL:
      return {
        ...state,
        coverProfileProjectLoading: false,
        coverProfileProjectdata: "",
        updatedPortfolioProjectdata: "",
        coverProfileProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const GetDeletePortfolioProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_DELETE_PORTFOLIO_PROJECT_REQUEST:
      return {
        ...state,
        getDeletePortfolioProjectLoading: true,
      };
    case ActionTypes.GET_DELETE_PORTFOLIO_PROJECT_SUCCESS:
      return {
        ...state,
        getDeletePortfolioProjectLoading: false,
        getDeletePortfolioProjectdata: action.payload.data,
        getDeletePortfolioProjectError: "",
      };
    case ActionTypes.GET_DELETE_PORTFOLIO_PROJECT_FAIL:
      return {
        ...state,
        getDeletePortfolioProjectLoading: false,
        getDeletePortfolioProjectdata: "",
        getDeletePortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const PermanentDeleteProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.PERMANENT_DELETE_PROJECT_REQUEST:
      return {
        ...state,
        permanentDeletePortfolioProjectLoading: true,
      };
    case ActionTypes.PERMANENT_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        permanentDeletePortfolioProjectLoading: false,
        isPermanentDeleteProject: !state.isPermanentDeleteProject,
        permanentDeletePortfolioProjectError: "",
      };
    case ActionTypes.PERMANENT_DELETE_PROJECT_FAIL:
      return {
        ...state,
        permanentDeletePortfolioProjectLoading: false,
        permanentDeletePortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

const RestoreDeleteProjectReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case ActionTypes.RESTORE_DELETE_PROJECT_REQUEST:
      return {
        ...state,
        restoreDeletePortfolioProjectLoading: true,
      };
    case ActionTypes.RESTORE_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        restoreDeletePortfolioProjectLoading: false,
        isRestoreDeleteProject: !state.isRestoreDeleteProject,
        restoreDeletePortfolioProjectError: "",
      };
    case ActionTypes.RESTORE_DELETE_PROJECT_FAIL:
      return {
        ...state,
        restoreDeletePortfolioProjectLoading: false,
        restoreDeletePortfolioProjectError: action.payload.message,
      };
    default:
      return state;
  }
};

export {
  AddNewProjectReducer,
  GetPortfolioProjectReducer,
  DeletePortfolioProjectReducer,
  GetSinglePortfolioProjectReducer,
  UpdatePortfolioProjectReducers,
  ProfileCoverProjectReducer,
  GetDeletePortfolioProjectReducer,
  PermanentDeleteProjectReducer,
  RestoreDeleteProjectReducer,
};
