import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Navbar, SideBarNav } from "..";
import useGetBusinessArea from "../../Hooks/getBusinessArea";
import NewSideBarDesign from "../../Pages/Example/Example";

///NEW SIDEBAR

const drawerWidth = 240;

function Layout(props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [sideBarClose, setSideBarClose] = React.useState(true);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleSideBarToggle = () => {
		setSideBarClose(!sideBarClose);
	};

	const container =
		window !== undefined ? () => window().document.body : undefined;

	const businessArea = useGetBusinessArea();

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Navbar
				handleDrawerToggle={handleDrawerToggle}
				drawerWidth={drawerWidth}
				sideBarClose={sideBarClose}
				handleSideBarToggle={handleSideBarToggle}
			/>
			<Box
				component="nav"
				sx={{
					width: { lg: sideBarClose ? drawerWidth : "0" },
					flexShrink: { sm: 0 },
				}}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}

				{/* //MOBILE VIEW */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { md: "block", lg: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					<SideBarNav
						handleDrawerToggle={handleSideBarToggle}
						sideBarClose={sideBarClose}
						setSideBarClose={setSideBarClose}
					/>
				</Drawer>

				{/* ///WEB VEIW */}
				{/* <Drawer
          variant="persistent"
          anchor="left"
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={sideBarClose}
        >
          <SideBarNav icon={true} handleDrawerToggle={handleSideBarToggle} />
        </Drawer> */}

				<Box
					sx={{
						display: {
							xs: "none",
							lg: "block",
						},
					}}
				>
					<SideBarNav
						icon={true}
						handleDrawerToggle={handleSideBarToggle}
						sideBarClose={sideBarClose}
						setSideBarClose={setSideBarClose}
					/>
				</Box>
			</Box>

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: {
						sm: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
					},
					ml: !sideBarClose && "64px",
				}}
			>
				<Toolbar />
				{props.children}
			</Box>
		</Box>
	);
}

Layout.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default Layout;
