import React from "react";
import styles from "./ProfileCompletionForm.module.css";
import {
	LanguageIcon,
	FacebookOutlinedIcon,
	TwitterIcon,
	LinkedInIcon,
} from "../../Assets";
import { Box, Grid, Typography } from "@mui/material";
import { ButtonCmp, InputCmp, WhiteButtonCmp } from "../../Components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../Hooks/useAlert/useAlert";
import {
	EmptyProfileWizardState,
	ProfileWizardAction,
} from "../../Store/Actions/ProfileAction";
import { useEffect } from "react";

const SocialMedia = ({
	setWhichForm,
	setBarPercent,
	setSocialMediaComplete,
}) => {
	const { control, handleSubmit } = useForm({
		mode: "onChange",
		defaultValues: {
			website: "",
			facebook: "",
			twitter: "",
			linkedin: "",
		},
	});
	const navigate = useNavigate();
	///global states
	const { setAlert } = useAlert();
	const dispatch = useDispatch();
	const { profileWizarddata } = useSelector(
		(state) => state.ProfielWizardReducer
	);

	const onSubmit = (obj) => {
		console.log(obj, "obj");
		dispatch(ProfileWizardAction(obj, "socialMedia", setAlert));
	};

	useEffect(() => {
		if (profileWizarddata) {
			setWhichForm("yourConnection");
			setBarPercent("100%");
			setSocialMediaComplete(true);
			dispatch(EmptyProfileWizardState());
			navigate("/profile");
		}
	}, [profileWizarddata]);

	return (
		<section className={styles.profileInfo}>
			<div>
				<Typography
					noWrap
					variant="h5"
					fontWeight={"bold"}
				>
					Social media
				</Typography>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<section className={styles.socialLink_wrapper}>
					<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
						<div>
							<LanguageIcon
								style={{ color: "var(--lightIcon)", fontSize: "34px" }}
							/>
						</div>
						<Grid container>
							<Grid
								item
								lg={4}
							>
								<InputCmp
									name="website" //imporant
									control={control} //imporant
									label="Website"
									type={"url"}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
						<div>
							<FacebookOutlinedIcon
								style={{ color: "var(--lightIcon)", fontSize: "34px" }}
							/>
						</div>
						<Grid container>
							<Grid
								item
								lg={4}
							>
								<InputCmp
									name="facebook" //imporant
									control={control} //imporant
									label="Facebook"
									type={"url"}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
						<div>
							<TwitterIcon
								style={{ color: "var(--lightIcon)", fontSize: "34px" }}
							/>
						</div>
						<Grid container>
							<Grid
								item
								lg={4}
							>
								<InputCmp
									name="twitter" //imporant
									control={control} //imporant
									label="Twitter"
									type={"url"}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
						<div>
							<LinkedInIcon
								style={{ color: "var(--lightIcon)", fontSize: "34px" }}
							/>
						</div>
						<Grid container>
							<Grid
								item
								lg={4}
							>
								<InputCmp
									name="linkedin" //imporant
									control={control} //imporant
									label="Linkedin"
									type={"url"}
								/>
							</Grid>
						</Grid>
					</Box>
				</section>

				{/* ////btn */}
				<section className={styles.formBtns}>
					<WhiteButtonCmp
						title="Cancel"
						onClick={() => {
							navigate("/profile");
						}}
					/>
					<ButtonCmp
						title="Skip Step"
						onClick={() => {
							// setWhichForm("yourConnection");
							navigate("/profile");
						}}
					/>
					<ButtonCmp
						title="Save and Continue"
						type="submit"
					/>
				</section>
			</form>
		</section>
	);
};

export default SocialMedia;
