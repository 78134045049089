import {
  getMethodCustomHeader,
  postMethodCustomHeader,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const GetAllNotification = (perPage, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_REQUEST,
      });

      const response = await getMethodCustomHeader(
        // `user/notifications?per_page=${perPage}&page=${page}`
        `user/notifications`
      );
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_NOTIFICATION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.message, "error");
      console.log(error?.message, "error");
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetNotificationCount = (setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_COUNT_REQUEST,
      });

      const response = await getMethodCustomHeader(`user/notifications-count`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_NOTIFICATION_COUNT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.GET_NOTIFICATION_COUNT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.message, "error");
      console.log(error?.message, "error");
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_COUNT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MarkAllNotification = (setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.MARKALL_NOTIFICATION_REQUEST,
      });

      const response = await postMethodCustomHeader(
        `user/mark-all-notification-seen`
      );
      console.log("respoSnse notificarion connection", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.MARKALL_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.MARKALL_NOTIFICATION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.message, "error");
      console.log(error?.message, "error");
      dispatch({
        type: ActionTypes.MARKALL_NOTIFICATION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MarkNotification = (id, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.MARK_NOTIFICATION_REQUEST,
      });

      const obj = {
        is_seen: 1,
      };
      const response = await postMethodCustomHeader(
        `user/mark-notification-seen/${id}`,
        obj
      );
      console.log("respoSnse notificarion connection", response);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.MARK_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.MARK_NOTIFICATION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.message, "error");
      console.log(error?.message, "error");
      dispatch({
        type: ActionTypes.MARK_NOTIFICATION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const NotificationCountZero = (setAlert) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.NOTIFICATION_COUNT_ZERO,
    });
  };
};

export {
  GetAllNotification,
  GetNotificationCount,
  MarkAllNotification,
  NotificationCountZero,
  MarkNotification,
};
