import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { EditProfileSvg, BiEdit, EyeSvg } from "../../../Assets";
import { GetAllProductsAction } from "../../../Store/Actions/BuildStudio/BuildStudioAction";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../../Hooks/useAlert/useAlert";
import { useNavigate } from "react-router-dom";
import LoaderCmp from "../../LoaderCmp/LoaderCmp";

const dummyProduct = [
  {
    id: 112,
    user_id: 43,
    brand_id: 2,
    material_id: 2,
    distributor_id: 1,
    supplier_id: 1,
    coat_one_id: 1,
    coat_two_id: 2,
    category_id: 11,
    primer_id: 1,
    pattern_id: 2,
    glass_type_id: 1,
    title: "testing 2",
    slug: "testing-2",
    model_no: "12156",
    model_name: "CG",
    other: "asdasd",
    depth: "asd",
    finish: "asdasd",
    type: "asd",
    installer: "asd",
    item: "aasd",
    item_no: "asd",
    do_not_include: "asdas",
    custom: "asd",
    joint: "qwe",
    sku: "1asd",
    description: "asdasdasd asdasd asdas",
    style: "assd",
    sdl: "asd",
    rooms: "22",
    width: "10.00",
    height: "5255.00",
    length: "12.00",
    quantity: 2,
    status: 1,
    created_by: null,
    updated_by: null,
    deleted_by: null,
    deleted_at: null,
    created_at: "2022-12-12T17:25:49.000000Z",
    updated_at: "2022-12-12T17:25:49.000000Z",
    ImgURL: "https://buildlink.designfinix.com/uploads/products/",
    ImgPublic:
      "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/products/",
    label: "testing 2",
    user: {
      id: 43,
      type: "Manufacturer",
      name: "testing account",
      first_name: "testing",
      last_name: "account",
      phone: null,
      dob: null,
      gender: "Not to Answer",
      company_name: null,
      company_email: null,
      email: "testing@gmail.com",
      street_address: null,
      city_id: null,
      state_id: null,
      country_id: null,
      zip: null,
      year_est: null,
      website: null,
      licensed_profession: null,
      licensed_number: null,
      fein_corporate_charter_no: null,
      bonded: false,
      general_liability_insurance: false,
      gli_policy: null,
      gli_max: null,
      worker_comp: false,
      wc_policy: null,
      wc_max: null,
      business_description: null,
      affiliations: null,
      certifications: null,
      facebook_link: null,
      twitter_link: null,
      linkedin_link: null,
      youtube_link: null,
      email_verified_at: null,
      otp: null,
      image: "no_image.png",
      cover_image: "no_image.png",
      reset_activation: null,
      login_first_time: false,
      is_verify: true,
      status: 1,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-10-31",
      updated_at: "2022-10-31",
      ImgURL: "https://buildlink.designfinix.com/uploads/users/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/users/",
      label: "testing account",
      rating: 4.2,
      is_connected: false,
      sent_request_pending: false,
      received_request_pending: false,
      all_roles: ["Manufacturer"],
    },
    brand: {
      id: 2,
      title: "Brand 2",
      slug: "brand-2",
      image: null,
      logo: "brands_1660428338.jpg",
      description:
        "Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:05:38.000000Z",
      updated_at: "2022-08-13T17:05:38.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/brands/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/brands/",
      label: "Brand 2",
    },
    pattern: {
      id: 2,
      title: "Pattern 2",
      slug: "pattern-2",
      image: "patterns_1665136083.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-10-07T09:48:03.000000Z",
      updated_at: "2022-10-07T09:48:03.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/patterns/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/patterns/",
      label: "Pattern 2",
    },
    category: {
      id: 11,
      title: "Bridges & Boardwalks",
      slug: "bridges-boardwalks",
      image: null,
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: null,
      updated_at: "2022-10-22T20:56:25.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/categories/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/categories/",
      label: "Bridges & Boardwalks",
    },
    distributor: {
      id: 1,
      title: "Maxime itaque dolor",
      slug: "maxime-itaque-dolor",
      image: "distributors_1660430563.jpg",
      email: "feterota@mailinator.com",
      phone: "+1 (583) 253-4662",
      whatsapp_no: "+1 (583) 253-4662",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:42:43.000000Z",
      updated_at: "2022-08-13T17:42:43.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/distributors/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/distributors/",
      label: "Maxime itaque dolor",
    },
    supplier: {
      id: 1,
      title: "Voluptatem nobis qua",
      slug: "voluptatem-nobis-qua",
      image: "suppliers_1660429864.png",
      phone: "+1 (171) 936-3871",
      email: "lowor@mailinator.com",
      whatsapp_no: "+1 (171) 936-3871",
      status: 0,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:31:04.000000Z",
      updated_at: "2022-08-13T17:33:14.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/suppliers/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/suppliers/",
      label: "Voluptatem nobis qua",
    },
    glass_type: {
      id: 1,
      title: "Optio enim voluptat",
      slug: "optio-enim-voluptat",
      image: "glass_types_1662737502.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-09-09T12:31:42.000000Z",
      updated_at: "2022-09-09T12:31:42.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/glass_types/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/glass_types/",
      label: "Optio enim voluptat",
    },
    material: {
      id: 2,
      title: "Material two",
      slug: "material-two",
      logo: null,
      image: "materials_1660427426.png",
      description: "Voluptas est ipsum",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T16:50:26.000000Z",
      updated_at: "2022-08-13T16:50:26.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/materials/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/materials/",
      label: "Material two",
    },
    coat_one: {
      id: 1,
      title: "Coat 1",
      slug: "coat-1",
      image: "coats_1660404578.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:29:38.000000Z",
      updated_at: "2022-08-13T10:29:57.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/coats/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/coats/",
      label: "Coat 1",
    },
    coat_two: {
      id: 2,
      title: "Coat 2",
      slug: "coat-2",
      image: "coats_1660404578.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:29:38.000000Z",
      updated_at: "2022-08-13T10:29:57.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/coats/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/coats/",
      label: "Coat 2",
    },
    primer: {
      id: 1,
      title: "olia",
      slug: "olia",
      image: "primers_1660404822.jpg",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:33:42.000000Z",
      updated_at: "2022-08-13T10:33:42.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/primers/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/primers/",
      label: "olia",
    },
    images: [
      {
        id: 80,
        product_id: 112,
        image: "product-2577-1670865949.jfif",
        is_thumbnail: 1,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: 81,
        product_id: 112,
        image: "product-63588-1670865949.jpg",
        is_thumbnail: 0,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
      },
    ],
    symbols: [
      {
        id: 143,
        product_id: 112,
        symbol_id: 56,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        symbol: {
          id: 56,
          title: "quad outlet 2",
          slug: "quad-outlet-2",
          image: "symbols_1670350292.png",
          svg_image: null,
          type: "Pro Symbol",
          service_id: 2,
          status: 1,
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: "2022-12-06T18:11:32.000000Z",
          updated_at: "2022-12-06T18:11:32.000000Z",
          ImgURL: "https://buildlink.designfinix.com/uploads/symbols/",
          ImgPublic:
            "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/symbols/",
          label: "quad outlet 2",
        },
      },
      {
        id: 144,
        product_id: 112,
        symbol_id: 10,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        symbol: {
          id: 10,
          title: "220 volt outlet",
          slug: "220-volt-outlet",
          image: "symbols_1669749667.png",
          svg_image: null,
          type: "Pro Symbol",
          service_id: 27,
          status: 1,
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: "2022-11-29T16:38:42.000000Z",
          updated_at: "2022-11-29T19:21:07.000000Z",
          ImgURL: "https://buildlink.designfinix.com/uploads/symbols/",
          ImgPublic:
            "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/symbols/",
          label: "220 volt outlet",
        },
      },
    ],
    colors: [
      {
        id: 202,
        product_id: 112,
        color_id: 2,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        color: {
          id: 2,
          title: "Green",
          slug: "green",
          code: "#b93131",
          status: 1,
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: "2022-08-13T13:30:33.000000Z",
          updated_at: "2022-08-13T13:30:33.000000Z",
          label: "Green",
        },
      },
      {
        id: 203,
        product_id: 112,
        color_id: 3,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        color: {
          id: 3,
          title: "Red",
          slug: "red",
          code: "#ff0000",
          status: 1,
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: "2022-08-13T18:59:29.000000Z",
          updated_at: "2022-08-13T18:59:29.000000Z",
          label: "Red",
        },
      },
    ],
  },
  {
    id: 111,
    user_id: 43,
    brand_id: 2,
    material_id: 2,
    distributor_id: 1,
    supplier_id: 1,
    coat_one_id: 2,
    coat_two_id: 1,
    category_id: 12,
    primer_id: 1,
    pattern_id: 2,
    glass_type_id: 1,
    title: "testing",
    slug: "testing",
    model_no: "123123",
    model_name: "aasd",
    other: "asdasd",
    depth: "asd",
    finish: "asda",
    type: "asd",
    installer: "asd",
    item: "asd",
    item_no: "asd",
    do_not_include: "asd",
    custom: "asda",
    joint: "123",
    sku: "asd",
    description: "adadasdasd",
    style: "asdas",
    sdl: "agfasf",
    rooms: "123",
    width: "2165.00",
    height: "165.00",
    length: "12135.00",
    quantity: 22,
    status: 1,
    created_by: null,
    updated_by: null,
    deleted_by: null,
    deleted_at: null,
    created_at: "2022-11-15T20:34:51.000000Z",
    updated_at: "2022-11-15T20:34:51.000000Z",
    ImgURL: "https://buildlink.designfinix.com/uploads/products/",
    ImgPublic:
      "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/products/",
    label: "testing",
    user: {
      id: 43,
      type: "Manufacturer",
      name: "testing account",
      first_name: "testing",
      last_name: "account",
      phone: null,
      dob: null,
      gender: "Not to Answer",
      company_name: null,
      company_email: null,
      email: "testing@gmail.com",
      street_address: null,
      city_id: null,
      state_id: null,
      country_id: null,
      zip: null,
      year_est: null,
      website: null,
      licensed_profession: null,
      licensed_number: null,
      fein_corporate_charter_no: null,
      bonded: false,
      general_liability_insurance: false,
      gli_policy: null,
      gli_max: null,
      worker_comp: false,
      wc_policy: null,
      wc_max: null,
      business_description: null,
      affiliations: null,
      certifications: null,
      facebook_link: null,
      twitter_link: null,
      linkedin_link: null,
      youtube_link: null,
      email_verified_at: null,
      otp: null,
      image: "no_image.png",
      cover_image: "no_image.png",
      reset_activation: null,
      login_first_time: false,
      is_verify: true,
      status: 1,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-10-31",
      updated_at: "2022-10-31",
      ImgURL: "https://buildlink.designfinix.com/uploads/users/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/users/",
      label: "testing account",
      rating: 4.2,
      is_connected: false,
      sent_request_pending: false,
      received_request_pending: false,
      all_roles: ["Manufacturer"],
    },
    brand: {
      id: 2,
      title: "Brand 2",
      slug: "brand-2",
      image: null,
      logo: "brands_1660428338.jpg",
      description:
        "Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim Molestiae atque enim",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:05:38.000000Z",
      updated_at: "2022-08-13T17:05:38.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/brands/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/brands/",
      label: "Brand 2",
    },
    pattern: {
      id: 2,
      title: "Pattern 2",
      slug: "pattern-2",
      image: "patterns_1665136083.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-10-07T09:48:03.000000Z",
      updated_at: "2022-10-07T09:48:03.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/patterns/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/patterns/",
      label: "Pattern 2",
    },
    category: {
      id: 12,
      title: "Maintenance Equipment",
      slug: "maintenance-equipment",
      image: null,
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: null,
      updated_at: "2022-10-22T20:56:25.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/categories/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/categories/",
      label: "Maintenance Equipment",
    },
    distributor: {
      id: 1,
      title: "Maxime itaque dolor",
      slug: "maxime-itaque-dolor",
      image: "distributors_1660430563.jpg",
      email: "feterota@mailinator.com",
      phone: "+1 (583) 253-4662",
      whatsapp_no: "+1 (583) 253-4662",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:42:43.000000Z",
      updated_at: "2022-08-13T17:42:43.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/distributors/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/distributors/",
      label: "Maxime itaque dolor",
    },
    supplier: {
      id: 1,
      title: "Voluptatem nobis qua",
      slug: "voluptatem-nobis-qua",
      image: "suppliers_1660429864.png",
      phone: "+1 (171) 936-3871",
      email: "lowor@mailinator.com",
      whatsapp_no: "+1 (171) 936-3871",
      status: 0,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T17:31:04.000000Z",
      updated_at: "2022-08-13T17:33:14.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/suppliers/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/suppliers/",
      label: "Voluptatem nobis qua",
    },
    glass_type: {
      id: 1,
      title: "Optio enim voluptat",
      slug: "optio-enim-voluptat",
      image: "glass_types_1662737502.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-09-09T12:31:42.000000Z",
      updated_at: "2022-09-09T12:31:42.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/glass_types/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/glass_types/",
      label: "Optio enim voluptat",
    },
    material: {
      id: 2,
      title: "Material two",
      slug: "material-two",
      logo: null,
      image: "materials_1660427426.png",
      description: "Voluptas est ipsum",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T16:50:26.000000Z",
      updated_at: "2022-08-13T16:50:26.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/materials/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/materials/",
      label: "Material two",
    },
    coat_one: {
      id: 2,
      title: "Coat 2",
      slug: "coat-2",
      image: "coats_1660404578.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:29:38.000000Z",
      updated_at: "2022-08-13T10:29:57.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/coats/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/coats/",
      label: "Coat 2",
    },
    coat_two: {
      id: 1,
      title: "Coat 1",
      slug: "coat-1",
      image: "coats_1660404578.png",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:29:38.000000Z",
      updated_at: "2022-08-13T10:29:57.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/coats/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/coats/",
      label: "Coat 1",
    },
    primer: {
      id: 1,
      title: "olia",
      slug: "olia",
      image: "primers_1660404822.jpg",
      status: 1,
      created_by: null,
      updated_by: null,
      deleted_by: null,
      deleted_at: null,
      created_at: "2022-08-13T10:33:42.000000Z",
      updated_at: "2022-08-13T10:33:42.000000Z",
      ImgURL: "https://buildlink.designfinix.com/uploads/primers/",
      ImgPublic:
        "/home/u114183004/domains/designfinix.com/public_html/buildlink/public/uploads/primers/",
      label: "olia",
    },
    images: [
      {
        id: 77,
        product_id: 111,
        image: "product-86716-1668544492.jpg",
        is_thumbnail: 1,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: 78,
        product_id: 111,
        image: "product-68303-1668544492.jfif",
        is_thumbnail: 0,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
      },
      {
        id: 79,
        product_id: 111,
        image: "product-62493-1668544492.png",
        is_thumbnail: 0,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
      },
    ],
    symbols: [],
    colors: [
      {
        id: 201,
        product_id: 111,
        color_id: 2,
        created_by: null,
        updated_by: null,
        deleted_by: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        color: {
          id: 2,
          title: "Green",
          slug: "green",
          code: "#b93131",
          status: 1,
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: "2022-08-13T13:30:33.000000Z",
          updated_at: "2022-08-13T13:30:33.000000Z",
          label: "Green",
        },
      },
    ],
  },
];

const columns = [
  {
    id: "images",
    label: "Image",
    minWidth: 100,
  },
  { id: "symbols", label: "Symbol", minWidth: 50 },
  {
    id: "label",
    label: "Name",
    minWidth: 150,
  },
  {
    id: "brand",
    label: "Brand",
    minWidth: 150,
  },
  //   {
  //     id: "type",
  //     label: "Type",
  //     minWidth: 50,
  //   },
  {
    id: "model_no",
    label: "Model No.",
    minWidth: 50,
  },
  {
    id: "colors",
    label: "Color",
    minWidth: 150,
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function StickyHeadTable({ handleClose, setViewProductModal }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allProducts, setAllProducts] = React.useState([]);
  const [hoverElement, setHoverElement] = React.useState({
    index: null,
    hover: false,
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { getAllProductLoading, getAllProductdata } = useSelector(
    (state) => state.GetAllProductsReducer
  );
  React.useEffect(() => {
    dispatch(GetAllProductsAction(null, null, setAlert));
  }, []);
  React.useEffect(() => {
    if (getAllProductdata) {
      const data = getAllProductdata.records;
      const images = data?.map((product) => {
        return product.images.filter((image) => {
          if (image.is_thumbnail === 1) {
            return image;
          }
        });
      });

      const tempArr = getAllProductdata?.records?.map((product) => {
        return {
          ...product,
          images: [...images],
          brand: product.brand.label,
        };
      });

      setAllProducts([...tempArr]);
    }
  }, [getAllProductdata]);

  console.log("getAllProductdata", getAllProductdata);

  return getAllProductLoading ? (
    <LoaderCmp />
  ) : (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        height: "100%",
        boxShadow: "none",
      }}
    >
      <TableContainer sx={{ height: "90%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allProducts?.map((row, index) => {
              console.log("row asjkdhaksjd", row);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  onMouseEnter={() =>
                    setHoverElement({
                      hover: true,
                      index: index,
                    })
                  }
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "images" ? (
                          <img
                            src={`${row.ImgURL}${value[index][0].image}`}
                            width="120"
                            height={"80"}
                          />
                        ) : column.id === "brand" ? (
                          <Typography>{value}</Typography>
                        ) : column.id === "model_no" ? (
                          <Typography>{value}</Typography>
                        ) : column.id === "label" ? (
                          <Typography>{value}</Typography>
                        ) : column.id === "symbols" ? (
                          value.length ? (
                            value?.map((symbol) => {
                              return (
                                <img
                                  key={symbol.symbol.id}
                                  src={
                                    symbol?.symbol?.ImgURL +
                                    symbol?.symbol?.image
                                  }
                                  width="50"
                                  height="50"
                                />
                              );
                            })
                          ) : (
                            "no symbols add"
                          )
                        ) : column.id === "colors" ? (
                          hoverElement.hover && hoverElement.index === index ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}
                            >
                              {/* <Box>
                            <BiEdit />
                            <Typography>Edit</Typography>
                          </Box> */}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClose();
                                  setViewProductModal(true);
                                  navigation(`?product=${row.slug}`);
                                  console.log("row.slug", row.slug);
                                }}
                              >
                                <EyeSvg />
                                <Typography>View</Typography>
                              </Box>
                            </Box>
                          ) : (
                            value?.map((color) => {
                              return (
                                <Box
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: color?.color?.code,
                                    display: "inline-block",
                                    mr: "8px",
                                    borderRadius: "50%",
                                  }}
                                  key={color?.color.id}
                                ></Box>
                              );
                            })
                          )
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dummyProduct?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
