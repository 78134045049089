import React from "react";
import Button from "@mui/material/Button";

const WhiteButtonCmp = ({
  title,
  style,
  variant,
  whiteBtn,
  onClick,
  ...props
}) => {
  return (
    <>
      <Button
        variant={variant ? variant : "outlined"}
        sx={{
          borderRadius: 10,
          fontSize: "12px",
          minWidth: "160px",
          padding: "13px 20px ",
          color: "black !important",
          border: "1px solid black !important",
          ...style,
        }}
        onClick={onClick}
        {...props}
      >
        {title ? title : "Button"}
      </Button>
    </>
  );
};

export default WhiteButtonCmp;
