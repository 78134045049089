const ActionTypes = {
  EXAMPLE_TYPE_REQUEST: "EXAMPLE_TYPE_REQUEST",
  EXAMPLE_TYPE_SUCCESS: "EXAMPLE_TYPE_SUCCESS",
  EXAMPLE_TYPE_FAIL: "EXAMPLE_TYPE_FAIL",

  USER_CONFIRMPASSWORD_REQUEST: "USER_CONFIRMPASSWORD_REQUEST",
  USER_CONFIRMPASSWORD_SUCCESS: "USER_CONFIRMPASSWORD_SUCCESS",
  USER_CONFIRMPASSWORD_FAIL: "USER_CONFIRMPASSWORD_FAIL",

  USER_FORGET_REQUEST: "USER_FORGET_REQUEST",
  USER_FORGET_SUCCESS: "USER_FORGET_SUCCESS",
  USER_FORGET_FAIL: "USER_FORGET_FAIL",

  CODE_VERIFICATION_REQUEST: "CODE_VERIFICATION_REQUEST",
  CODE_VERIFICATION_SUCCESS: "CODE_VERIFICATION_SUCCESS",
  CODE_VERIFICATION_FAIL: "CODE_VERIFICATION_FAIL",

  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  USER_SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_FAIL: "USER_SIGNUP_FAIL",

  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",

  ///GETR ALL USER CONSTANT
  GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
  GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
  GET_ALL_USER_FAIL: "GET_ALL_USER_FAIL",

  //update device id
  UPDATE_DEVICEID_REQUEST: "UPDATE_DEVICEID_REQUEST",
  UPDATE_DEVICEID_SUCCESS: "UPDATE_DEVICEID_SUCCESS",
  UPDATE_DEVICEID_FAIL: "UPDATE_DEVICEID_FAIL",

  ///cities
  GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAIL: "GET_CITIES_FAIL",

  // GENERAL ACTIONS

  ///state
  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAIL: "GET_STATES_FAIL",

  //services
  GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_FAIL: "GET_SERVICES_FAIL",

  //licensed services
  GET_LICENSED_SERVICES_REQUEST: "GET_LICENSED_SERVICES_REQUEST",
  GET_LICENSED_SERVICES_SUCCESS: "GET_LICENSED_SERVICES_SUCCESS",
  GET_LICENSED_SERVICES_FAIL: "GET_LICENSED_SERVICES_FAIL",

  //other services
  GET_OTHER_SERVICES_REQUEST: "GET_OTHER_SERVICES_REQUEST",
  GET_OTHER_SERVICES_SUCCESS: "GET_OTHER_SERVICES_SUCCESS",
  GET_OTHER_SERVICES_FAIL: "GET_OTHER_SERVICES_FAIL",

  ///Brands
  GET_BRANDS_REQUEST: "GET_BRANDS_REQUEST",
  GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  GET_BRANDS_FAIL: "GET_BRANDS_FAIL",

  ///COLORS
  GET_COLORS_REQUEST: "GET_COLORS_REQUEST",
  GET_COLORS_SUCCESS: "GET_COLORS_SUCCESS",
  GET_COLORS_FAIL: "GET_COLORS_FAIL",

  ///COLORS
  GET_FINISHINGS_REQUEST: "GET_FINISHINGS_REQUEST",
  GET_FINISHINGS_SUCCESS: "GET_FINISHINGS_SUCCESS",
  GET_FINISHINGS_FAIL: "GET_FINISHINGS_FAIL",

  ///MATERIALS
  GET_MATERIALS_REQUEST: "GET_MATERIALS_REQUEST",
  GET_MATERIALS_SUCCESS: "GET_MATERIALS_SUCCESS",
  GET_MATERIALS_FAIL: "GET_MATERIALS_FAIL",

  ///Coats
  GET_COATS_REQUEST: "GET_COATS_REQUEST",
  GET_COATS_SUCCESS: "GET_COATS_SUCCESS",
  GET_COATS_FAIL: "GET_COATS_FAIL",

  ///Primers
  GET_PRIMERS_REQUEST: "GET_PRIMERS_REQUEST",
  GET_PRIMERS_SUCCESS: "GET_PRIMERS_SUCCESS",
  GET_PRIMERS_FAIL: "GET_PRIMERS_FAIL",

  ///Patterns
  GET_PATTERNS_REQUEST: "GET_PATTERNS_REQUEST",
  GET_PATTERNS_SUCCESS: "GET_PATTERNS_SUCCESS",
  GET_PATTERNS_FAIL: "GET_PATTERNS_FAIL",

  ///glass types
  GET_GLASS_TYPES_REQUEST: "GET_GLASS_TYPES_REQUEST",
  GET_GLASS_TYPES_SUCCESS: "GET_GLASS_TYPES_SUCCESS",
  GET_GLASS_TYPES_FAIL: "GET_GLASS_TYPES_FAIL",

  ///distributors
  GET_DISTRIBUTORS_REQUEST: "GET_DISTRIBUTORS_REQUEST",
  GET_DISTRIBUTORS_SUCCESS: "GET_DISTRIBUTORS_SUCCESS",
  GET_DISTRIBUTORS_FAIL: "GET_DISTRIBUTORS_FAIL",

  ///suppliers
  GET_SUPPLIERS_REQUEST: "GET_SUPPLIERS_REQUEST",
  GET_SUPPLIERS_SUCCESS: "GET_SUPPLIERS_SUCCESS",
  GET_SUPPLIERS_FAIL: "GET_SUPPLIERS_FAIL",

  ///USER ROLE
  GET_USER_ROLE: "GET_USER_ROLE",

  //USER PROFILE
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAIL: "GET_USER_PROFILE_FAIL",

  //SINGLE USER PROFILE
  GET_SINGLE_USER_PROFILE_REQUEST: "GET_SINGLE_USER_PROFILE_REQUEST",
  GET_SINGLE_USER_PROFILE_SUCCESS: "GET_SINGLE_USER_PROFILE_SUCCESS",
  GET_SINGLE_USER_PROFILE_FAIL: "GET_SINGLE_USER_PROFILE_FAIL",

  //ALL USER PROJECTS
  GET_ALLUSER_PROJECT_REQUEST: "GET_ALLUSER_PROJECT_REQUEST",
  GET_ALLUSER_PROJECT_SUCCESS: "GET_ALLUSER_PROJECT_SUCCESS",
  GET_ALLUSER_PROJECT_FAIL: "GET_ALLUSER_PROJECT_FAIL",

  //AWARDED PROJECTS
  GET_AWARDED_PROJECT_REQUEST: "GET_AWARDED_PROJECT_REQUEST",
  GET_AWARDED_PROJECT_SUCCESS: "GET_AWARDED_PROJECT_SUCCESS",
  GET_AWARDED_PROJECT_FAIL: "GET_AWARDED_PROJECT_FAIL",

  //MY BIDS PROJECT PROJECTS
  GET_MYBIDS_PROJECT_REQUEST: "GET_MYBIDS_PROJECT_REQUEST",
  GET_MYBIDS_PROJECT_SUCCESS: "GET_MYBIDS_PROJECT_SUCCESS",
  GET_MYBIDS_PROJECT_FAIL: "GET_MYBIDS_PROJECT_FAIL",

  //MY BIDS PROJECT DETAILS
  GET_MYBIDS_PROJECT_DETAILS_REQUEST: "GET_MYBIDS_PROJECT_DETAILS_REQUEST",
  GET_MYBIDS_PROJECT_DETAILS_SUCCESS: "GET_MYBIDS_PROJECT_DETAILS_SUCCESS",
  GET_MYBIDS_PROJECT_DETAILS_FAIL: "GET_MYBIDS_PROJECT_DETAILS_FAIL",

  //MY BIDS CREATE PROPOSAL
  MYBIDS_CREATE_PROPOSAL_REQUEST: "MYBIDS_CREATE_PROPOSAL_REQUEST",
  MYBIDS_CREATE_PROPOSAL_SUCCESS: "MYBIDS_CREATE_PROPOSAL_SUCCESS",
  MYBIDS_CREATE_PROPOSAL_FAIL: "MYBIDS_CREATE_PROPOSAL_FAIL",

  //MY BIDS SUBMITTED BID DETAILS
  GET_MYBIDS_SUBMITTED_BID_DETAILS_REQUEST:
    "GET_MYBIDS_SUBMITTED_BID_DETAILS_REQUEST",
  GET_MYBIDS_SUBMITTED_BID_DETAILS_SUCCESS:
    "GET_MYBIDS_SUBMITTED_BID_DETAILS_SUCCESS",
  GET_MYBIDS_SUBMITTED_BID_DETAILS_FAIL:
    "GET_MYBIDS_SUBMITTED_BID_DETAILS_FAIL",

  //MY BIDS UPDATE PROPOSAL
  MYBIDS_UPDATE_PROPOSAL_REQUEST: "MYBIDS_UPDATE_PROPOSAL_REQUEST",
  MYBIDS_UPDATE_PROPOSAL_SUCCESS: "MYBIDS_UPDATE_PROPOSAL_SUCCESS",
  MYBIDS_UPDATE_PROPOSAL_FAIL: "MYBIDS_UPDATE_PROPOSAL_FAIL",

  //MY BIDS ADD TO WISHLIST
  MYBIDS_ADD_TO_WISHLIST_REQUEST: "MYBIDS_ADD_TO_WISHLIST_REQUEST",
  MYBIDS_ADD_TO_WISHLIST_SUCCESS: "MYBIDS_ADD_TO_WISHLIST_SUCCESS",
  MYBIDS_ADD_TO_WISHLIST_FAIL: "MYBIDS_ADD_TO_WISHLIST_FAIL",

  //MY BIDS AWARDED PROJECTS
  GET_MYBIDS_AWARDED_PROJECT_REQUEST: "GET_MYBIDS_AWARDED_PROJECT_REQUEST",
  GET_MYBIDS_AWARDED_PROJECT_SUCCESS: "GET_MYBIDS_AWARDED_PROJECT_SUCCESS",
  GET_MYBIDS_AWARDED_PROJECT_FAIL: "GET_MYBIDS_AWARDED_PROJECT_FAIL",

  // MY BIDS WISHLISTED PROJECTS
  GET_MYBIDS_WISHLISTED_PROJECT_REQUEST:
    "GET_MYBIDS_WISHLISTED_PROJECT_REQUEST",
  GET_MYBIDS_WISHLISTED_PROJECT_SUCCESS:
    "GET_MYBIDS_WISHLISTED_PROJECT_SUCCESS",
  GET_MYBIDS_WISHLISTED_PROJECT_FAIL: "GET_MYBIDS_WISHLISTED_PROJECT_FAIL",

  // MY BIDS APPLIED PROJECTS
  GET_MYBIDS_APPLIED_PROJECT_REQUEST: "GET_MYBIDS_APPLIED_PROJECT_REQUEST",
  GET_MYBIDS_APPLIED_PROJECT_SUCCESS: "GET_MYBIDS_APPLIED_PROJECT_SUCCESS",
  GET_MYBIDS_APPLIED_PROJECT_FAIL: "GET_MYBIDS_APPLIED_PROJECT_FAIL",

  //MY BIDS COMPLETED PROJECT PROJECTS
  GET_MYBIDS_COMPLETED_PROJECT_REQUEST: "GET_MYBIDS_COMPLETED_PROJECT_REQUEST",
  GET_MYBIDS_COMPLETED_PROJECT_SUCCESS: "GET_MYBIDS_COMPLETED_PROJECT_SUCCESS",
  GET_MYBIDS_COMPLETED_PROJECT_FAIL: "GET_MYBIDS_COMPLETED_PROJECT_FAIL",

  //Create Job
  CREATE_JOB_REQUEST: "CREATE_JOB_REQUEST",
  CREATE_JOB_SUCCESS: "CREATE_JOB_SUCCESS",
  CREATE_JOB_FAIL: "CREATE_JOB_FAIL",

  //single Job detail
  SINGLE_JOB_REQUEST: "SINGLE_JOB_REQUEST",
  SINGLE_JOB_SUCCESS: "SINGLE_JOB_SUCCESS",
  SINGLE_JOB_FAIL: "SINGLE_JOB_FAIL",

  //single Bids detail
  SINGLE_BID_REQUEST: "SINGLE_BID_REQUEST",
  SINGLE_BID_SUCCESS: "SINGLE_BID_SUCCESS",
  SINGLE_BID_FAIL: "SINGLE_BID_FAIL",

  //delete job detail
  DELETE_JOB_REQUEST: "DELETE_JOB_REQUEST",
  DELETE_JOB_SUCCESS: "DELETE_JOB_SUCCESS",
  DELETE_JOB_FAIL: "DELETE_JOB_FAIL",

  //get delete job detail
  GET_DELETE_JOB_REQUEST: "GET_DELETE_JOB_REQUEST",
  GET_DELETE_JOB_SUCCESS: "GET_DELETE_JOB_SUCCESS",
  GET_DELETE_JOB_FAIL: "GET_DELETE_JOB_FAIL",
  EMPTY__DELETE_JOB_STATE: "EMPTY__DELETE_JOB_STATE",

  //get delete job detail
  GET_COMPLETED_JOB_REQUEST: "GET_COMPLETED_JOB_REQUEST",
  GET_COMPLETED_JOB_SUCCESS: "GET_COMPLETED_JOB_SUCCESS",
  GET_COMPLETED_JOB_FAIL: "GET_COMPLETED_JOB_FAIL",

  //restore delete job detail
  RESTORE_DELETE_JOB_REQUEST: "RESTORE_DELETE_JOB_REQUEST",
  RESTORE_DELETE_JOB_SUCCESS: "RESTORE_DELETE_JOB_SUCCESS",
  RESTORE_DELETE_JOB_FAIL: "RESTORE_DELETE_JOB_FAIL",
  EMPTY_RESTORE_DELETE_STATE: "EMPTY_RESTORE_DELETE_STATE",

  //permanent delete job detail
  PERMANENT_DELETE_JOB_REQUEST: "PERMANENT_DELETE_JOB_REQUEST",
  PERMANENT_DELETE_JOB_SUCCESS: "PERMANENT_DELETE_JOB_SUCCESS",
  PERMANENT_DELETE_JOB_FAIL: "PERMANENT_DELETE_JOB_FAIL",

  //Accept Proposal JOb
  ACCEPT_PROPOSAL_REQUEST: "ACCEPT_PROPOSAL_REQUEST",
  ACCEPT_PROPOSAL_SUCCESS: "ACCEPT_PROPOSAL_SUCCESS",
  ACCEPT_PROPOSAL_FAIL: "ACCEPT_PROPOSAL_FAIL",
  EMPTY_ACCEPT_PROPOSAL_STATE: "EMPTY_ACCEPT_PROPOSAL_STATE",

  //GET Awarded Job MileStone
  GET_AWARDED_JOB_MILESTONE_REQUEST: "GET_AWARDED_JOB_MILESTONE_REQUEST",
  GET_AWARDED_JOB_MILESTONE_SUCCESS: "GET_AWARDED_JOB_MILESTONE_SUCCESS",
  GET_AWARDED_JOB_MILESTONE_FAIL: "GET_AWARDED_JOB_MILESTONE_FAIL",
  EMPTY_GET_AWARDED_JOB_MILESTONE_STATE:
    "EMPTY_GET_AWARDED_JOB_MILESTONE_STATE",

  //MileStone Accepted
  ACCEPT_MILESTONE_REQUEST: "ACCEPT_MILESTONE_REQUEST",
  ACCEPT_MILESTONE_SUCCESS: "ACCEPT_MILESTONE_SUCCESS",
  ACCEPT_MILESTONE_FAIL: "ACCEPT_MILESTONE_FAIL",
  EMPTY_ACCEPT_MILESTONE_STATE: "EMPTY_ACCEPT_MILESTONE_STATE",

  //Add new MileStone
  ADD_MILESTONE_REQUEST: "ADD_MILESTONE_REQUEST",
  ADD_MILESTONE_SUCCESS: "ADD_MILESTONE_SUCCESS",
  ADD_MILESTONE_FAIL: "ADD_MILESTONE_FAIL",
  EMPTY_ADD_MILESTONE_STATE: "EMPTY_ADD_MILESTONE_STATE",

  //delete  MileStone
  DELETE_MILESTONE_REQUEST: "DELETE_MILESTONE_REQUEST",
  DELETE_MILESTONE_SUCCESS: "DELETE_MILESTONE_SUCCESS",
  DELETE_MILESTONE_FAIL: "DELETE_MILESTONE_FAIL",
  EMPTY_DELETE_MILESTONE_STATE: "EMPTY_DELETE_MILESTONE_STATE",

  //edit  MileStone
  EDIT_MILESTONE_REQUEST: "EDIT_MILESTONE_REQUEST",
  EDIT_MILESTONE_SUCCESS: "EDIT_MILESTONE_SUCCESS",
  EDIT_MILESTONE_FAIL: "EDIT_MILESTONE_FAIL",
  EMPTY_EDIT_MILESTONE_STATE: "EMPTY_EDIT_MILESTONE_STATE",

  //GET SINGLE JOB
  GET_SINGLE_JOB_REQUEST: "GET_SINGLE_JOB_REQUEST",
  GET_SINGLE_JOB_SUCCESS: "GET_SINGLE_JOB_SUCCESS",
  GET_SINGLE_JOB_FAIL: "GET_SINGLE_JOB_FAIL",
  EMPTY_GET_SINGLE_JOB_STATE: "EMPTY_GET_SINGLE_JOB_STATE",

  //UPDATE JOB
  JOB_UDPATE_REQUEST: "JOB_UDPATE_REQUEST",
  JOB_UDPATE_SUCCESS: "JOB_UDPATE_SUCCESS",
  JOB_UDPATE_FAIL: "JOB_UDPATE_FAIL",
  EMPTY_JOB_UDPATE_STATE: "EMPTY_JOB_UDPATE_STATE",

  //UPDATE JOB
  END_CONTRACT_REQUEST: "END_CONTRACT_REQUEST",
  END_CONTRACT_SUCCESS: "END_CONTRACT_SUCCESS",
  END_CONTRACT_FAIL: "END_CONTRACT_FAIL",
  EMPTY_END_CONTRACT_STATE: "EMPTY_END_CONTRACT_STATE",

  //ACCEPT JOB COMPLETE
  ACCEPT_END_CONTRACT_REQUEST: "ACCEPT_END_CONTRACT_REQUEST",
  ACCEPT_END_CONTRACT_SUCCESS: "ACCEPT_END_CONTRACT_SUCCESS",
  ACCEPT_END_CONTRACT_FAIL: "ACCEPT_END_CONTRACT_FAIL",
  EMPTY_ACCEPT_END_CONTRACT_STATE: "EMPTY_ACCEPT_END_CONTRACT_STATE",

  // =======xxxxx=xxxxx=xxxxx===
  ///PROFILE CONSTANTSS

  //PROFILE WIZARD
  PROFILE_WIZARD_REQUEST: "PROFILE_WIZARD_REQUEST",
  PROFILE_WIZARD_SUCCESS: "PROFILE_WIZARD_SUCCESS",
  PROFILE_WIZARD_FAIL: "PROFILE_WIZARD_FAIL",
  EMPTY_PROFILE_WIZARD_STATE: "EMPTY_PROFILE_WIZARD_STATE",

  //UPDATE PROFILE WIDGET
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  // =======xxxxx=xxxxx=xxxxx===

  ///MY GALLERT CONSTANTSS

  //ADD NEW PROJECT PORTFOLIO
  ADD_NEW_PROJECT_REQUEST: "ADD_NEW_PROJECT_REQUEST",
  ADD_NEW_PROJECT_SUCCESS: "ADD_NEW_PROJECT_SUCCESS",
  ADD_NEW_PROJECT_FAIL: "ADD_NEW_PROJECT_FAIL",
  EMPTY_ADD_NEW_PROJECT_STATE: "EMPTY_ADD_NEW_PROJECT_STATE",

  ///get portfolio project
  GET_PORTFOLIO_PROJECT_REQUEST: "GET_PORTFOLIO_PROJECT_REQUEST",
  GET_PORTFOLIO_PROJECT_SUCCESS: "GET_PORTFOLIO_PROJECT_SUCCESS",
  GET_PORTFOLIO_PROJECT_FAIL: "GET_PORTFOLIO_PROJECT_FAIL",
  EMPTY_GET_PORTFOLIO_PROJECT_STATE: "EMPTY_GET_PORTFOLIO_PROJECT_STATE",

  //delete portfolio project
  DELETE_PORTFOLIO_PROJECT_REQUEST: "DELETE_PORTFOLIO_PROJECT_REQUEST",
  DELETE_PORTFOLIO_PROJECT_SUCCESS: "DELETE_PORTFOLIO_PROJECT_SUCCESS",
  DELETE_PORTFOLIO_PROJECT_FAIL: "DELETE_PORTFOLIO_PROJECT_FAIL",
  EMPTY__DELETE_PORTFOLIO_PROJECT_STATE:
    "EMPTY__DELETE_PORTFOLIO_PROJECT_STATE",

  ////get sinlge portfolio project
  GET_SINGLE_PORTFOLIO_PROJECT_REQUEST: "GET_SINGLE_PORTFOLIO_PROJECT_REQUEST",
  GET_SINGLE_PORTFOLIO_PROJECT_SUCCESS: "GET_SINGLE_PORTFOLIO_PROJECT_SUCCESS",
  GET_SINGLE_PORTFOLIO_PROJECT_FAIL: "GET_SINGLE_PORTFOLIO_PROJECT_FAIL",
  EMPTY_GET_SINGLE_PORTFOLIO_PROJECT_STATE:
    "EMPTY_GET_SINGLE_PORTFOLIO_PROJECT_STATE",

  //UPDATE PORTFOLIO PROJECT PORTFOLIO
  UPDATE_PORTFOLIO_PROJECT_REQUEST: "UPDATE_PORTFOLIO_PROJECT_REQUEST",
  UPDATE_PORTFOLIO_PROJECT_SUCCESS: "UPDATE_PORTFOLIO_PROJECT_SUCCESS",
  UPDATE_PORTFOLIO_PROJECT_FAIL: "UPDATE_PORTFOLIO_PROJECT_FAIL",
  EMPTY_UPDATE_PORTFOLIO_PROJECT_STATE: "EMPTY_UPDATE_PORTFOLIO_PROJECT_STATE",

  //UPDATE PROFILE  PIC PORTFOLIO
  UPDATE_PROFILE_PICTURE_REQUEST: "UPDATE_PROFILE_PICTURE_REQUEST",
  UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_FAIL: "UPDATE_PROFILE_PICTURE_FAIL",
  EMPTY_UPDATE_PROFILE_PICTURE_STATE: "EMPTY_UPDATE_PROFILE_PICTURE_STATE",

  //ADD PROFILE COVER  PORTFOLIO
  PROFILE_COVER_PROJECT_REQUEST: "PROFILE_COVER_PROJECT_REQUEST",
  PROFILE_COVER_PROJECT_SUCCESS: "PROFILE_COVER_PROJECT_SUCCESS",
  PROFILE_COVER_PROJECT_FAIL: "PROFILE_COVER_PROJECT_FAIL",
  EMPTY_PROFILE_COVER_PROJECT_STATE: "EMPTY_PROFILE_COVER_PROJECT_STATE",

  //GET PROFILE COVER  PORTFOLIO
  GET_PROFILE_COVER_PROJECT_REQUEST: "GET_PROFILE_COVER_PROJECT_REQUEST",
  GET_PROFILE_COVER_PROJECT_SUCCESS: "GET_PROFILE_COVER_PROJECT_SUCCESS",
  GET_PROFILE_COVER_PROJECT_FAIL: "GET_PROFILE_COVER_PROJECT_FAIL",
  EMPTY_GET_PROFILE_COVER_PROJECT_STATE:
    "EMPTY_GET_PROFILE_COVER_PROJECT_STATE",

  ////delete portfolio project
  GET_DELETE_PORTFOLIO_PROJECT_REQUEST: "GET_DELETE_PORTFOLIO_PROJECT_REQUEST",
  GET_DELETE_PORTFOLIO_PROJECT_SUCCESS: "GET_DELETE_PORTFOLIO_PROJECT_SUCCESS",
  GET_DELETE_PORTFOLIO_PROJECT_FAIL: "GET_DELETE_PORTFOLIO_PROJECT_FAIL",
  EMPTY_GET_DELETE_PORTFOLIO_PROJECT_STATE:
    "EMPTY_GET_DELETE_PORTFOLIO_PROJECT_STATE",

  //permanent delete PROJECT detail
  PERMANENT_DELETE_PROJECT_REQUEST: "PERMANENT_DELETE_PROJECT_REQUEST",
  PERMANENT_DELETE_PROJECT_SUCCESS: "PERMANENT_DELETE_PROJECT_SUCCESS",
  PERMANENT_DELETE_PROJECT_FAIL: "PERMANENT_DELETE_PROJECT_FAIL",
  EMPTY_PERMANENT_DELETE_STATE: "EMPTY_PERMANENT_DELETE_STATE",

  //restore delete PROJECT detail
  RESTORE_DELETE_PROJECT_REQUEST: "RESTORE_DELETE_PROJECT_REQUEST",
  RESTORE_DELETE_PROJECT_SUCCESS: "RESTORE_DELETE_PROJECT_SUCCESS",
  RESTORE_DELETE_PROJECT_FAIL: "RESTORE_DELETE_PROJECT_FAIL",

  // Manufacturer
  //Add Product
  ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAIL: "ADD_PRODUCT_FAIL",

  //USER PRODUCTS
  GET_USER_PRODUCTS_REQUEST: "GET_USER_PRODUCTS_REQUEST",
  GET_USER_PRODUCTS_SUCCESS: "GET_USER_PRODUCTS_SUCCESS",
  GET_USER_PRODUCTS_FAIL: "GET_USER_PRODUCTS_FAIL",

  //PRODUCT DETAIL
  GET_PRODUCT_DETAILS_REQUEST: "GET_PRODUCT_DETAILS_REQUEST",
  GET_PRODUCT_DETAILS_SUCCESS: "GET_PRODUCT_DETAISL_SUCCESS",
  GET_PRODUCT_DETAILS_FAIL: "GET_PRODUCT_DETAILS_FAIL",

  //CATEGORY DETAIL
  GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAIL: "GET_CATEGORY_FAIL",

  //SYMBOLS DETAIL
  GET_SYMBOL_REQUEST: "GET_SYMBOL_REQUEST",
  GET_SYMBOL_SUCCESS: "GET_SYMBOL_SUCCESS",
  GET_SYMBOL_FAIL: "GET_SYMBOL_FAIL",

  //delete product
  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAIL: "DELETE_PRODUCT_FAIL",
  EMPTY__DELETE_PRODUCT_STATE: "EMPTY__DELETE_PRODUCT_STATE",

  //deleted products list
  GET_DELETED_PRODUCTS_REQUEST: "GET_DELETED_PRODUCTS_REQUEST",
  GET_DELETED_PRODUCTS_SUCCESS: "GET_DELETED_PRODUCTS_SUCCESS",
  GET_DELETED_PRODUCTS_FAIL: "GET_DELETED_PRODUCTS_FAIL",

  //restore deleted product
  RESTORE_DELETED_PRODUCT_REQUEST: "RESTORE_DELETED_PRODUCT_REQUEST",
  RESTORE_DELETED_PRODUCT_SUCCESS: "RESTORE_DELETED_PRODUCT_SUCCESS",
  RESTORE_DELETED_PRODUCT_FAIL: "RESTORE_DELETED_PRODUCT_FAIL",
  EMPTY_RESTORE_DELETED_PRODUCT_STATE: "EMPTY_RESTORE_DELETED_PRODUCT_STATE",

  //permanent delete product
  PERMANENT_DELETE_PRODUCT_REQUEST: "PERMANENT_DELETE_PRODUCT_REQUEST",
  PERMANENT_DELETE_PRODUCT_SUCCESS: "PERMANENT_DELETE_PRODUCT_SUCCESS",
  PERMANENT_DELETE_PRODUCT_FAIL: "PERMANENT_DELETE_PRODUCT_FAIL",
  EMPTY_PERMANENT_DELETE_PRODUCT_STATE: "EMPTY_PERMANENT_DELETE_PRODUCT_STATE",

  //Update Product
  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAIL: "UPDATE_PRODUCT_FAIL",

  //Update Product
  PRODUCT_BULK_IMPORT_REQUEST: "PRODUCT_BULK_IMPORT_REQUEST",
  PRODUCT_BULK_IMPORT_SUCCESS: "PRODUCT_BULK_IMPORT_SUCCESS",
  PRODUCT_BULK_IMPORT_FAIL: "PRODUCT_BULK_IMPORT_FAIL",
  // manufacturer ends here

  //PRO CONNECTION CONSTANTS

  //SEARCH CONNECTION
  SEARCH_CONNECTION_REQUEST: "SEARCH_CONNECTION_REQUEST",
  SEARCH_CONNECTION_SUCCESS: "SEARCH_CONNECTION_SUCCESS",
  SEARCH_CONNECTION_FAIL: "SEARCH_CONNECTION_FAIL",

  //CREATE CONNECTION
  CREATE_CONNECTION_REQUEST: "CREATE_CONNECTION_REQUEST",
  CREATE_CONNECTION_SUCCESS: "CREATE_CONNECTION_SUCCESS",
  CREATE_CONNECTION_FAIL: "CREATE_CONNECTION_FAIL",
  EMPTY_CREATE_CONNECTION_FAIL: "EMPTY_CREATE_CONNECTION_FAIL",

  //PENDING REQUEST CONNECTION
  GET_PENDING_REQUEST_REQUEST: "GET_PENDING_REQUEST_REQUEST",
  GET_PENDING_REQUEST_SUCCESS: "GET_PENDING_REQUEST_SUCCESS",
  GET_PENDING_REQUEST_FAIL: "GET_PENDING_REQUEST_FAIL",
  EMPTY_GET_PENDING_REQUEST_FAIL: "EMPTY_GET_PENDING_REQUEST_FAIL",

  //RECEIVED REQUEST CONNECTION
  GET_RECEIVED_REQUEST_REQUEST: "GET_RECEIVED_REQUEST_REQUEST",
  GET_RECEIVED_REQUEST_SUCCESS: "GET_RECEIVED_REQUEST_SUCCESS",
  GET_RECEIVED_REQUEST_FAIL: "GET_RECEIVED_REQUEST_FAIL",
  EMPTY_GET_RECEIVED_REQUEST_FAIL: "EMPTY_GET_RECEIVED_REQUEST_FAIL",

  //RECEIVED CONNECTION REQUEST
  RECIEVED_CONNECTION_REQUEST_REQUEST: "RECIEVED_CONNECTION_REQUEST_REQUEST",
  RECIEVED_CONNECTION_REQUEST_SUCCESS: "RECIEVED_CONNECTION_REQUEST_SUCCESS",
  RECIEVED_CONNECTION_REQUEST_FAIL: "RECIEVED_CONNECTION_REQUEST_FAIL",
  EMPTY_RECIEVED_CONNECTION_REQUEST_STATE:
    "EMPTY_RECIEVED_CONNECTION_REQUEST_STATE",

  //REMOVE CONNECTION REQUEST
  REMOVE_CONNECTION_REQUEST_REQUEST: "REMOVE_CONNECTION_REQUEST_REQUEST",
  REMOVE_CONNECTION_REQUEST_SUCCESS: "REMOVE_CONNECTION_REQUEST_SUCCESS",
  REMOVE_CONNECTION_REQUEST_FAIL: "REMOVE_CONNECTION_REQUEST_FAIL",
  EMPTY_REMOVE_CONNECTION_REQUEST_STATE:
    "EMPTY_REMOVE_CONNECTION_REQUEST_STATE",

  //GET PRO CONNECTION REQUEST
  GET_PRO_CONNECTION_REQUEST: "GET_PRO_CONNECTION_REQUEST",
  GET_PRO_CONNECTION_SUCCESS: "GET_PRO_CONNECTION_SUCCESS",
  GET_PRO_CONNECTION_FAIL: "GET_PRO_CONNECTION_FAIL",
  EMPTY_GET_PRO_CONNECTION_STATE: "EMPTY_GET_PRO_CONNECTION_STATE",

  //REMOVE CONNECTION
  REMOVE_CONNECTION_REQUEST: "REMOVE_CONNECTION_REQUEST",
  REMOVE_CONNECTION_SUCCESS: "REMOVE_CONNECTION_SUCCESS",
  REMOVE_CONNECTION_FAIL: "REMOVE_CONNECTION_FAIL",
  EMPTY_REMOVE_CONNECTION_STATE: "EMPTY_REMOVE_CONNECTION_STATE",

  //GET FABRIC CANVAS
  SET_FAB_CANVAS: "SET_FAB_CANVAS",

  //Added Services
  GET_ADDED_SERVICES: "GET_ADDED_SERVICES",

  //Selected service from added Services
  GET_SELECTED_SERVICES: "GET_SELECTED_SERVICES",

  //PDF DESIGNER ACTIONS

  ///GET BUILDSTUDIO PROJECTS
  GET_BUILDSTUDIO_PROJECTS_REQUEST: "GET_BUILDSTUDIO_PROJECTS_REQUEST",
  GET_BUILDSTUDIO_PROJECTS_SUCCESS: "GET_BUILDSTUDIO_PROJECTS_SUCCESS",
  GET_BUILDSTUDIO_PROJECTS_FAIL: "GET_BUILDSTUDIO_PROJECTS_FAIL",

  ///DELETE BUILDSTUDIO PROJECTS
  DELETE_BUILDSTUDIO_PROJECTS_REQUEST: "DELETE_BUILDSTUDIO_PROJECTS_REQUEST",
  DELETE_BUILDSTUDIO_PROJECTS_SUCCESS: "DELETE_BUILDSTUDIO_PROJECTS_SUCCESS",
  DELETE_BUILDSTUDIO_PROJECTS_FAIL: "DELETE_BUILDSTUDIO_PROJECTS_FAIL",

  ///GET DELETE BUILDSTUDIO PROJECTS
  GET_DELETE_BS_PROJECTS_REQUEST: "GET_DELETE_BS_PROJECTS_REQUEST",
  GET_DELETE_BS_PROJECTS_SUCCESS: "GET_DELETE_BS_PROJECTS_SUCCESS",
  GET_DELETE_BS_PROJECTS_FAIL: "GET_DELETE_BS_PROJECTS_FAIL",

  ///RESTORE DELETE BUILDSTUDIO PROJECTS
  RESTORE_DELETE_BS_PROJECTS_REQUEST: "RESTORE_DELETE_BS_PROJECTS_REQUEST",
  RESTORE_DELETE_BS_PROJECTS_SUCCESS: "RESTORE_DELETE_BS_PROJECTS_SUCCESS",
  RESTORE_DELETE_BS_PROJECTS_FAIL: "RESTORE_DELETE_BS_PROJECTS_FAIL",

  ///PERMANENT DELETE BUILDSTUDIO PROJECTS
  PARMANENT_DELETE_BS_PROJECTS_REQUEST: "PARMANENT_DELETE_BS_PROJECTS_REQUEST",
  PARMANENT_DELETE_BS_PROJECTS_SUCCESS: "PARMANENT_DELETE_BS_PROJECTS_SUCCESS",
  PARMANENT_DELETE_BS_PROJECTS_FAIL: "PARMANENT_DELETE_BS_PROJECTS_FAIL",

  ///GET SINGLE BUILDSTUDIO PROJECTS
  GET_SINGLE_BS_PROJECT_REQUEST: "GET_SINGLE_BS_PROJECT_REQUEST",
  GET_SINGLE_BS_PROJECT_SUCCESS: "GET_SINGLE_BS_PROJECT_SUCCESS",
  GET_SINGLE_BS_PROJECT_FAIL: "GET_SINGLE_BS_PROJECT_FAIL",

  ////GET SYMBOL ACCORDING SERVICES//
  GET_SERVICE_SYMBOL_REQUEST: "GET_SERVICE_SYMBOL_REQUEST",
  GET_SERVICE_SYMBOL_SUCCESS: "GET_SERVICE_SYMBOL_SUCCESS",
  GET_SERVICE_SYMBOL_FAIL: "GET_SERVICE_SYMBOL_FAIL",

  //SAVE & LOAD PDF-DESIGNER DATA
  GET_PDF_DESIGNER_DATA: "GET_PDF_DESIGNER_DATA",

  SET_PDF_DESIGNER_DATA: "SET_PDF_DESIGNER_DATA",
  SET_PDF_DESIGNER_DATA_SUCCESS: "SET_PDF_DESIGNER_DATA_SUCCESS",
  SET_PDF_DESIGNER_DATA_FAIL: "SET_PDF_DESIGNER_DATA_FAIL",

  ///GET ALL PRODUCTS
  GET_ALL_PRODUCTS_REQUEST: "GET_ALL_PRODUCTS_REQUEST",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAIL: "GET_ALL_PRODUCTS_FAIL",

  ///CREATE BS PROJECT
  CREATE_BS_PROJECT_REQUEST: "CREATE_BS_PROJECT_REQUEST",
  CREATE_BS_PROJECT_SUCCESS: "CREATE_BS_PROJECT_SUCCESS",
  CREATE_BS_PROJECT_FAIL: "CREATE_BS_PROJECT_FAIL",

  ///UPLOAD SINGLE PDF IN BS-PROJECT
  UPLOAD_SINGLE_PDF_REQUEST: "UPLOAD_SINGLE_PDF_REQUEST",
  UPLOAD_SINGLE_PDF_SUCCESS: "UPLOAD_SINGLE_PDF_SUCCESS",
  UPLOAD_SINGLE_PDF_FAIL: "UPLOAD_SINGLE_PDF_FAIL",

  ///GET SINGLE BS PROJECT
  GET_BS_PROJECT_REQUEST: "GET_BS_PROJECT_REQUEST",
  GET_BS_PROJECT_SUCCESS: "GET_BS_PROJECT_SUCCESS",
  GET_BS_PROJECT_FAIL: "GET_BS_PROJECT_FAIL",

  ///EDIT BS PROJECT
  EDIT_BS_PROJECT_REQUEST: "EDIT_BS_PROJECT_REQUEST",
  EDIT_BS_PROJECT_SUCCESS: "EDIT_BS_PROJECT_SUCCESS",
  EDIT_BS_PROJECT_FAIL: "EDIT_BS_PROJECT_FAIL",

  ///NOTIFICATION
  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",

  ///count notification
  GET_NOTIFICATION_COUNT_REQUEST: "GET_NOTIFICATION_COUNT_REQUEST",
  GET_NOTIFICATION_COUNT_SUCCESS: "GET_NOTIFICATION_COUNT_SUCCESS",
  GET_NOTIFICATION_COUNT_FAIL: "GET_NOTIFICATION_COUNT_FAIL",
  NOTIFICATION_COUNT_ZERO: "NOTIFICATION_COUNT_ZERO",

  ///mark All notification
  MARKALL_NOTIFICATION_REQUEST: "MARKALL_NOTIFICATION_REQUEST",
  MARKALL_NOTIFICATION_SUCCESS: "MARKALL_NOTIFICATION_SUCCESS",
  MARKALL_NOTIFICATION_FAIL: "MARKALL_NOTIFICATION_FAIL",

  ///mark  notification
  MARK_NOTIFICATION_REQUEST: "MARK_NOTIFICATION_REQUEST",
  MARK_NOTIFICATION_SUCCESS: "MARK_NOTIFICATION_SUCCESS",
  MARK_NOTIFICATION_FAIL: "MARK_NOTIFICATION_FAIL",

  ///dashboard data
  DASHBOARD_DATA_REQUEST: "DASHBOARD_DATA_REQUEST",
  DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
  DASHBOARD_DATA_FAIL: "DASHBOARD_DATA_FAIL",

  //OWNER JOB RATING
  OWNER_RATING_REQUEST: "OWNER_RATING_REQUEST",
  OWNER_RATING_SUCCESS: "OWNER_RATING_SUCCESS",
  OWNER_RATING_FAIL: "OWNER_RATING_FAIL",

  //BIDDER JOB RATING
  BIDDER_RATING_REQUEST: "BIDDER_RATING_REQUEST",
  BIDDER_RATING_SUCCESS: "BIDDER_RATING_SUCCESS",
  BIDDER_RATING_FAIL: "BIDDER_RATING_FAIL",

  //PDF RENAME
  PDF_RENAME_REQUEST: "PDF_RENAME_REQUEST",
  PDF_RENAME_SUCCESS: "PDF_RENAME_SUCCESS",
  PDF_RENAME_FAIL: "PDF_RENAME_FAIL",

  //PDF REMOVE
  PDF_REMOVE_REQUEST: "PDF_REMOVE_REQUEST",
  PDF_REMOVE_SUCCESS: "PDF_REMOVE_SUCCESS",
  PDF_REMOVE_FAIL: "PDF_REMOVE_FAIL",

  //GET SINGLE Complete JOB
  GET_SINGLE_COMPLETE_JOB_REQUEST: "GET_SINGLE_COMPLETE_JOB_REQUEST",
  GET_SINGLE_COMPLETE_JOB_SUCCESS: "GET_SINGLE_COMPLETE_JOB_SUCCESS",
  GET_SINGLE_COMPLETE_JOB_FAIL: "GET_SINGLE_COMPLETE_JOB_FAIL",
  EMPTY_GET_SINGLE_COMPLETE_JOB_STATE: "EMPTY_GET_SINGLE_COMPLETE_JOB_STATE",

  //GET SINGLE Complete PROJECT bidder
  GET_SINGLE_COMPLETE_PROJECT_REQUEST: "GET_SINGLE_COMPLETE_PROJECT_REQUEST",
  GET_SINGLE_COMPLETE_PROJECT_SUCCESS: "GET_SINGLE_COMPLETE_PROJECT_SUCCESS",
  GET_SINGLE_COMPLETE_PROJECT_FAIL: "GET_SINGLE_COMPLETE_PROJECT_FAIL",
  EMPTY_GET_SINGLE_COMPLETE_PROJECT_STATE:
    "EMPTY_GET_SINGLE_COMPLETE_PROJECT_STATE",


};

export default ActionTypes;
