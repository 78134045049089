import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProductTabPanel from "./ProductTabPanel";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AddProduct.module.css";
import {
	DragNDrop,
	InputCmp,
	TextAreaCmp,
	DropDownCmp,
	VIewImagesModal,
} from "../../../Components";
import {
	GetBrandsAction,
	GetCategory,
} from "../../../Store/Actions/GetActions";
import { BiEdit, RiDeleteBin6Line } from "../../../Assets";

const GeneralTab = (props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(GetBrandsAction());
		dispatch(GetCategory());
	}, [dispatch]);

	const { tabValue, control, watch, imageReplace } = props;

	const { brandsdata } = useSelector((state) => state.BrandsReducers);
	const { categoriesdata } = useSelector((state) => state.CategoriesReducers);

	const [thumbnailSrc, setThumbnailSrc] = useState("");
	const [thumbnailPicture, setThumbnailPicture] = useState({});

	const [gallerySrc, setGallerySrc] = useState("");
	const [galleryPictures, setGalleryPictures] = useState([]);

	const [open, setOpen] = useState(false);

	const getThumbnailPicture = (img) => {
		setThumbnailPicture({
			is_thumbnail: true,
			image: img,
		});

		imageReplace([
			{
				is_thumbnail: true,
				image: img,
			},
			...galleryPictures,
		]);

		console.log(img);
	};

	const getGalleryPictures = (img, src) => {
		setGalleryPictures([
			...galleryPictures,
			{
				is_thumbnail: false,
				imgSrc: URL.createObjectURL(img),
				image: img,
			},
		]);
		imageReplace([
			thumbnailPicture,
			...galleryPictures,
			{
				is_thumbnail: false,
				image: img,
			},
		]);

		console.log(gallerySrc);
	};

	const deleteGalleryPic = (ind) => {
		galleryPictures.splice(ind, 1);
		setGalleryPictures([...galleryPictures]);
	};

	const deleteThumbnailPic = () => {
		setThumbnailPicture({});
	};

	return (
		<ProductTabPanel
			value={tabValue}
			index={0}
		>
			<section className={styles.productIntro_wrapper}>
				<Grid
					container
					columnSpacing={2}
					rowSpacing={5}
				>
					<Grid
						item
						lg={12}
					>
						<InputCmp
							name="title"
							control={control}
							label="Product Name"
							rules={{
								required: "Product Name is required",
							}}
						/>
					</Grid>
					<Grid
						item
						lg={12}
					>
						<TextAreaCmp
							rows={3}
							label="Description"
							name="description"
							control={control}
						/>
					</Grid>
					<Grid
						item
						lg={6}
					>
						<DropDownCmp
							name="category"
							label="Category"
							control={control}
							options={categoriesdata && categoriesdata}
							disableClearable
							freeSolo={false}
							rules={{
								required: "Category is required",
							}}
						/>
					</Grid>
					<Grid
						item
						lg={6}
					>
						<DropDownCmp
							name="brandName"
							label="Brand Name"
							control={control}
							options={brandsdata && brandsdata}
							disableClearable
							freeSolo={false}
							rules={{
								required: "Brand Name is required",
							}}
						/>
					</Grid>
					<Grid
						item
						lg={4}
					>
						<section>
							<Typography
								variant="h5"
								sx={{ fontWeight: "bold" }}
							>
								Product Thumbnail
							</Typography>
						</section>
						<Box sx={{ my: "40px" }}>
							<DragNDrop
								images={true}
								setImageSrc={setThumbnailSrc}
								getFile={getThumbnailPicture}
							/>
						</Box>
						{thumbnailSrc && (
							<Box className={styles.uploadImgShow}>
								<img
									src={thumbnailSrc}
									alt=""
								/>
								<section className={styles.editImgBox}>
									<BiEdit
										color="white"
										fontSize="50px"
									/>
									<RiDeleteBin6Line
										color="white"
										fontSize="50px"
										onClick={() => deleteThumbnailPic()}
									/>
								</section>
							</Box>
						)}
					</Grid>
					<Grid
						item
						lg={8}
					>
						<section>
							<Typography
								variant="h5"
								sx={{ fontWeight: "bold" }}
							>
								Product Gallery
							</Typography>
						</section>

						<Box sx={{ my: "40px" }}>
							<DragNDrop
								images={true}
								getFile={getGalleryPictures}
								setImageSrc={setGallerySrc}
							/>
						</Box>

						{gallerySrc && (
							<Box className={styles.listingGallerypic}>
								{galleryPictures.map((img, index) => {
									return index === 0 ? (
										<Box
											key={index}
											className={styles.uploadImgShow}
										>
											<img
												src={gallerySrc}
												alt=""
											/>
											<section className={styles.editImgBox}>
												{/* <BiEdit color="white" fontSize="50px" /> */}
												<RiDeleteBin6Line
													color="white"
													fontSize="50px"
													onClick={() => deleteGalleryPic(index)}
												/>
											</section>
										</Box>
									) : index === 1 ? (
										<Box
											key={index}
											className={styles.uploadImgShow2}
											onClick={() => {
												setOpen(true);
											}}
										>
											<h1>{galleryPictures.length - 1}+</h1>
										</Box>
									) : null;
								})}
								<VIewImagesModal
									open={open}
									setOpen={setOpen}
									projectImages={galleryPictures}
									deleteGalleryPic={deleteGalleryPic}
								/>
							</Box>
						)}
					</Grid>
				</Grid>
			</section>
		</ProductTabPanel>
	);
};

export default GeneralTab;
